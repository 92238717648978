<template>
    <!-- <div class="dashboard-main"> -->
        <!-- <div class="sidemenu">
            <SideMenu />
        </div> -->
        <!-- <div class="dashboard-view"> -->
            <!-- Current Dashboard View -->
            <!-- <router-view></router-view> -->
        <!-- </div> -->
    <!-- </div> -->
    <div>
        <router-view></router-view>
    </div>
</template>
<style lang="scss" scoped>
.dashboard-main{
    // display: flex;
    // flex-direction: row;
    // // justify-content: flex-end;
    // height: 100%;
    .sidemenu{
        // position: fixed;
        // left: 0
    }
    .dashboard-view{
        width: 99%;
        // overflow-y: auto;
        // height: 100%;
        margin-left: 20px;
        padding: 0;
        right: 0;
        order: 2
        // margin-top: 30px;
        // box-sizing: content-box;
        // padding-bottom: 30px;
    }
}
</style>
<script>
import SideMenu from '@/components/SideMenu';
export default {
    name: 'NewDashboard',
    components: {
        SideMenu
    },
    created(){
        // this.$router.push({name:'overview-page', params: {id: this.$router.params.id}})
    }
}
</script>