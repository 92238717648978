/* eslint-disable arrow-body-style */
/* eslint-disable no-return-assign */
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VuexPersistence from 'vuex-persist';

// eslint-disable-next-line import/no-cycle
import router from './router';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export default new Vuex.Store({
  state: {
    accessToken: null,
    loggingIn: false,
    loginError: null,
    gotInstallation: false,
    installations: null,
    isAccountsPage: null,
    teamSetting: null,
    personalSetting: null,
    slackState: false,
    userSlackState: false,
    organisation: null,
    githubIntegrated: false,
    displayMessage: false,
    timeList:[
      {value: "08:00", label: "08:00 am"},
      {value: "08:30", label: "08:30 am"},
      {value: "09:00", label: "09:00 am"},
      {value: "09:30", label: "09:30 am"},
      {value: "10:00", label: "10:00 am"},
      {value: "10:30", label: "10:30 am"},
      {value: "11:00", label: "11:00 am"},
      {value: "11:30", label: "11:30 am"},
      {value: "12:00", label: "12:00 pm"},
      {value: "12:30", label: "12:30 pm"},
      {value: "13:00", label: "01:00 pm"},
      {value: "13:30", label: "01:30 pm"},
      {value: "14:00", label: "02:00 pm"},
      {value: "14:30", label: "02:30 pm"},
      {value: "15:00", label: "03:00 pm"},
      {value: "15:30", label: "03:30 pm"},
      {value: "16:00", label: "04:00 pm"},
      {value: "16:30", label: "04:30 pm"},
      {value: "17:00", label: "05:00 pm"},
      {value: "17:30", label: "05:30 pm"},
      {value: "18:00", label: "06:00 pm"},
      {value: "18:30", label: "06:30 pm"},
      {value: "19:00", label: "07:00 pm"},
      {value: "19:30", label: "07:30 pm"},
      {value: "20:00", label: "08:00 pm"},
    ]
  },
  mutations: {
    loginStart: state => state.loggingIn = true,
    loginStop: (state, errorMessage) => {
      state.loggingIn = false;
      state.loginError = errorMessage;
    },
    updateAccessToken: (state, accessToken) => {
      state.accessToken = accessToken;
    },
    logout: (state) => {
      state.accessToken = null;
    },
    installationStart: state => state.gotInstallation = true,
    putInstallations: (state, array) => {
      // // console.log(array);
      state.installations = array;
    },
    hideInstallation: state => state.gotInstallation = false,
    emptyInstallations: state =>  state.installations = [],
    setHeader: (state, value) => {
      state.isAccountsPage = value;
    },
    setSettings: (state, value) => {
      state.teamSetting = value;
    },
    setSlackStateTrue: (state, value) => {
      // console.log(`value ${value}`);
      state.slackState = value;
      // console.log(state.slackState);
    },
    setUserSlackState: (state, value) => {
      state.userSlackState = value;
    },
    setOrganisation: (state, value) => {
      state.organisation = value;
      // console.log(state.organisation);
    },
    setGithubIntegrated: (state, value) => {
      state.githubIntegrated = value;
    },
    setDisplayMessage: (state, value) => {
      state.displayMessage = value;
    }
  },
  actions: {
    doLogin({ commit }, accessToken) {
      commit('loginStart');
      axios.get(`${process.env.VUE_APP_BASEURL}/user`, {
        params: {
          token: accessToken,
        },
      }).then((response) => {
        // console.log(response);
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('githubUser', JSON.stringify(response.data));
        commit('setUserSlackState', response.data.uses_slack);
        commit('loginStop', null);
        commit('updateAccessToken', accessToken);
        // router.replace({ name: '' });

        this.getInstallations(token).then(() => {
          if(this.gotInstallation){
              let org_id = this.installations[0].account.id;
              this.$router.push({name: 'review-metrics', params: {id: org_id}})
          }
          else{
              this.$router.push({name: 'integrations'})
          }
        })


      })
        .catch((error) => {
          commit('loginStop', error);
          commit('updateAccessToken', null);
        });
    },
    fetchAccessToken({ commit }) {
      commit('updateAccessToken', localStorage.getItem('accessToken'));
    },
    logout({ commit }) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('githubUser');
      commit('logout');
      // router.push('/');
      window.location = 'https://www.gohedwig.in'
    },
    async getInstallations({ commit }, accessToken) {
      // console.log('getinstallation');
      const response = await axios.get(`${process.env.VUE_APP_BASEURL}/installations`, {
        params: {
          token: accessToken,
        },
      });
      // // console.log(response);
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        if (response.data.total_count !== 0) {
          commit('installationStart');
          commit('putInstallations', response.data.installations);
        } else {
          commit('hideInstallation');
          commit('emptyInstallations');
        }
        resolve();
      });
    },
    hidelist({ commit }) {
      commit('hideInstallation');
    },
    setHeader({ commit }, value) {
      commit('setHeader', value);
    },
    getChannels({ commit }, accessToken) {
      axios.post(`${process.env.VUE_APP_BASEURL}/slack/all_channels`, {
        token: accessToken,
      }).then((response) => {
        // console.log(response);
        commit('setChannels', response.data);
      }).catch((error) => {
        // console.log(error);
      });
    },
    setSettings({ commit }, settings) {
      commit('setSettings', settings);
    },
    changeSlackState({ commit }, hasSlack) {
      // console.log(hasSlack);
      commit('setSlackStateTrue', hasSlack);
    },
    setUserSlackState({ commit }, slackState) {
      console.log('this.setUserSlackState');
      commit('setUserSlackState', slackState);
    },
    setOrganisation({ commit }, organisation) {
      commit('setOrganisation', organisation);
    },
    setGithubIntegrated({ commit }, value) {
      commit('setGithubIntegrated', value);
    },
    setDisplayMessage( { commit }, value){
      commit('setDisplayMessage', value);
    }
  },
  getters: {
    getSlackState: (state) => {
      // console.log('From getter', state.slackState);
      return state.slackState;
    },
    getUserSlackState: (state) => {
      return state.userSlackState;
    },
    getAccessToken: (state) => {
      return state.accessToken;
    },
    getInstallations: (state) => {
      // console.log(state.installations);
      return state.installations;
    },
  },
  plugins: [vuexLocal.plugin],
});
