<template>
    <div>
        <div ref="stackedBarChart"></div>
    </div>
</template>
<style lang="scss" scoped>

</style>
<script>
import Plotly from 'plotly.js/dist/plotly';
export default {
    name: 'StackedBarChart',
    props:{
        graphData:{
            required: true
        }
    },
    data(){
        // let layout = {barmode: 'stack', legend: {orientation: "h", traceorder: "normal"}};
        let layout = {
                        barmode: 'stack', 
                        yaxis: {dtick: 2},
                        showlegend: true,
                        margin: { t: 20},
                        height: 315,
                        width: 800,
                        font: {family: 'Overpass'},
                        legend: {orientation: "h"},
                    }
        
        return{
            layout
        }
    },
    mounted(){
        this.prepareGraph();
    },
    methods:{
        prepareGraph(){
            let finalData = this.sortData(this.graphData);
            console.log(finalData);
            let graph = this.$refs.stackedBarChart;
            Plotly.newPlot(graph, finalData, this.layout, {displayModeBar: false, responsive: true, staticPlot: true});
        },
        sortData(data){
            console.log(data);
            let months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

            let trace1 = {
                x:[],
                y:[],
                name: 'PR Opening Time',
                type: 'bar',
                marker: {
                        color: '#1B1D45'
                    }
            };

            let trace2 = {
                x:[],
                y:[],
                name: 'Reaction Time',
                type: 'bar',
                marker: {
                        color: '#F95A63'
                    }
            };

            let trace3 = {
                x:[],
                y:[],
                name: 'Approval Time',
                type: 'bar',
                        marker: {
                        color: '#AAD4C8'
                    }

            };

            let trace4 = {
                x:[],
                y:[],
                name: 'Merge Time',
                type: 'bar',
                        marker: {
                        color: '#8A6EDD'
                    }
            };

            data.pr_opening.forEach((elem) => {
                // console.log(elem)
                let d =  new Date(elem.date);
                let month = months[d.getMonth()];
                let date = d.getDate();

                trace1.x.push(`${date}-${month}`);
                trace1.y.push(elem.time)
            })

            data.reaction_time.forEach((elem) => {
                let d =  new Date(elem.date);
                let month = months[d.getMonth()];
                let date = d.getDate();

                trace2.x.push(`${date}-${month}`);
                trace2.y.push(elem.time)
            })

            data.approval_time.forEach((elem) => {
                let d =  new Date(elem.date);
                let month = months[d.getMonth()];
                let date = d.getDate();

                trace3.x.push(`${date}-${month}`);
                trace3.y.push(elem.time)
            })

            data.merge_time.forEach((elem) => {
                let d =  new Date(elem.date);
                let month = months[d.getMonth()];
                let date = d.getDate();

                trace4.x.push(`${date}-${month}`);
                trace4.y.push(elem.time)
            })

            return [trace1, trace2, trace3, trace4]
        }
        
    }
    
}
</script>