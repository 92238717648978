<template>
    <canvas :id="id" width="119" height="92">
    </canvas>
</template>
<style lang="scss" scoped>
    canvas{
        // border: 1px solid gray;
    }
</style>
<script>
import { fabric } from "fabric";
export default {
    name: 'canvascomponent',
    props: {
        event_data: Array,
        id: String
    },
    data(){
        let shapeMap = {
            pr_opened: new fabric.Circle({
                radius: 7, fill: '#190845', left: 20, top: 10, selectable: false
            }),
            pr_comment: new fabric.Triangle({
                width: 7, height: 7, fill: '#AAD4C8', left: 30, top: 40, selectable: false
            }),
            merge_commit: new fabric.Rect({ 
                width: 7, height: 7, fill: '#FF5B64', left: 40, top: 60, angle: 45,selectable: false
            }),
            commit: new fabric.Rect({ 
                width: 7, height: 7, fill: 'transparent', hasBorders: true, borderColor: 'red', left: 80, top: 50, angle: 45, stroke: '#FF5B64', strokeWidth: 2, selectable: false
            }),
            pr_approved: new fabric.Circle({
                radius: 7, fill: 'transparent', left: 50, top: 10, stroke: '#8753DE', strokeWidth: 2, selectable: false
            }),
        };
        let shapeMap2 = {
            pr_opened: {
                radius: 7, fill: '#190845', left: 20, top: 10, hasControls: false
            },
            pr_comment: {
                width: 14, height: 14, fill: '#AAD4C8', left: 30, top: 40, selectable: false
            },
            merge_commit:{ width: 15, height: 15, fill: '#FF5B64', left: 40, top: 60, angle: 45, },
            commit: { width: 15, height: 15, fill: 'transparent', hasBorders: true, borderColor: 'red', left: 80, top: 50, angle: 45, stroke: '#FF5B64', strokeWidth: 2},
            pr_approved: {
                radius: 7, fill: 'transparent', left: 40, top: 10, stroke: '#8753DE', strokeWidth: 2
            },
        };
        let sizeMap = {
            big: 12,
            medium: 10,
            small: 8
        };
        let positionMap = {

        };
        return {
            shapeMap, sizeMap, shapeMap2
        }
    },
    methods:{
        draw(shapes){
            var canvas = new fabric.Canvas(this.id);
            shapes.forEach((elem) => {
                canvas.add(elem);
            })
        },
        determinePosition(){

        },
        determineElementSize(event_data){
           return event_data.reduce((accumulator, currentValue) => {
                let shape = this.shapeMap[currentValue.event];
                shape.off('mousedblclick');
                shape.on('mousedblclick', function() {
                    console.log('selected a rectangle');
                });
                console.log(shape)
                if(shape.width && shape.height && !shape.radius){
                    shape.width = this.sizeMap[currentValue.size];
                    shape.height = this.sizeMap[currentValue.size];
                    accumulator.push(shape);
                } else if(shape.width && shape.height && shape.radius){
                    shape.radius = this.sizeMap[currentValue.size];
                    accumulator.push(shape);
                }
                return accumulator;
            }, []);
        },
    },
    mounted(){
        // console.log(this.event_data);
        // this.shapeMap.pr_approved.radius = 12;
        // console.log(this.shapeMap);
        let data = this.determineElementSize(this.event_data);
        // console.log(data);

        // this.determinePosition(data);
        // let shapes = this.event_data.reduce((accumulator, currentValue) => {
        //                 let shape =  this.shapeMap[currentValue.event];
        //                 accumulator.push(shape);
        //                 return accumulator;
        //             }, [])
        // console.log(shapes);
        this.draw(data);

        
    }
}
</script>