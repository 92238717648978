<template>
    <div v-bind:class="{card: true, inactive: !available}" @click="goToLink()">
        <div class="logo">
            <img :src="require(`@/assets/${logo}`)" width="68px" height="68px"/>
        </div>
        <div class="label">{{label}}</div>
        <div v-if="message != ''" class="label">{{message}}</div>
        <div v-if="hint" class="hint">{{hint}}</div>
    </div>
</template>
<style lang="scss" scoped>
.card{
    width: 250px;
    height: 153px;
    box-shadow:  0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    .logo{
        margin: 0 auto;
    }
    .label{
        font-size: 20px;
        font-family: 'Overpass';
    }
    .hint{
        opacity: 0;
        transition: opacity 0.4s ease-in-out;
    }
}
.card:hover .hint{
    opacity: 0.5;
    transition: opacity 0.4s ease-in-out;
}
.inactive{
    background-color: #F3EFEF;
    cursor: default;
}
</style>
<script>
export default {
    name: 'integration-card',
    props:{
        logo:{
            type: String
        },
        label:{
            required: true,
            type: String
        },
        available: {
            required: true,
            type: Boolean 
        },
        link:{
            required: true,
            type: String
        },
        hint:{
            required: false,
            type: String
        },
        action:{
            required: true,
            type: String
        },
        message:{
            required: true,
            type: String
        }
    },
    methods:{
        goToLink(){
            if(this.available){
                if(this.action == 'click'){
                    this.$emit('cardClick')
                }
                else if(this.action == 'install'){
                    window.location = this.link;
                }
                else{
                    // console.log('nothing will be done haha')
                }
            }
            else {
                // console.log('Not available yet buddy');
            }
            
        }
    }
}
</script>

