import Api from '@/services/api';

export default {
    applyFilters(params){
        return Api().post('/filterLeaderboard',{
            teams: params.selected_teams,
            repos: params.selected_repos,
            org_id: params.org_id,
            leaderboard: params.leaderboard
        })
    },
    getThroughput(org_id){
        return Api().post('/pullrequestdays', {
            org_id: org_id
        })
    },
    getTinyGraphData(org_id){
        return Api().post('/pullrequestdayscontributor', {
            org_id: org_id
        })
    },
    getUserWeeklyMetrics(org_id, author){
        return Api().get('/getUserWeeklyMetrics',{
            params:{
                org_id: org_id,
                author: author
            }
        })
    },
    getDummyData(){
        return Api().post('/dummyapi')
    },
    getDummyDataCycle(){
        return Api().post('/dummyapi2')
    },
    getDummyCycleMetrics(){
        return Api().get("/dummy_cycle_metrics")
    },
    getDummyRTTContributor(){
        return Api().get("/dummy_rtt_contributor")
    },
    getDummyCycleContributor(){
        return Api().get("/dummy_cycle_contributor")
    },
    getDummyCommitVolume(send_data){
        return Api().get("/dummy_commit_volume", {
            params: {
                send_data: send_data
            }
        })
    },
    getDummyCodeVolume(){
        return Api().get("/dummy_code_volume")
    },
    getDummyCommitVolumeContributor(){
        return Api().get("/dummy_commit_volume_contributor")
    },
    getDummyCodeVolumeContributor(){
        return Api().get("/dummy_code_volume_contributor")
    },
    getDummyMergedPRs(){
        return Api().get("/dummy_merged_prs")
    },
    getDummyMergedPRContributor(){
        return Api().get("/dummy_merged_prs_contributor")
    },
    getDummyComments(){
        return Api().get("/dummy_comment_coverage")
    },
    getDummyCommentsContributor(){
        return Api().get("/dummy_comment_coverage_contributor")
    },
    getDummyOpenPrs(send_data){
        return Api().get("/dummy_open_prs", {
            params: {
                send_data: send_data
            }
        })
    },
    getDummySnapshot(send_data){
        return Api().get("/dummy_snapshot", {
            params: {
                send_data: send_data
            }
        })
    },
    getDummyCodeFocus(send_data){
        return Api().get("/dummy_code_focus", {
            params: {
                send_data: send_data
            }
        })
    },
    getDummyOverviewStats(){
        return Api().get("/dummy_overview_stats")
    },
    getDummySankeyData(){
        return Api().get("/dummy_sankey_data")
    },
    getDummyDailyActivity(){
        return Api().get("/dummy_daily_activity")
    }
}