/* eslint-disable object-curly-newline */
import Vue from 'vue';
import Router from 'vue-router';
import VueCookies from 'vue-cookies';
import VueSession from 'vue-session';
import Home from './views/Home.vue';
import Install from './views/Install.vue';
import InstallApp from './components/InstallApp.vue';
import PullRequestPage from './views/PullRequestPage.vue';
import GettingStartedPage from './views/GettingStartedPage.vue';
import NotFoundPage from './views/NotFoundPage.vue';
import TeamSettings from './views/TeamSettings.vue';
import PersonalSettings from './views/PersonalSettings.vue';
import AddUsers from './views/AddUsers.vue';
import Leaderboard from './views/Leaderboard.vue';
import Dashboard from './views/Dashboard.vue';
import NotificationRules from './views/NotificationRules.vue';
import OnBoard from './views/OnBoard.vue';
import InstallHome from './views/InstallHome.vue';
import InstallAgain from './views/InstallAgain.vue';
import PullReq from './views/PullReq.vue';
import NewDashboard from './views/NewDashboard.vue';
import Overview from './views/Overview.vue';
import NewLeaderboard from './views/NewLeaderboard.vue';
import ReviewMetrics from './views/ReviewMetrics.vue';
import Integrations from './views/Integrations.vue';
import Workload from './views/Workload.vue';
import OpenPRs from './views/OpenPRs.vue';
import Auth from './views/Auth.vue';
import Snapshot from './views/Snapshot.vue';
import CommitTimeline from './views/CommitTimeline.vue';
import DailyActivity from './views/DailyActivity.vue';
// eslint-disable-next-line import/no-cycle
import store from './store';

Vue.use(Router);
Vue.use(VueCookies);
Vue.use(VueSession, { persist: true });
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/installs',
      component: Install,
      name: 'install',
      children: [
        { path: 'home', component: InstallHome, name: 'install-home' },
        { path: 'test', component: PullReq},
        { path: 'new', component: InstallApp, name: 'install-organisation', meta: { requiresAuth: true, installHeader: true } },
        { path: ':id(\\d+)/prs', component: PullRequestPage, name: 'pullrequests', meta: { requiresAuth: true, navHeader: true } },
        { path: ':id(\\d+)', component: GettingStartedPage, name: 'getting-started', meta: { requiresAuth: true, navHeader: true } },
        // { path: ':id(\\d+)/notification_rules', component: NotificationRules, name: 'notification-rules', meta: { requiresAuth: true, navHeader: true } },
        { path: ':id(\\d+)/onboard', component: OnBoard, name: 'onboard', meta: { requiresAuth: true } },
        // { path: ':id(\\d+)/notification_rules/:channel_id/edit', component: TeamSettings, name: 'team-settings', meta: { requiresAuth: true } },
        // { path: ':id(\\d+)/personal-settings', component: PersonalSettings, name: 'personal-settings', meta: { requiresAuth: true } },
        { path: ':id(\\d+)/slack_users', component: AddUsers, name: 'add-users', meta: { requiresAuth: true } },
        { path: ':id(\\d+)/leaderboard', component: Leaderboard, name: 'leaderboard', meta: { requiresAuth: true } },
        { path: ':id(\\d+)/dashboard', component: Dashboard, name: 'dashboard', meta: { requiresAuth: true } },
      ],
      meta: { requiresAuth: true },
    },
    {
      path: '',
      component: Home,
      name: 'home',
      meta: { isHome: true },
    },
    {
      path: '/auth',
      component: Auth,
      name: 'auth',
    },
    {
      path: '/analytics',
      component: NewDashboard,
      name: 'new-dashboard',
      children:[
        {path: ':id/overview', component: Overview, name:'overview-page'},
        {path: ':id/workload', component: Workload, name: 'workload'},
        {path: ':id/reviewer-leaderboard', component: NewLeaderboard, name: 'reviewer-leaderboard'},
        {path: ':id/author-leaderboard', component: NewLeaderboard, name: 'author-leaderboard'},
        {path: ':id/daily-activity', component: DailyActivity, name: 'daily-activity'},
        {path: ':id/snapshot', component: Snapshot, name: 'snapshot'},
        {path: ':id/commit-timeline', component: CommitTimeline, name: 'commit-timeline'},
        {path: ':id/review-metrics', component: ReviewMetrics, name: 'review-metrics'},
        {path: ':id/open-prs', component: OpenPRs, name: 'open-prs'},
        {path: ':id/notification-rules/:channel_id/edit', component: TeamSettings, name: 'team-settings'},
        {path: ':id/personal-settings', component: PersonalSettings, name: 'personal-settings'},
        {path: ':id/notification-rules', component: NotificationRules, name: 'notification-rules'},
        {path: 'integrations', component: Integrations, name: 'integrations'},
        {path: ':id/integrations', component: Integrations, name: 'auth-integrations'},
        {path: 'integrations/:id/onboard', component: OnBoard, name: 'slack-onboard'},
      ]
    },
    {
      path: '*',
      component: NotFoundPage,
      name: 'not-found',
    },
    {
      path: '/reinstall',
      component: InstallAgain,
      name: 'install-again',
    },
  ],
});

// router.beforeEach((to, from, next) => {
//   store.dispatch('fetchAccessToken');
//   if (to.fullPath === '/installs/new') {
//     if (!store.state.accessToken) {
//       next('');
//     }
//   }
//   if (to.fullPath === '/') {
//     if (store.state.accessToken) {
//       next('/installs/new');
//     }
//   }
//   if (to.fullPath === '/installs') {
//     // console.log('Installs Header');
//     store.dispatch('setHeader', {
//       value: true,
//     });
//   }
//   if (to.fullPath === '/installs/*') {
//     // console.log('Header');
//     store.dispatch('setHeader', {
//       value: false,
//     });
//   }
//   next();
// });

// router.beforeEach((to, from, next) => {
//   store.dispatch('fetchAccessToken');

//   if (to.fullPath === '/installs') {
//     // console.log('Installs Header');
//     store.dispatch('setHeader', {
//       value: false,
//     });
//   }

//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     if (!store.state.accessToken) {
//       next('');
//     }
//   }

//   next();
// });

export default router;
