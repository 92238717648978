<template>
    <div class="container">
        Seems like there's been some problem <br/>
        Please install the application again
    </div>    
</template>

<script>
export default {
    name:'install-again',
        
}
</script>

<style lang="scss" scoped>

</style>