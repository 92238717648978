<template>
     <div>
        <div ref="areaBarChart"></div>
    </div>
</template>
<style lang="scss" scoped>

</style>
<script>
import Plotly from 'plotly.js/dist/plotly';
export default {
    name: 'AreaBarChart',
    props: {
        graphData: {
            required: true
        },
        height:{
            type: Number
        },
        width:{
            type: Number
        },
        type: {
            type: String
        }
    },
    data(){
        // var trace1 = {
        //     x: [1, 2, 3, 4],
        //     y: [0, 2, 3, 5],
        //     fill: 'tozeroy',
        //     type: 'scatter',
        //     mode: 'none'
        // };
        // var trace1 = {
        //     x: [1, 2, 3, 4],
        //     y: [0, 2, 3, 5],
        //     type: 'bar'
        // };


        // var trace2 = {
        //     x: [1, 2, 3, 4],
        //     y: [3, 5, 1, 7],
        //     yaxis: 'y2',
        //     fill: 'tozeroy',
        //     type: 'scatter',
        //     mode: 'none'
        // };

        let layout = {  
            barmode: 'stack', 
            yaxis: {dtick: 10},
            showlegend: true,
            margin: { t: 20},
            height: 300,
            width: 800,
            font: {family: 'Overpass'},
            legend: {orientation: "h"},
            yaxis2: {
                title: 'LOC',
                titlefont: {color: 'rgb(148, 103, 189)'},
                tickfont: {color: 'rgb(148, 103, 189)'},
                overlaying: 'y',
                side: 'right'
            }
        }

        // let d = [trace1, trace2]
        return{
            layout
        }
    },
    mounted(){
        this.prepareGraph();
    },
    methods:{
        prepareGraph(){
            if(this.type == "overview"){
                this.layout.height = this.height;
                this.layout.width = this.width;
            }
            let finalData = this.sortData(this.graphData);
            let graph = this.$refs.areaBarChart;
            Plotly.newPlot(graph, finalData, this.layout, {displayModeBar: false, responsive: true, staticPlot: true});
        },
        sortData(data){
            let months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

            let bar = {
                x: [],
                y: [],
                type: 'bar',
                fill: 'none',
                marker: {color: '#8774BE'},
                width: []
            };


            let area = {
                x: [],
                y: [],
                yaxis: 'y2',
                fill: 'tozeroy',
                type: 'scatter',
                opacity: 0.1,
                mode: 'none',
                fillcolor: 'rgba(170, 212, 200, 0.7)'
            };

            data.forEach((elem) => {
                let d =  new Date(elem.date);
                let month = months[d.getMonth()];
                let date = d.getDate();
                bar.x.push(`${date}-${month}`);
                bar.y.push(elem.open_prs);
                area.x.push(`${date}-${month}`)
                area.y.push(elem.loc);
                bar.width.push(0.3)
            })

            return [area, bar]
        }
    }
}
</script>