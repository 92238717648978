<template>
    <div class="whole">
        <div class="page-header">
          <label>LeaderBoard</label>
          <!-- <SearchBox /> -->
        </div>
        <hr />
        <!-- FILTERS -->
        <div class="filters-row">
            <template>
                <fish-select search multiple v-model="selected_teams" style="width: 150px; height: auto;" @change="changeHandlerTeams()" hint="Teams">
                    <fish-option v-for="(team, index) in team_options" :key="index" :index="team.team_id" :content="team.team_name"></fish-option>
                </fish-select>
            </template>
            <template>
                <fish-select search multiple v-model="selected_repos" style="width: 150px; height: auto;" @change="changeHandlerRepos()" hint="Repos">
                    <fish-option v-for="(repo, index) in repo_options" :key="index" :index="repo.id" :content="repo.name"></fish-option>
                </fish-select>
            </template>
            <template>
                <fish-select v-model="time_period" style="width: 150px; height: auto;" @change="changeHandlerTime()" hint="Time Period">
                    <fish-option index="7d" content="7 Days"></fish-option>
                    <fish-option index="14d" content="14 Days"></fish-option>
                    <fish-option index="30d" content="1 Month"></fish-option>
                </fish-select>
            </template>
            <!-- <template>
                <fish-select search multiple v-model="selected_repos" style="width: 150px; height: auto;" @change="changeHandler" hint="Weekends">
                    <fish-option v-for="(repo, index) in repo_options" :key="index" :index="repo.name" :content="repo.name"></fish-option>
                </fish-select>
            </template> -->
            <template>
                <fish-button @click="applyFilters()">Apply</fish-button>
            </template>
        </div>
        <template v-if="mode == 'author-leaderboard'">
            <template v-if="!authorDataEmpty">
                <div class="author-content">
                    <div class="graph-box">
                        <label>Top 5 Contributors</label>
                        <NewLineChart :key="componentKey" v-if="dataLoaded" v-bind:graphData="graph_data_author" :graphType="graphType"/>
                    </div>
                    <div class="author-table">
                        <AuthorLeaderboardTable :authorList="authorData" />
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="author-content">
                    <div class="message">
                        We need atleast 3 days worth of data for the graphs to give something meaningful to you
                    </div>
                </div>
            </template>
            
        </template>
        <template v-if="mode == 'reviewer-leaderboard'">
            <template v-if="!reviewerDataEmpty">
                <div class="author-content">
                    <div class="graph-box">
                        <label>Top 5 Reviewers</label>
                        <NewLineChart :key="componentKey" v-if="dataLoaded" v-bind:graphData="graph_data_reviewer" :graphType="graphType" />
                    </div>
                    <div class="author-table">
                        <ReviewerLeaderboardTable :reviewerList="reviewerData" />
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="author-content">
                    <div class="message">
                        We need atleast 3 days worth of data for the graphs to give something meaningful to you
                    </div>
                </div>
            </template>
        </template>
    </div>
</template>
<style lang="scss" scoped>
.whole{
    // margin: 40px 30px 20px 30px;
    margin-top: 40px;
    height: 80vh;
    width: 98%;
    .page-header{
        display: flex;
        flex-direction: row;
        width: inherit;
        justify-content: space-between;
        align-items: center;
        label{
            font-size: 50px;
            font-weight: bold;
            height: auto;
            color: #190845;
        }
    }
    .filters-row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 60vw;
        align-items: baseline;
        margin-bottom: 20px;
    }
    .author-content{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: nowrap;
            .graph-box{
                border: 2px solid #E5E5E5;
                width: inherit;
                height: 45vh;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
                padding-top: 30px;
                label{
                    font-size: 35px;
                    font-weight: bold;
                    color: #190845;
                    font-style: normal;
                    margin-left: 60px;
                }
            }
        .author-table{
            margin-top: 15px;
        }
        .message{
            font-size: 50px;
            height: 80px;
            background: none;
            margin-top: 10vh;
            line-height: 80px;
        }
    }
    
}
</style>
<script>
import SearchBox from '@/components/SearchBox';
import NewLineChart from '@/components/NewLineChart';
import AuthorLeaderboardTable from '@/components/AuthorLeaderboardTable';
import ReviewerLeaderboardTable from '@/components/ReviewerLeaderboardTable';
import LeaderboardService from '@/services/LeaderboardService';
import Vue from 'vue';
import FishUI from 'fish-ui';
import axios from 'axios';
Vue.use(FishUI);
export default {
    name: 'new-leaderboard',
    components:{
        SearchBox,
        NewLineChart,
        AuthorLeaderboardTable,
        ReviewerLeaderboardTable
    },
    data(){
        let mode;
        let authorData = [];
        let reviewerData = [];
        let team_options = [];
        let repo_options = [];
        let selected_repos = [];
        let selected_teams = [];
        let graph_data_author = [];
        let graph_data_reviewer = [];
        let time_period;
        let dataLoaded = false;
        let graphType = 'leaderboard';
        let componentKey = 0;
        let authorDataEmpty = false;
        let reviewerDataEmpty = false;
        return{
            mode,
            authorData,
            reviewerData,
            multipleValues1: [],
            team_options,
            repo_options,
            selected_repos,
            selected_teams,
            time_period,
            graph_data_author,
            graph_data_reviewer,
            dataLoaded,
            graphType,
            componentKey,
            authorDataEmpty,
            reviewerDataEmpty
        }
    },
    methods:{
        setLeaderboardMode(name){
            // console.log(name)
            if(name == 'author-leaderboard'){
                this.mode = 'author-leaderboard';
                this.fetchAuthorData().then((response) => {
                    console.log(response.data);
                        // this.authorDataEmpty = true;
                    this.authorData = response.data;
                })
                this.fetchLeaderboardGraphs(this.$route.params.id).then((response) => {
                    console.log(response.data);
                    if(response.data.author.length <= 3){
                        // // console.log('author data empty')
                        this.authorDataEmpty = true;
                    } else {
                        this.graph_data_author = response.data.author;
                    }
                })
            }

            if(name == 'reviewer-leaderboard'){
                this.mode = 'reviewer-leaderboard';
                this.fetchReviewerData().then((response) => {
                    console.log(response.data);
                    this.reviewerData = response.data;
                })
                this.fetchLeaderboardGraphs(this.$route.params.id).then((response) => {
                    console.log(response.data);
                    if(response.data.reviewer.length <= 3){
                        this.reviewerDataEmpty = true;
                    } else{
                        this.graph_data_reviewer = response.data.reviewer;
                    }
                })
            }
        },
        async fetchAuthorData(){
            const response = await LeaderboardService.getAuthorList(this.$route.params.id);
            return response;
        },
        async fetchReviewerData(){
            const response = await LeaderboardService.getReviewerList(this.$route.params.id);
            return response;
        },
        changeHandler(values){
            // // console.log('change handles', values)
        },
        changeHandlerTeams(values) {
            // // console.log(this.selected_teams)
        },
        changeHandlerRepos(values){
            // // console.log(this.selected_repos)
        },
        getTeams(){
            axios.get(`${process.env.VUE_APP_BASEURL}/get_teams`,{
                params:{
                    org_id: this.$route.params.id
                }
            }).then((response) => {
                this.team_options = response.data
            })
        },
        getRepos(){
            axios.get(`${process.env.VUE_APP_BASEURL}/get_org_repos`,{
                params:{
                    org_id: this.$route.params.id
                }
            }).then((response) => {
                //   // console.log(response.data)
                this.repo_options = response.data
            })
        },
        applyFilters(){
            let params = {
                selected_teams: this.selected_teams,
                selected_repos: this.selected_repos,
                org_id: this.$route.params.id,
                leaderboard: this.mode
            }
            LeaderboardService.applyFilters(params).then((response) => {
                // // console.log(response.data);
                if(this.mode == "reviewer-leaderboard"){
                    this.reviewerData = response.data;
                }
                else{
                    this.authorData = response.data;
                }
            })
        },
        async fetchLeaderboardGraphs(org_id) {
            // console.log('called');
            const response = await LeaderboardService.getLeaderboardGraph(org_id);
            return response;
        }
    },
    created(){
        // console.log('created called');
        this.setLeaderboardMode(this.$route.name);
        this.getTeams();
        this.getRepos();
        // this.fetchLeaderboardGraphs(this.$route.params.id);
    },
    watch: {
        $route(to, from){
            if(this.$route.name == 'author-leaderboard'){
                this.mode = this.$route.name;
                // this.generateAuthorChart();
                this.fetchAuthorData().then((response) => {
                    console.log(response.data);
                    this.authorData = response.data;
                })
                this.getTeams();
                this.getRepos();
 
                this.fetchLeaderboardGraphs(this.$route.params.id).then((response) => {
                    console.log(response.data);
                    if(response.data.author.length <= 3){
                        this.authorDataEmpty = true
                    } else {
                        this.graph_data_author = response.data.author;
                        this.componentKey = !this.componentKey;
                    }
                })
            }
            if(this.$route.name == 'reviewer-leaderboard'){
                this.mode = this.$route.name;
                this.fetchReviewerData().then((response) => {
                    console.log(response.data);
                    this.reviewerData = response.data;
                });
                this.getTeams();
                this.getRepos();
                this.fetchLeaderboardGraphs(this.$route.params.id).then((response) => {
                    console.log(response.data);
                    if(response.data.reviewer.length <= 3){
                        this.reviewerDataEmpty = true;
                    } else {
                        this.graph_data_reviewer = response.data.reviewer;
                        this.componentKey = !this.componentKey;
                    }
                })
            }
        },
        graph_data_reviewer: function(){
            if(this.graph_data_reviewer.length > 0){
                this.dataLoaded = true;
            }
        },
        graph_data_author: function(){
            if(this.graph_data_author.length > 0){
                this.dataLoaded = true;
            }
        }
    }
}
</script>