<template>
<div>
    <div class="section-insights">
        <div class="container">
            <div v-if="hasPrs">
                <div class="page-header">Open Pull Requests ({{ total_prs }})</div>
                <div class="box">
                    <div class="boxHeader">
                        <div class="headerContent">
                            <form class="filters">
                                 <md-field>
                                    <md-select v-model="sort" @md-selected="applySort($event)" class="set">
                                        <md-option value="age-old" >Oldest</md-option>
                                        <md-option value="age-new">Newest</md-option>
                                        <md-option value="size-large">Largest</md-option>
                                    </md-select>
                                </md-field>
                                <md-field>
                                    <md-select v-model="repos" placeholder="All Repos" @md-selected="repoValueChanged()" class="set" multiple>                          
                                        <md-option v-for="repo in org_repos" :value="repo.id" :key="repo.id">{{repo.name}}</md-option>
                                    </md-select>
                                </md-field>
                                <md-field>
                                    <md-select v-model="teams" placeholder="All Teams" @md-selected="teamValueChanged()" class="set" multiple>                          
                                        <md-option v-for="team in github_teams" :value="team.team_id" :key="team.team_id">{{team.team_name}}</md-option>
                                    </md-select>
                                </md-field>
                                <md-field>
                                    <md-select v-model="authors" placeholder="All Authors" @md-selected="authorValueChanged()" class="set" multiple>                          
                                        <md-option v-for="auth in authors_opts" :value="auth" :key="auth">{{auth}}</md-option>
                                    </md-select>
                                </md-field>
                                <md-field>
                                    <md-select v-model="time" @md-selected="filterByTime()" class="set">
                                        <md-option value="all">All</md-option>
                                        <md-option value="14">14d</md-option>
                                        <md-option value="30">1 month</md-option>
                                    </md-select>
                                </md-field>
                            </form>
                            <md-button @click="applyFilters()" class="md-dense md-raised md-primary">Apply</md-button>
                            <md-button @click="clearAllFilters()" class="md-dense md-primary">Clear</md-button>
                        </div>
                    </div>
                    <div class="flex-table prs">
                        <div class="row-header">
                            <div class="col-left">
                                <span>Pull request</span>
                            </div>
                            <div class="col-right">
                                <span>Size</span>
                            </div>
                            <div class="col-right">
                                <span>Approvals</span>
                            </div>
                        </div>
                        <div class="row-body" v-for="pr in prList" :key="pr.pr_id">
                            <div class="col-left">
                                <div class="pr">
                                    <div class="avatar">
                                        <img :title="pr.payload.user.login" width="100%" :src="pr.payload.user.avatar_url">
                                    </div>
                                    <div class="pr-info">
                                        <div class="pr-title">
                                            <span class="repo">[{{ pr.payload.head.repo.name }}#{{ pr.payload.number }}]</span>
                                            <a target="_blank" :href="pr.payload.html_url" rel="noopener noreferrer">{{ pr.payload.title }}</a>
                                        </div> 
                                        <div class="pr-metadata">
                                           <span> {{pr.age}} days old </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-right">
                                <span class="text-green"><strong>+{{ pr.payload.additions }}</strong></span>
                                <span class="text-red"><strong>-{{ pr.payload.deletions }}</strong></span>
                            </div>
                            <div class="col-right approval">
                                <i class="material-icons thumbs-up">thumb_up</i>
                                <span>0</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="box">
                    <label>THERE ARE NO OPEN PULL REQUESTS</label>
                </div>
            </div>
        <div class="lds-ring" v-if="loading"><div></div><div></div><div></div><div></div></div>
        </div>
    </div> 
</div>     
</template>

<script>
import axios from 'axios';
import store from '../store';
import moment from 'moment';
import Header from '@/components/Header.vue'
import lodash from "lodash";
import Vue from "vue";
Object.defineProperty(Vue.prototype, '_', { value: lodash});
export default {
    name: 'PullRequestPage',
    components: {
        Header,
    },
    data(){
        let prList = [];
        let hasPrs = false;
        let orgError = false;
        let repos = [];
        let filtersPristine = true;
        let teams = [];
        let authors = [];
        let sort = "age-old";
        let time = "all";
        let loading = false;
        return{ 
            prList,
            hasPrs,
            orgError,
            repos,
            org_repos: [],
            filtersPristine,
            prs_no_filters: [],
            github_teams: [],
            teams,
            authors_opts: [],
            authors,
            sort,
            time,
            total_prs: 0,
            offset:0,
            loading,
            filters: {
                teams: [],
                authors: [],
                repos: []
            },
        }
    },
    created() {
        // console.log('First Call')
        // console.log(this.$route.params.id);
        this.fetchRepoAndTeams();
        this.fetchAuthors();
        store.dispatch('hidelist');
        axios.get(`${process.env.VUE_APP_BASEURL}/get_repos`,{
                params: {
                    org_id: this.$route.params.id,
                    offset: this.offset
                }
            }).then((response) => {
                // console.log(response.data)
                if(response.data.error_message2){
                
                    this.orgError = true;
                    this.$router.push({name: 'install-again'}).catch(err => {})
                    //NO ORGANISATION FOUND DISPLAY ERROR
                    // possible redirect to an errors page asking to install application again
                } else if(response.data.prs) {
                    this.hasPrs = true;
                    this.prList = response.data.prs;
                    this.prs_no_filters = response.data.prs;
                    this.total_prs = response.data.count;
                    this.applySort();

                    // this.filterByTime();
                } else if(response.data.total_prs == 0) {
                    //NO PRS MESSAGE DISPLAY
                    this.hasPrs = false;
                }
                
            });
    },
    methods: {
        applySort(){
            // console.log('apply sort')
            if(this.sort == "age-old"){
                this.prList = _.sortBy(this.prList, (p) =>{
                    return p.age
                })
                this.prList = _.reverse(this.prList);
            }
            else if(this.sort == "age-new"){
                // console.log('newest')
                this.prList = _.sortBy(this.prList, (p) =>{
                    return p.age
                })         
            }
            else if(this.sort == "size-large"){
                // console.log('largest');
                this.prList = _.sortBy(this.prList, (p) => {
                    return p.payload.additions
                })
                this.prList = _.reverse(this.prList);
            }
        },
        fetchRepoAndTeams(){
            axios.get(`${process.env.VUE_APP_BASEURL}/get_org_repos`, {
                params: {
                    org_id: this.$route.params.id
                }
            }).then((response) => {
                // console.log('repos')
                // console.log(response.data)
                this.org_repos = response.data;
            })

            axios.get(`${process.env.VUE_APP_BASEURL}/get_teams`, {
                params: {
                    org_id: this.$route.params.id
                }
            }).then((response) => {
                // // console.log(response.data)
                this.github_teams = response.data;
            })
        },
        fetchAuthors(){
            axios.get(`${process.env.VUE_APP_BASEURL}/get_authors`, {
                params: {
                    org_id: this.$route.params.id
                }
            }).then((response) => {
                // console.log(response.data)
                this.authors_opts = response.data;
            })
        },
        repoFilterClosed(){
            if(this.repos.length > 0){
                this.offset = 0;
                // console.log("closedd")
                this.filtersPristine = false;
                axios.get(`${process.env.VUE_APP_BASEURL}/get_repos`, {
                    params: {
                        repo_id: this.repos,
                        o_id: this.$route.params.id,
                        offset: this.offset
                    }
                }).then((response) => {
                    // console.log(response);
                    this.prList = response.data.prs

                })
            } else if (this.repos.length == 0 && this.authors.length == 0 && this.teams.length == 0 && this.filtersPristine == false) {
                // console.log('Filters not pristine')
                this.prList = this.prs_no_filters;
            }
        },
        teamFilterClosed(){
            if(this.teams.length > 0){
                this.offset = 0;
                this.filtersPristine = false;
                axios.get(`${process.env.VUE_APP_BASEURL}/get_repos`, {
                    params: {
                        team_id: this.teams,
                        organisation_id: this.$route.params.id,
                        offset: this.offset
                    }
                }).then((response) => {
                    // console.log(response.data)
                    this.prList = response.data.prs;
                    // console.log(this.prList)
                })
            } else if(this.teams.length == 0 && this.repos.length == 0 && this.authors.length == 0 &&this.filtersPristine == false) {
                // console.log('Filters not pristine')
                this.prList = this.prs_no_filters;
            }
        },
        authorFilterClosed(){
            if(this.authors.length > 0){
                this.offset = 0;
                this.filtersPristine = false;
                axios.get(`${process.env.VUE_APP_BASEURL}/get_repos`, {
                    params: {
                        or_id: this.$route.params.id,
                        authors: this.authors,
                        offset: this.offset
                    }
                }).then((response) => {
                    // console.log(response.data)
                    this.prList = response.data.prs;
                })
            } else if(this.authors.length == 0 && this.teams.length == 0 && this.repos.length == 0 && this.filtersPristine == false) {
                this.prList = this.prs_no_filters;
            }
        },
        filterByTime(){
            // console.log('time filter')
            if(this.time == "all"){
                this.prList = this.prs_no_filters;
            }else{
                this.prList = this.prList.reduce((acc, cVal) => {
                if(cVal.age < this.time){
                    acc.push(cVal)
                }
                return acc
            }, [])
            }
            
        },
        scroll () {
         window.onscroll = () => {
            if ((window.innerHeight + window.pageYOffset) >= document.body.scrollHeight) {
                // alert("you're at the bottom of the page");
                this.offset = this.offset + 10;
                this.loading = true;
                axios.post(`${process.env.VUE_APP_BASEURL}/filterPrs`,{
                        filters: this.filters,
                        org_id: this.$route.params.id,
                        offset: this.offset
                    
                }).then((response) => {
                    this.loading = false;
                    // console.log(response.data.prs)
                    this.prList = _.union(this.prList, response.data.prs)
                    // if (response.data.prs.length > 0) this.applySort();
                    this.prs_no_filters = this.prList;
                })
            }
         };
  	    },
        repoValueChanged(){
            this.filters.repos = this.repos;
            // console.log(this.filters)            
        },
        teamValueChanged(){
            this.filters.teams = this.teams;
            // console.log(this.filters)
        },
        authorValueChanged(){
            this.filters.authors = this.authors;
            // console.log(this.filters)
        },
        applyFilters(){
            this.offset = 0;
            this.filtersPristine = false;
            if(this.filters.repos.length > 0 || this.filters.teams.length > 0 || this.filters.authors.length > 0){
                // // console.log('did something happen')
                axios.post(`${process.env.VUE_APP_BASEURL}/filterPrs`,{
                    filters: this.filters,
                    offset: this.offset,
                    org_id: this.$route.params.id
                }).then((response) => {
                    // console.log(response)
                    // this.prList = [];
                    this.prList = response.data.prs;
                    this.total_prs = response.data.total_count
                })
            }            
        },
        clearAllFilters(){
            if(this.filtersPristine == false){
                this.filters = {
                    repos: [],
                    teams: [],
                    authors: []
                };
                this.repos = [];
                this.teams = [];
                this.authors = [];
                axios.post(`${process.env.VUE_APP_BASEURL}/filterPrs`,{
                    filters: this.filters,
                    offset: this.offset,
                    org_id: this.$route.params.id
                }).then((response) => {
                    this.prList = response.data.prs;
                    this.total_prs = response.data.total_count
                    this.filtersPristine = true;
                })
            }
            
        }
    },
    mounted(){
        this.scroll();
    },
};
</script>

<style lang="scss" scoped>

.section-insights{
    max-width: 75em;
    margin: 0 auto;
    padding-bottom: 20px;
    .container{
        max-width: 75em;
        margin: 0 1em;
        position: relative;
        // margin-bottom: 20px;
        .box{
            background: #2E3034;
            padding: 0px;
            // margin-bottom: 20px;
            .boxHeader{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .headerContent{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background-color: #57595D;
                    border-radius: 12px 12px 0px 0px;
                    h3{
                        font-weight: 500;
                        font-size: 1em;
                        margin: 0;
                        color: white;
                        flex-grow: 1;
                    }
                    .filters{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        margin-left: 40px;
                        .set{
                            width: 140px;
                            // font: inherit;
                            // outline: none;
                            // min-width: 1px;
                            // line-height: normal;
                            // vertical-align: middle;
                            // background-color: none;
                            // border: none;
                            // overflow: hidden;
                            // text-overflow: ellipsis;
                            // /* padding: 5px 1.75em 5px 8px; */
                            // -webkit-appearance: none;
                            // -moz-appearance: none;
                            // border-radius: 3px;
                            // cursor: pointer;
                            // background-repeat: no-repeat;
                            // background-position: right 8px center;
                            /* box-shadow: 0 0 0 1px rgba(50,50,93,0.1), 0 2px 5px 0 rgba(50,50,93,0.1), 0 1px 1.5px 0 rgba(0,0,0,0.07), 0 1px 2px 0 rgba(0,0,0,0.08), 0 0 0 0 transparent; */
                        }
                    }
                }
            }
            .flex-table.prs{
                border-collapse: collapse;
                text-align: left;
                border-radius: 0px 0px 12px 12px;
                .row-header, .row-body{
                    display: flex;
                    padding: 0.4em 0 0.45em 0;
                    border-bottom: 1px solid #dae1e9;
                    background-color: #57595D;
                    color: white;
                    align-items: center;
                    .col-left{
                        flex-grow: 1;
                        padding-left: 1.15rem;
                        padding-right: 2rem;
                        min-width: 0;
                        .pr{
                            display: flex;
                            align-items: center;
                            overflow: hidden;
                            min-width: 0;
                            margin-left: 10px;
                            .avatar {
                                margin-right: 0.85em;
                                display: inline-block;
                                overflow: hidden;
                                width: 2.25em;
                                height: 2.25em;
                                border-radius: 2.25em;
                                flex: 0 0 2.25em;
                                box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.2), -1px -1px 10px 1px rgba(0, 0, 0, 0.2);
                            }
                            .pr-info {
                                color: white;
                                min-width: 0;
                            }
                            .pr-title {
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                .repo {
                                    font-weight: 500;
                                }
                                a {
                                    color: #6092FB;
                                }
                            }
                            .pr-metadata {
                                margin-top: 0.33em;
                                font-size: 0.9em;
                                color: #444;
                            }
                        }
                    }
                    .col-right{
                        flex: 0 0 8em;
                        display: flex;
                        flex-direction: row;
                        justify-content: left;
                        align-items: center;
                        padding-left: 1rem;
                        span {
                            font-size: 0.9em;
                        }
                        .text-green{
                            strong{
                                color: #35A07A;
                            }
                        }
                        .text-red{
                            strong{
                                color: #BB914A;
                            }
                        }
                    }
                    .col-right .approval {
                        color: white;
                        .material-icons{
                            font-size: 15px;
                        }
                    }
                }
                .row-body{
                    border-bottom: 1px solid #dae1e9;
                    display: flex;
                    padding: 0.95em 0;
                    align-items: center;
                    background: none;
                }
                .row-body:last-child {
                    border-bottom: none;
                }
            }
 
        }
    }
    .page-header{
        font-size: 30px;
        text-align: left;
        margin-bottom: 20px;
        margin-top: 40px;
        color: white;
        font-weight: bold;
    }
    .lds-ring {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid rgb(2, 2, 2);
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: rgb(8, 8, 8) transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }
    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}


</style>