<template>
    <div class="table">
        <div class="table-header">
            <label>Rank</label>
            <label>Reviewer</label>
            <label v-on:click="sortByReviewed()">#PRs Reviewed</label>
            <label>#Comments</label>
            <label>Reaction Time</label>
            <label>Review Turnaround Time</label>
        </div>
        <div class="table-body">
            <div class="table-row" v-for="(reviewer, index) in reviewerList" :key="index">
                <label class="rank">{{index + 1}}</label>
                <label class="reviewer-name">{{reviewer.github_login}}</label>
                <label class="reviews">{{reviewer.pr_count}}</label>
                <label class="comments">{{reviewer.comment_count}}</label>
                <label class="rt">{{reviewer.reaction_time}}</label>
                <label class="rtt">{{reviewer.review_turnaround_time}}</label>
            </div>
            
        </div>
    </div>
</template>
<style lang="scss" scoped>
.table{
    display: flex;
    flex-direction: column;
    .table-header{
        display: flex;
        flex-direction: row;
        height: 60px;
        width: inherit;
        background: #F1F1F1;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px 0 15px ;
        label{
            font-family: Overpass;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 28px;
            color: #190845;
            width: 20%;
        }
    }
    .table-body{
        display: flex;
        flex-direction: column;
        height: 25vh;
        overflow-y: auto;
        .table-row{
            display: flex;
            flex-direction: row;
            width: inherit;
            height: 30px;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #e5e5e5;
            border-left: 1px solid #e5e5e5;
            border-right: 1px solid #e5e5e5;
            padding: 0 15px 0 15px ;
            label{
                width: 20%;
            }
        }
    }
}
</style>
<script>
import Vue from "vue";
import lodash from "lodash";
Object.defineProperty(Vue.prototype, '_', { value: lodash});
export default {
    name: 'reviewerLeaderboardTable',
    props:{
        reviewerList:{
            type: Array,
            required: true
        }
    },
    methods:{
        sortByReviewed(){
            // console.log('sort by resolved called');
            this.reviewerList = _.sortBy(this.reviewerList, (o) => {
              return o.reviews
          })
        },
        sortByComments(){
            // console.log('sort by comments');
            this.reviewerList = _.sortBy(this.reviewerList, (o) => {
                return o.comments
            })
        },
        sortByRT(){
            // console.log('sort by reaction time');
            this.reviewerList = _.sortBy(this.reviewerList, (o) => {
                return o.rt;
            })
        },
        sortByRRT(){
            // console.log('sort by review turnaround time');
            this.reviewerList = _.sortBy(this.reviewerList, (o) => {
                return o.rtt;
            })
        }
    }
}
</script>