<template>
    <div>
        <div ref="donutChart"></div>
    </div>
</template>
<style lang="scss" scoped>

</style>
<script>
import Plotly from 'plotly.js/dist/plotly';
export default {
    name: 'donutChart',
    props:{
        graphData: {
            type: Object
        }
    },
    data(){

        var layout = {
            title: 'Code Focus',
            annotations: [
                {
                font: {
                    size: 20
                },
                showarrow: false,
                text: '',
                x: 0.17,
                y: 0.5
                },
            ],
            width: 332,
            height: 398,
            showlegend: false,
            legend: {orientation: "h"},
            grid: {rows: 1, columns: 2}
        };
        return {layout}
    },
    mounted(){
        this.prepareGraph();
    },
    methods:{

        prepareGraph(){
            let finalData = this.sortData(this.graphData);
        let graph = this.$refs.donutChart;
        Plotly.newPlot(graph, finalData, this.layout, {displayModeBar: false, responsive: true, staticPlot: true});
    },
    sortData(data){
        let values = [];
        values[0] = data.churn_code;
        values[1] = data.new_code;

        let gData = [{
            values: values,
            labels: ['Churn Code', 'New Code'],
            hoverinfo: 'label+percent+name',
            name: '',
            hole: .6,
            type: 'pie'
        }];

            return gData;
        }
    }
}
</script>