<template>
    <div id="chart">
        <canvas ref="chart"></canvas>
    </div>
</template>
<script>
import Chart from 'chart.js';
export default {
    name: 'LineChart',
    mounted() {
        var chart = this.$refs.chart;
        var ctx = chart.getContext("2d");
        var myChart = new Chart(ctx, {
            type: 'line',
            data: {
                    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
                    datasets: [{
                        label: '# of Votes',
                        data: [12, 19, 3, 5, 2, 3],
                        backgroundColor: [
                            'rgba(0, 0, 0, 0.0)',
                            'rgba(0, 0, 0, 0.0)',
                            'rgba(0, 0, 0, 0.0)',
                            'rgba(0, 0, 0, 0.0)',
                            'rgba(0, 0, 0, 0.0)',
                            'rgba(0, 0, 0, 0.0)'
                        ],
                        borderColor: [
                            'rgba(255,99,132,1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    },
                    {
                        label: '# of Votes',
                        data: [15, 12, 8, 2, 9, 13],
                        backgroundColor: [
                            'rgba(0, 0, 0, 0.0)',
                            'rgba(0, 0, 0, 0.0)',
                            'rgba(0, 0, 0, 0.0)',
                            'rgba(0, 0, 0, 0.0)',
                            'rgba(0, 0, 0, 0.0)',
                            'rgba(0, 0, 0, 0.0)'
                        ],
                        borderColor: [
                            'rgba(255,99,132,1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }]
                },
                options: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
        });
    }
}
</script>
<style lang="scss" scoped>

</style>