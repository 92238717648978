<template>
   <router-link :to="{ name: `${linkTo}`}" class="bg bg_slider">{{label}}</router-link> 
</template>
<script>
export default {
    name: 'navlistitem',
    props:{
        label: String,
        linkTo: String
    },
};
</script>
<style scoped>
.bg {
      font-family: 'Raleway';
      font-size: 1.5em;
      font-weight: bolder;
      line-height: 3em;
      padding: 10px;
      text-decoration: none;
      width: 400px;
      text-decoration: none;
      border-radius: 10px;
}
.bg_slider {
  --initialTextColor: #FFF;
  --slideTextColor: #805baf;

  --initialBgColor: #805baf;
  --slideBgColor: #FFF;

  color: var(--initialTextColor);

  background-image: linear-gradient(90deg,
      var(--initialBgColor) 0%,
      var(--initialBgColor) 50%,
      var(--slideBgColor) 50%,
      var(--slideBgColor) 100%);
  background-size: 400%;
  transition: background-position .3s cubic-bezier(.47, .1, 1, .63),
    color .2s linear;
  transition-delay: 0.0s, 0.15s;
}
.md-theme-default a:not(.md-button){
    color: white;
}
.md-theme-default a:not(.md-button):hover{
    color: #272838;
    text-decoration: none;
}

.bg_slider:hover {
  color: var(--slideTextColor);
  cursor: pointer;
  background-position: -50% 100%;
}
</style>