<template>
    <div class="bar">
        <div class="repo">repo1_backend - 50% (114 Commits)</div>
        <div class="progress_bar">
            <div ref="progressBar" class="progress"></div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.bar{
    display: flex;
    flex-direction: column;
    .repo{

    }
    .progress_bar{
        background: #F1F1F1;
        border: 1px solid #BDBDBD;
        box-sizing: border-box;
        border-radius: 10px;
        width: 300px;
        height: 18px;
        .progress{
            // width: 50%;
            height: inherit;
            // background: red;
            box-sizing: border-box;
            border: 1px solid #BDBDBD;
            border-radius: 10px;
        }
    }
}
</style>
<script>
export default {
    name: 'progressbar',
    props: {
        color: {
            type: String
        },
        percentage: {
            type: Number
        }
    },
    mounted(){
        let bar = this.$refs.progressBar;
        bar.style.width = `${this.percentage}%`;
        bar.style.backgroundColor = this.color;
    }
}
</script>