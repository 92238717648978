<template>
  <div id="app">
    <div class="l-view">
      <SideMenu :key="key"/>
    </div>
    <div class="r-view">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import SideMenu from '@/components/SideMenu.vue';
export default {
  name: 'App',
  components: {
    SideMenu
  },
  data(){
    return{
      key: false
    }
  },
  watch:{
    $route(to, from){
      this.key = !this.key
    }
  }
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Overpass:wght@400;600;800&display=swap');

$image-path: '~@/../mdb/mdbvue/img';
@import '~@/../mdb/mdbvue/scss/mdb-free.scss';


@import "~bulma/sass/utilities/_all";

$primary: #190845;
$primary-invert: findColorInvert($primary);
$twitter: #4099FF;
$twitter-invert: findColorInvert($twitter);

$colors: (
    "white": ($white, $black),
    "black": ($black, $white),
    "light": ($light, $light-invert),
    "dark": ($dark, $dark-invert),
    "primary": ($primary, $primary-invert),
    "info": ($info, $info-invert),
    "success": ($success, $success-invert),
    "warning": ($warning, $warning-invert),
    "danger": ($danger, $danger-invert),
    "twitter": ($twitter, $twitter-invert)
);

$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;
@import "~bulma/sass/utilities/_all";
@import "~bulma";
@import "~buefy/src/scss/buefy";

#app {
  font-family: 'Overpass';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: $primary;
  background-repeat: repeat-y;
  // overflow: auto;
}
.l-view{
  // float: left;
  position: fixed;
  left: 0;
  width: 20%;
  height: 100vh;
}
.r-view{
  position: fixed;
  // float: right;
  right: 0;
  overflow: auto;
  width: 80%;
  height: 100vh;

}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

body {
    background-color: #FFF;
    font-family: Lato,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;
    font-size: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html, body {
    height: 100%;
    overflow: visible;
}

body {
    margin: 0;
    background-repeat: repeat-y;
}
</style>
