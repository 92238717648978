<template>
    <div class="container">
        
        <div class="box">
            <div class="box-header">
                <label>Github Users</label>
                <span>
                    <label class="label2" id="links">Copy shareable invite link</label>
                    <md-tooltip md-direction="right">{{shareableLink}}</md-tooltip>
                    <span v-clipboard:copy="shareableLink" v-clipboard:success="onCopy">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/></svg>								
                    </span>
                </span>
            </div>
            <div class="box-body">
                <div class="box-card" v-for="user in userList" :key="user.login">
                    <label>{{user.login}}</label>
                    <md-button v-if="!user.uses_slack" class="md-raised md-primary add-btn" @click="addUser(user)">Add</md-button>
                    <md-button v-else class="md-raised remove-btn" @click="removeUser(user)" v-bind:disabled="loggedInUser == user.login ? true : false">Remove</md-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Axios from 'axios';
import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard)
export default {
    name: 'AddUsers',
    data(){
        let userList = [];
        let loggedInUser;
        let shareableLink;
        return {
            userList,
            loggedInUser,
            shareableLink
        }
    },
    methods: {
        fetchUsers(org_id){
            Axios.get(`${process.env.VUE_APP_BASEURL}/get_users`, {
                params: {
                    org_id: org_id
                }
            }).then((response) => {
                // console.log(response.data);
                this.userList = response.data;
            })
        },
        addUser(params){
            // console.log(params);
            Axios.post(`${process.env.VUE_APP_BASEURL}/add_user`, {
                login: params.login
            }).then((response) => {
                // console.log(response.data)
                this.fetchUsers(this.$route.params.id);
            })
        },
        removeUser(params){
            // console.log(params);
            Axios.post(`${process.env.VUE_APP_BASEURL}/remove_user`, {
                login: params.login
            }).then((response) => {
                // console.log(response.data)
                this.fetchUsers(this.$route.params.id);
            })
        },
        onCopy(e){
            // console.log(e.text)
        }
    },
    created(){
        this.shareableLink = `${process.env.VUE_APP_BASEURL}/getonboard/${this.$route.params.id}`
        if(localStorage.getItem("githubUser")){
            this.loggedInUser = JSON.parse(localStorage.getItem("githubUser")).login
            // console.log(this.loggedInUser)
        }
        this.fetchUsers(this.$route.params.id)
    }

}
</script>
<style lang="scss" scoped>
.container {
    width: 80%;
    
    .box {
        display: flex;
        flex-direction: column;
        margin-top: 90px;
        padding: 0px;
        background: none;
        // border-radius: 10px;
        border-radius: 12px 12px 12px 12px;
        .box-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            background-color: #57595D;
            height: 70px;
            padding: 15px;
            border-radius: 12px 12px 0px px;
            label{
                text-transform: capitalize;
                font-size: 20px;
                color: white;
                font-weight: bold;
            }
            .label2{
                font-weight: 500;
            }
        }
        .box-body {
            display: flex;
            flex-direction: column;
            padding: 15px;
            background-color: #2E3034;
            border-radius: 0px 0px 12px 12px;
            .box-card {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding: 15px;
                border-bottom: 1px solid white;
                color: white;
                .add-btn{
                    background-color: #805baf;
                    color: white;
                    letter-spacing: 2px;
                }
                .remove-btn{
                    background-color: white;
                    color: #805baf;
                    letter-spacing: 2px;
                }
            }
            .box-card:last-child{
                border-bottom: none;
            }
        }
    }
}
</style>