<template>
  <div class="container">
    <div class="inner-container">
            <br/><br/><br/>
            <div class="table">
                <div class="table-header">
                    <div class="headers">
                         <label class="author">Reviewer</label>
                        <label class="prs" v-on:click="sortByTotalPrs()">Total PRs</label>
                        <label class="m-size" v-on:click="sortByResolved()">PR Resolved</label>
                        <label class="a-size">Comments</label>
                        <b-tooltip label="Average Time To Resolution" position="is-right">
                            <label class="a-size" v-on:click="sortByAvgTTR()">Avg TTR</label>
                        </b-tooltip>
                    </div>
                    <div class="filter-list">
                        <multiselect v-model="repo_value" :options="repo_options" :multiple="true" @close="filterResultsByRepo()" @remove="repoOptionRemoved($event)" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Select repos" label="name" track-by="name" :preselect-first="true"></multiselect>
                        <multiselect v-model="team_value" :options="team_options" :multiple="true" @close="filterResultsByTeam()" @remove="teamOptionRemoved($event)" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Select teams" label="team_name" track-by="team_name" :preselect-first="true"></multiselect>
                        <multiselect v-model="time_value" :options="time_options" :searchable="false" @close="filterResultsByTime()" :close-on-select="false" :show-labels="false" label="label" track_by="val"></multiselect>
                    </div>
                </div>
                <div v-if="resultsFound">
                    <div class="table-rows" v-for="rec in leaderboardData" :key="rec.github_login">
                        <label class="author">{{ rec.github_login }}</label>
                        <label class="prs">{{ rec.total_prs }}</label>
                        <label class="m-size">{{ rec.resolved_prs }}</label>
                        <label class="a-size">N/A</label>
                        <label class="a-size" v-if="rec.avg_ttr < 0.5">0</label>
                        <label class="a-size" v-else>{{ _.round(rec.avg_ttr) }}</label>
                    </div>
                </div>
                <div v-else>
                    NO RECORDS IN LEADERBOARD
                </div>
            </div>
    </div>
  </div>
</template>
<script>
import Buefy from "buefy";
import axios from 'axios';
import Vue from "vue";
import Multiselect from 'vue-multiselect';
// Vue.component('multiselect', Multiselect)
import lodash from "lodash"
Object.defineProperty(Vue.prototype, '_', { value: lodash});
export default {
  name: "leaderboard",
  components: {
      Multiselect,
  },
  beforeCreate() {
    Vue.use(Buefy);
  },
  data() {
    let isReviewer = true;
    let reviewerData;
    let authorData;
    let leaderboardData = [];
    let totalPRSortState = 'asc';
    let filtersActive = false;
    let originalData = [];
    let filteredData = [];
    let resultsFound = true;
    return {
      isReviewer,
      isPublic: false,
      reviewerData,
      authorData,
      leaderboardData,
      totalPRSortState,
      team_value: null,
      team_options: [],
      repo_options: [],
      repo_value: null,
      time_value: {'label':'14d', 'val': 14},
      time_options: [
                        {'label': '7d', 'val': 7},
                        {'label':'14d', 'val': 14},
                        {'label':'4w', 'val': 28},
                        {'label': '12w', 'val': 84},
                        {'label': '24w', 'val': 168}
                    ],
      originalData,
      filteredData,
      resultsFound,
      fakeData: [],
      offset: 0,
      limit: 10,
      prList: []
    };
  },
  methods: {
      sortByTotalPrs: function(){
          // console.log('sort called')
          this.leaderboardData = _.sortBy(this.leaderboardData, (o) => {
              return o.total_prs
          })
        //   return array
      },
      sortByAvgTTR: function(){
          // console.log('avg ttr');
          this.leaderboardData = _.sortBy(this.leaderboardData, (o) => {
              return o.avg_ttr
          })
      },
      sortByResolved: function(){
          this.leaderboardData = _.sortBy(this.leaderboardData, (o) => {
              return o.resolved_prs
          })
      },
      revertTTR: function(){

      },
      filterResultsByTeam: function(){
        // console.log(this.team_value)
        if(this.team_value){
            if(this.team_value.length > 0)
            {
                this.leaderboardData = this.leaderboardData.reduce((accumulator, currentValue) =>{
                this.team_value.forEach((elem) => {
                    // console.log(elem.team_id)
                    //   // console.log(currentValue.teams)
                    if(currentValue.teams.includes(elem.team_id)){
                        // console.log(currentValue)
                        accumulator.push(currentValue)
                    }
                })
                    return accumulator;
                }, [])
            }
          }
      },
      filterResultsByRepo: function(){
          if(this.repo_value){
              if(this.repo_value.length > 0){
                  this.leaderboardData = this.leaderboardData.reduce((acc, cVal) => {
                    this.repo_value.forEach((elem) => {
                        if(cVal.repo_id == elem.id){
                            // console.log('found')
                            acc.push(cVal)
                        }
                    })
                    return acc;
                }, [])
              }
          }
      },
      filterResultsByTime: function(){
        // console.log(this.time_value)
        // console.log(this.leaderboardData);
        this.leaderboardData = this.originalData.reduce((acc, cVal) => {
            if(cVal.most_recent_pr < this.time_value.val){
                acc.push(cVal)
            }
            return acc
        }, [])
        if(this.team_value){
            if(this.team_value.length > 0){
                this.filterResultsByTeam();
            }
        } 
        if(this.repo_value){
            if(this.repo_value.length > 0){
                this.filterResultsByREpo();
            }
        }
      
      },
      repoOptionRemoved: function(event){
          this.repo_value = this.repo_value.filter((elem) => {
              if (elem.id != event.id){
                  return elem;
              }
          });

          if(this.repo_value.length == 0){
              // console.log('empty')
              // console.log(this.originalData)
              this.leaderboardData = this.originalData;
          }

          else{
              // console.log(this.repo_value)
                this.leaderboardData = this.leaderboardData.reduce((acc, cVal) => {
                    this.repo_value.forEach((elem) => {
                        if(cVal.repo_id == elem.id){
                            // console.log('found')
                            acc.push(cVal);
                        }
                    })
                    return acc;
                }, [])
          }
      },
      teamOptionRemoved: function(event){
            this.team_value = this.team_value.filter((elem) => {
                if (elem.team_id != event.team_id){
                    return elem;
                }
            });

            if(this.team_value.length == 0){
                // console.log('empty')
                // console.log(this.originalData)
                this.leaderboardData = this.originalData;
            }

            else{// console.log(this.team_value)
            this.leaderboardData = this.leaderboardData.reduce((acc, cVal) => {
                this.team_value.forEach((elem) => {
                    if(cVal.repo_id == elem.id){
                        // console.log('found')
                        acc.push(cVal);
                    }
                })
                return acc;
            }, [])}
      },
      scroll () {
        window.onscroll = () => {
            if ((window.innerHeight + window.pageYOffset) >= document.body.scrollHeight) {
                // alert("you're at the bottom of the page");
                // console.log('true')
                // // console.log(ev)
                this.offset = this.offset + 10;
                for(let i = this.offset; i < this.offset + 10; i++){
                    this.leaderboardData.push(response.data[i])
                    this.originalData.push(response.data[i])
                }
                if(this.team_value){
                    if(this.team_value.length > 0){
                        this.filterResultsByTeam();
                    }
                }
                if(this.repo_value){
                    if(this.repo_value.length > 0){
                        this.filterResultsByREpo();
                    }
                }
            }
        };
      },    
  },
  created(){
      axios.get(`${process.env.VUE_APP_BASEURL}/get_teams`,{
          params:{
              org_id: this.$route.params.id
          }
      }).then((response) => {
        //   // console.log(response.data)
          this.team_options = response.data
      })
      
      axios.get(`${process.env.VUE_APP_BASEURL}/get_org_repos`,{
          params:{
              org_id: this.$route.params.id
          }
      }).then((response) => {
        //   // console.log(response.data)
          this.repo_options = response.data
      })
  },
  mounted(){
    this.scroll();
    axios.get(`${process.env.VUE_APP_BASEURL}/leaderboard`,{
        params: {
            org_id: this.$route.params.id
        }
    }).then((response) => {
        if(response.data.length < 10){
            this.leaderboardData = response.data;
            this.originalData = this.leaderboardData;
        }
        else{
            for(let i = this.offset; i < this.offset + 10; i++){
                this.leaderboardData.push(response.data[i])
                this.originalData.push(response.data[i])
            }
        }     
        if(this.leaderboardData.length == 0){
            this.resultsFound = false;
        }
    })

  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.container {
  .inner-container {
    width: 80%;
    .table {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 12px 12px 12px 12px;
        background: none;
        .table-header {
            border-radius: 12px 12px 0px 0px; 
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 110px;
            
            background-color: #57595D;
            font-size: 20px;
            // letter-spacing: 1.2px;
            color: white;
            .headers{
                display: flex;
                flex-direction: row;
                text-align: center;
                justify-content: space-between;
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 25px;
            }
            label {
                font-weight: 400;

            }
            .author {
                width: 350px;
                text-align: left;
            }
            .prs {
                width: 120px;
                text-align: center;                
            }
            .m-size {
                width: 120px;
                text-align: center;
            }
            .a-size {
                width: 120px;
                text-align: center;                                
            }
            .c-size {
                width: 120px;
                text-align: center
            }
            .filter-list {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                padding-bottom: 10px;
                .multiselect{
                    // background-color: black;
                    width: 250px;
                    .multiselect__tags{
                        background: none !important;
                    }
                }
            }
        }
        .table-rows {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border-bottom: 2px solid white;
            background-color: #2E3034;
            color: white;
            height: 55px;
            padding-top: 15px;
            padding-left: 10px;
            padding-right: 10px;
            .author {
                width: 350px;
                text-align: left;
                margin-left: 8px;
            }
            .prs {
                width: 120px;
                text-align: center;                                
            }
            .m-size {
                width: 120px;
                text-align: center;                                
            }
            .a-size {
                width: 120px;
                text-align: center;                                
            }
            
        }
        .table-rows:last-child{
            border-bottom: none;
            border-radius: 0px 0px 12px 12px;
        }
    }
  }
}
</style>