<template>
    <div class="table">
        <div class="table-header">
            <label>Rank</label>
            <label>Author</label>
            <label v-on:click="sortByCommits()">#Commits</label>
            <label>#Merge</label>
            <label>LTC</label>
            <label>Efficiency</label>
        </div>
        <div class="table-body">
            <!-- <div class="table-row"></div> -->
            <div class="table-row" v-for="(author, index) in authorList" :key="index">
                <label class="rank">{{index + 1}}</label>
                <label class="author-name">{{author.github_login}}</label>
                <label class="commits">{{author.commit_count}}</label>
                <label class="merge">{{author.merge_pr_count}}</label>
                <label class="ltc">{{author.lead_time_to_change}}</label>
                <label class="efficiency">{{author.efficiency}}</label>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.table{
    display: flex;
    flex-direction: column;
    .table-header{
        display: flex;
        flex-direction: row;
        height: 60px;
        width: inherit;
        background: #F1F1F1;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px 0 15px ;
        label{
            font-family: Overpass;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 28px;
            color: #190845;
            width: 20%;
        }
    }
    .table-body{
        display: flex;
        flex-direction: column;
        height: 25vh;
        overflow-y: auto;
        .table-row{
            display: flex;
            flex-direction: row;
            width: inherit;
            height: 30px;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #e5e5e5;
            border-left: 1px solid #e5e5e5;
            border-right: 1px solid #e5e5e5;
            padding: 0 15px 0 15px ;
            label{
                width: 20%;
            }
        }
    }
}
</style>
<script>
import Vue from "vue";
import lodash from "lodash"
Object.defineProperty(Vue.prototype, '_', { value: lodash});
export default {
    name: 'authorLeaderboardTable',
    props: {
        authorList:{
            type: Array,
            // required: true
        }
    },
    methods:{
        sortByCommits(){
            this.authorList = _.sortBy(this.authorList, (o) => {
                return o.commits
            })
        },
        sortByMerge(){
            this.authorList = _.sortBy(this.authorList, (o) => {
                return o.mergedCommits;
            })
        },
        sortByLTC(){
            this.authorList = _.sortBy(this.authorList, (o) => {
                return o.ltc
            })
        },
        sortByEfficiency(){
            this.authorList = _.sortBy(this.authorList, (o) => {
                return o.efficiency;
            })
        }
    }
}
</script>