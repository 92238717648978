<template>
    <div class="whole">
        <div class="page-header">
          <label>Integrations</label>
          <!-- <SearchBox /> -->
        </div>
        <hr />

        <div class="error-message" v-if="displayMessage">
            <label>You have to integrate Github first</label>
        </div>        

        <div class="middle-box">
            <div class="box-header">
                <label>Available Tools</label>
            </div>
            <hr style="height: 2px; color: #e5e5e5"/>
            <div class="tools">
                <IntegrationCard v-for="(int, index) in integrations" :label="int.label" :logo="int.logo" :available="int.available" :link="int.link" 
                    :hint="int.hint" :key="index" @cardClick="showModal = true" :action="int.action" :message="int.message"/>
            </div>
        </div>
        <SlackModal v-if="showModal" @closeModal="showModal = false" :orgList="installations"/>
        <div class="end-box">
            <div class="box-header">
                <label>Your Integrations</label>
            </div>
            <hr style="height: 4px; color: #e5e5e5"/>
            <div class="tools">
                <div class="message" v-if="!gotInstallation">
                    <label>There are no integrations configured so far.</label>
                </div>
                <div v-if="gotInstallation && isAuth" class="table">
                    <div class="table-header">
                        <label for="">Service name</label>
                        <label for="">Organisation</label>
                        <label for="">Admin</label>
                        <label for="">Date Added</label>
                        <!-- <label for="">Service name</label> -->
                    </div>
                    <div class="table-body">
                        <div v-for="(item, index) in orgIntegrations" :key="index" class="table-row no-pointer">
                            <label>{{item.service_name}}</label>
                            <label>{{item.account_name}}</label>
                            <label>{{item.admin_name}}</label>
                            <label>{{new Date(item.date).toDateString()}}</label>
                        </div>
                    </div>
                </div>
                <div v-if="gotInstallation && !isAuth" class="table">
                    <div class="table-header">
                        <label for="">Service name</label>
                        <label for="">Organisation</label>
                        <label for="">Date Added</label>
                        <!-- <label for="">Service name</label> -->
                    </div>
                    <div class="table-body">
                        <div v-for="(ins, index) in installations" :key="index" class="table-row" @click="goToOrg(ins.account.id)">
                            <label v-if="ins.account.login" >GitHub</label>
                            <label >{{ins.account.login}}</label>
                            <label>{{new Date(ins.created_at).toDateString()}}</label>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.whole{
    margin-top: 40px;
    height: 80vh;
    width: 98%;
    .page-header{
        display: flex;
        flex-direction: row;
        width: 60vw;
        justify-content: space-between;
        align-items: center;
        label{
            font-size: 50px;
            font-weight: bold;
            height: auto;
            color: #190845;
        }
    }
    .middle-box{
        width: inherit;
        height: 317px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        // align-items: flex-start;
        padding: 30px;
        border: 1px solid #E5E5E5;
        border-radius: 12px;
        .box-header{
            font-family: Overpass;
            font-style: normal;
            font-weight: bold;
            font-size: 40px;
            color: #190845;
            // height: 50px;
            // line-height: 30px;
            align-self: flex-start;
        }
        .tools{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: inherit;
        }
        
    }
    .end-box{
        width: inherit;
        height: 202px auto;
        display: flex;
        flex-direction: column;
        // justify-content: space-around;
        align-items: flex-start;
        margin-top: 29px;
        padding: 30px;
        border: 1px solid #E5E5E5;
        border-radius: 12px;
        .box-header{
            font-family: Overpass;
            font-style: normal;
            font-weight: bold;
            font-size: 36px;
            color: #190845;
            // height: 50px;
            // line-height: 30px;
            // align-self: flex-start;
        }
        .tools{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: inherit;
            .message{
                width: inherit;
                background-color: #AAD4C8;
                height: 68px;
                font-family: Overpass;
                font-style: normal;
                font-weight: bold;
                font-size: 28px;
                color: #FFFFFF;
                line-height: 63px;

                label{
                    margin: 0 auto;
                }
            }
            .table{
                display: flex;
                flex-direction: column;
                width: inherit;
                justify-content: space-between;
                user-select: none;
                .table-header{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    background-color: #E5E5E5;
                    height: 50px;
                    align-items: center;
                    padding: 10px;
                    margin-bottom: 5px;
                    label{
                        font-size: 20px;
                        font-weight: 600;
                        width: 25%;
                    }
                }
                .table-body{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding: 10px;
                    .table-row{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        height: 40px;
                        width: 100%;
                        cursor: pointer;
                        label{
                            font-size: 15px;
                            width: 25%;
                        }
                    }
                    .table-row:hover{
                        background-color: #E5E5E5;
                        font-weight: 500;
                    }
                    .no-pointer{
                        cursor: default;
                    }
                }
                
            }
        }
    }
    .error-message{
            width: inherit;
            background-color: #FF5B64;
            height: 68px;
            font-family: Overpass;
            font-style: normal;
            font-weight: bold;
            font-size: 28px;
            color: #FFFFFF;
            line-height: 63px;

            label{
                margin: 0 auto;
            }
        }   
}
</style>
<script>
import SearchBox from '@/components/SearchBox.vue';
import IntegrationCard from '@/components/IntegrationCard.vue';
import SlackModal from '@/components/SlackModal.vue';
import Integrations from '@/services/integrations.js';
import { mapState, mapActions, mapGetters } from 'vuex';
import axios from 'axios';
export default {
    name: 'integrations',
    components:{
        SearchBox,
        IntegrationCard,
        SlackModal
    },
    data(){
        let id, state, url, showModal, integrations = [];
        return{
            id,
            state,
            url, showModal,
            integrations,
            orgIntegrations: [],
            isAuth: false
        }
    },
    computed: {
        ...mapState([
            'gotInstallation',
            'installations',
            'isAccountsPage',
            'displayMessage'
        ]),
        ...mapGetters([
            'getSlackState',
            'getUserSlackState',
        ])
    },
    methods:{
        ...mapActions([
            'getInstallations',
            'setGithubIntegrated',
            'changeSlackState',
            'setOrganisation',
            'setDisplayMessage'
        ]),
        goToOrg(org_id){
            this.$router.replace({ name: 'review-metrics', params:{id: org_id}})
        }
    },
    created(){
        this.setDisplayMessage(false)
        const token = localStorage.getItem('accessToken');
        this.getInstallations(token).then(() => {
            if(this.gotInstallation){
                // console.log(this.installations);
                this.integrations = [
                    {logo: 'github-logo.svg', label: 'GitHub', available: false, link: 'https://github.com/apps/go-hedwig/installations/new', hint: 'Click to add organisation', action: 'install', message:'You already have an integration'},
                    {logo: 'slack-logo.svg', label: 'Slack', available: true, link: 'no link', hint: 'Click to add Slack', action: 'click', message:''},
                    {logo: 'jira-seeklogo.svg', label: 'Jira', available: false, link: 'https://github.com/apps/go-hedwig/installations/new', message: 'Coming Soon!', action: 'none'}
                ]
            } else {
                // console.log('this else working')
                this.integrations = [
                    {logo: 'github-logo.svg', label: 'GitHub', available: true, link: 'https://github.com/apps/go-hedwig/installations/new', hint: 'Click to add organisation', action: 'install', message:''},
                    {logo: 'slack-logo.svg', label: 'Slack', available: false, link: 'no link', hint: 'Github integration is required for Slack Integration', action: 'click', message:''},
                    {logo: 'jira-seeklogo.svg', label: 'Jira', available: false, link: 'no link', message: 'Coming Soon!', action: 'none'}
                ]
            }
        })
        
        if(this.$route.name == 'auth-integrations'){
            this.isAuth = true
            axios.post(`${process.env.VUE_APP_BASEURL}/integrations`, {
                org_id: this.$route.params.id
            }).then((response) => {
                // console.log(response.data);
                this.orgIntegrations = response.data;
            })
        } else {
            this.isAuth = false;
        }
    },
}
</script>