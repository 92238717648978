<template>
    <div class="container">
        <div v-if="slackIsInstalled">
            <template v-if="hasChannel">
                <div class="page-header">
                    <label>Team Reminders</label>
                </div>
                <!-- <hr /> -->
                <div class="error-message" v-if="displayMessage1">
                    <label>Go to settings and Install Slack</label>
                </div>
                <div class="error-message" v-if="displayMessage2">
                    <label>Go to settings and authenticate your slack account</label>
                </div>
                <div class="body">
                    <label class="header">
                        Slack Channels
                    </label>
                    <div class="bg bg_slider" v-for="channel in installedChannelList" :key="channel.id" v-on:click="goToChannelSetting(channel)">
                        <label>#{{channel.name}}</label>
                        <label>›</label>
                    </div>
                    <div class="btn-container">
                        <button class="btn" @click="showSetChannels()">Set up a new channel</button>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="page-header">
                    <label>Select Channel</label>
                </div>
                <!-- <hr /> -->
                <div class="body">
                    <label class="header">
                        Add Hedwig to a team-specific channel
                    </label>
                    <b-select class="channel-select" placeholder="Select channel" expanded v-model="channelSelected" v-on:input="change">
                        <option v-for="channel in availableChannels" :value="channel" :key="channel.id">{{channel.name}}</option>
                    </b-select>
                    <div class="btn-container">
                        <button class="btn" @click="addChannel()" id="add">Add Channel</button>
                    </div>
                </div>
            </template>
        </div>
        <div v-else>
            <div>Please Install Slack</div>
        </div>
    </div>
</template>

<script>
import Buefy from 'buefy';
import Vue from 'vue';
import axios from 'axios';
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
    name: 'NotificationRules',
    data() {
        let hasChannel;
        let channelSelected;
        let channelList = [];
        let installedChannelList = [];
        let availableChannels = [];
        let slackIsInstalled;
        let currentOrganisation = {};
        let displayMessage1 = false, displayMessage2 = false;
        return {
            hasChannel,
            channelSelected,
            channelList,
            installedChannelList,
            availableChannels,
            slackIsInstalled,    
            currentOrganisation,
            displayMessage1, displayMessage2
        }
    },
    methods: {
        goToChannelSetting: function(channel) {
            // console.log("Works")
            this.$router.push({ name: 'team-settings', params: { id: channel.org_id, channel_id: channel.channel_id}})
            this.setSettings(channel)
        },
        change: function() {
            // // console.log(this.channelSelected)
            document.getElementById("add").style.backgroundColor = "#61bd4f";
            document.getElementById("add").style.color = "white";
        },
        addChannel: function() {
            // console.log(this.channelSelected);
            axios.post(`${process.env.VUE_APP_BASEURL}/slack/send_message`, {
                token: localStorage.getItem('slack_token'),
                channel_id: this.channelSelected.id,
                channel_name:  this.channelSelected.name,
                text: "This channel has been set from notifications",
                as_user: false,
                org_id: this.$route.params.id
            }).then((response) => {
                // console.log(response); //settings
                let setting = response.data[1];
                this.setSettings(setting)
                this.$router.push({ name: 'team-settings', params: { id: response.data[1].org_id, channel_id: response.data[1].channel_id }})
            }).catch((error) => {
                // console.log(error)
            })
        },
        ...mapActions([
            'setSettings'
        ]),
        goToChannel: function() {
            this.hasChannel = false;
            
            const accessToken = localStorage.getItem("slack_token");
            axios.post(`${process.env.VUE_APP_BASEURL}/slack/all_channels`, {
                token: accessToken,
            }).then((response) => {
                this.channelList = response.data;
                // console.log(this.channelList)
                // console.log(this.installedChannelList)
                let installedChannelIds = this.installedChannelList.map((arr) => {
                    return arr.channel_id;
                })

                // console.log(installedChannelIds);
                
                this.channelList.forEach((arr) => {
                    if(!installedChannelIds.includes(arr.id)){
                        // console.log(arr.id)
                        this.availableChannels.push(arr)
                    }
                })
               // console.log(this.availableChannels)

            }).catch((error) => {
                // console.log(error);
            });

        },
        showSetChannels: function(){
            this.hasChannel = false;
        }
    },
    computed: {
        ...mapState([
            'slackState',
            'organisation',
            'accessToken',
        ]),
        ...mapGetters([
            'getSlackState',
            'getUserSlackState'
        ]),
    },
    beforeCreate() {
        Vue.use(Buefy);
    },
    created() {
        this.slackIsInstalled = this.getSlackState;
        let githubUserName;
        // if(localStorage.getItem("githubUser")) {
        //     githubUserName = JSON.parse(localStorage.getItem("githubUser")).login
        // }
        if(this.slackIsInstalled == false){
            // redirect to slack installation page external
            // let id = this.$route.params.id;
            // let state = id + "&type=identity" + `&username=${githubUserName}`;
            // let url = `${process.env.VUE_APP_BASEURL}/auth/manual/slack?state=${state}`;
            // window.location = url
            this.displayMessage1 = true;
        } else if(!this.getUserSlackState) {
            this.displayMessage2 = true;
        }
        else {
                //do the get channels call here
                // console.log(this.currentOrganisation);
                axios.get(`${process.env.VUE_APP_BASEURL}/get_channels`, {
                    params:{
                        org_id: this.$route.params.id
                    }
                }).then((response) => {
                    if(response.data.channels.length != 0){
                        this.hasChannel = true;
                        this.installedChannelList = response.data.channels
                    } else {
                        this.hasChannel = false;
                    }
                    // console.log(response.data)
                })
                if(!this.hasChannel){
                    // console.log('this this')
                    this.goToChannel();
                }
            
        }
        this.currentOrganisation = this.organisation;
    },
    beforeMount(){
        // console.log("before mounted")
    }
}
</script>

<style lang="scss" scoped>
.container {
    .page-header{
        font-size: 30px;
        text-align: left;
        margin-bottom: 20px;
        margin-top: 40px;
        color: #190845;
        font-weight: bold;
    }
    .error-message{
            width: inherit;
            background-color: #FF5B64;
            height: 68px;
            font-family: Overpass;
            font-style: normal;
            font-weight: bold;
            font-size: 28px;
            color: #FFFFFF;
            line-height: 63px;

            label{
                margin: 0 auto;
            }
        }
    .body {
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .header{
            font-size: 20px;
            text-align: left;
            margin-bottom: 20px;
            margin-top: 40px;
            color: #190845;
            font-weight: bold;
        }
        .channel-select{
            margin: auto;
            width: 400px;
        }
        .bg {
            font-family: 'Raleway';
            font-size: 1.5em;
            font-weight: bolder;
            line-height: 3em;
            padding: 10px;
            text-decoration: none;
            width: 400px;
            text-decoration: none;
            border-radius: 10px;
            margin: auto;
            margin-bottom: 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 0px 12px 0px 12px;
        }
        .bg_slider {
            --initialTextColor: #FFF;
            --slideTextColor: #190845;

            --initialBgColor: #190845;
            --slideBgColor: #FFF;

            color: var(--initialTextColor);

            background-image: linear-gradient(90deg,
                var(--initialBgColor) 0%,
                var(--initialBgColor) 50%,
                var(--slideBgColor) 50%,
                var(--slideBgColor) 100%);
            background-size: 400%;
            transition: background-position .3s cubic-bezier(.47, .1, 1, .63),
                color .2s linear;
            transition-delay: 0.0s, 0.15s;
        }
        .bg_slider:hover {
            color: var(--slideTextColor);
            cursor: pointer;
            background-position: -50% 100%;
            border: #190845 2px solid;
        }
        .btn-container{
            width: 160px;
            margin: auto;
            margin-top: 40px;
            .btn{
                width: inherit;
                height: 30px;
                padding: 0;
                color: #190845;
                font-weight: 600;
            }
        }
        
    }
}
</style>