<template>
    <div id="chart">
        <canvas ref="chrt"></canvas>
    </div>
</template>
<script>
import Chart from 'chart.js';
export default {
    name: 'chart1',
    mounted() {
            var chart = this.$refs.chrt;
            var ctx = chart.getContext("2d");
             // Return with commas in between
            var numberWithCommas = function(x) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            };

            var dataPack1 = [70, 60, 50, 84]
            var dataPack2 = [60, 90, 130, 260];
            var dataPack3 = [210, 270, 300, 256];
            var dataPack4 = [180, 400, 600, 440];
            var dataPack5 = [211, 408, 258, 424];
            var dates = ["May 1", "May 2", "May 3", "May 4"];

// Chart.defaults.global.elements.rectangle.backgroundColor = '#FF0000';

            var bar_chart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: dates,
                    datasets: [
                    {
                        label: '<50LOC',
                        data: dataPack1,
                        backgroundColor: "rgba(55, 160, 225, 0.7)",
                        hoverBackgroundColor: "rgba(55, 160, 225, 0.7)",
                        hoverBorderWidth: 2,
                        hoverBorderColor: 'lightgrey',
                        barThickness: 6,
                        maxBarThickness: 8,
                        minBarLength: 2,
                        order: 0
                    },
                    {
                        label: '<250LOC',
                        data: dataPack2,
                        backgroundColor: "rgba(105, 48, 85, 0.9)",
                        hoverBackgroundColor: "rgba(105, 48, 85, 0.9)",
                        hoverBorderWidth: 2,
                        hoverBorderColor: 'lightgrey',
                        barThickness: 6,
                        maxBarThickness: 8,
                        minBarLength: 2,
                        order: 0
                    },
                    {
                        label: '<1000LOC',
                        data: dataPack3,
                        backgroundColor: "rgba(125, 211, 105, 0.7)",
                        hoverBackgroundColor: "rgba(125, 211, 105, 0.7)",
                        hoverBorderWidth: 2,
                        hoverBorderColor: 'lightgrey',
                        barThickness: 6,
                        maxBarThickness: 8,
                        minBarLength: 2,
                        order: 0,
                    },
                    {
                        label: '1000LOC+',
                        data: dataPack4,
                        backgroundColor: "rgba(225, 58, 55, 0.7)",
                        hoverBackgroundColor: "rgba(225, 58, 55, 0.7)",
                        hoverBorderWidth: 2,
                        hoverBorderColor: 'lightgrey',
                        barThickness: 6,
                        maxBarThickness: 8,
                        minBarLength: 2,
                        order: 0
                    },
                    {
                        label: '%PR Above 500LOC',
                        data: dataPack5,
                        backgroundColor: "rgba(225, 58, 55, 0.0)",
                        borderColor: 'rgba(255,99,132,1)',
                        type: 'line',
                        order: 2,
                        lineTension: 0
                    },
                    ]
                },
                options: {
                        animation: {
                        duration: 10,
                    },
                    tooltips: {
                                mode: 'label',
                    callbacks: {
                    label: function(tooltipItem, data) { 
                        return data.datasets[tooltipItem.datasetIndex].label + ": " + numberWithCommas(tooltipItem.yLabel);
                    }
                    }
                    },
                    scales: {
                    xAxes: [{ 
                        stacked: true, 
                        gridLines: { display: false },
                        }],
                    yAxes: [{ 
                        stacked: true, 
                        ticks: {
                                callback: function(value) { return numberWithCommas(value); },
                                }, 
                        }],
                    }, // scales
                    legend: {
                        display: true,
                        position: 'bottom'
                    }
                } // options
            }
            );
        }
    }
</script>
<style lang="scss" scoped>

</style>