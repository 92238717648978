<template>
    <div>
        <template v-if="subMenu">
            <div class="expandable">
                <div v-bind:class="{'item-main': true}">
                    <div class="menu-icon">
                        <img :src="require(`@/assets/sidemenu/${icon}.png`)" height="25" width="25"/>
                    </div>
                    <div v-bind:class="{label: true}" @click="changeState()">
                        {{label}}
                    </div>
                    <i v-if="hidden" v-bind:class="{'material-icons': true}" @click="changeState()">keyboard_arrow_down</i>
                    <i v-else v-bind:class="{'material-icons': true}" @click="changeState()">keyboard_arrow_up</i>
                </div>
                <div v-bind:class="{submenu: true, fadeout: hidden, fadein: !hidden}">
                    <div v-for="sItem in subMenuItems" :key="sItem.label" class="submenu-item" @click="checkGithubIntegration(sItem.label)">
                        <label v-if="!gotInstallation">{{sItem.label}}</label>
                        <router-link v-if="gotInstallation" class="white" :to="sItem.location">{{sItem.label}}</router-link>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="item-main">
                <div class="menu-icon">
                    <img :src="require(`@/assets/sidemenu/${icon}.png`)" height="25" width="25"/>
                </div>
                <div class="label">
                    <!-- {{label}} -->
                    <router-link class="white" :to="location">{{label}}</router-link>
                </div>
            </div>
        </template>
    </div>
</template>
<style lang="scss" scoped>
.expandable{
    display: flex;
    flex-direction: column;
    .item-main{
        display: flex;
        flex-direction: row;
        height: 40px;
        width: 200px;
        justify-content: flex-start;
        align-items: center;
        // margin-bottom: 30px;
        .menu-icon{
            display: flex;
            height: 25px;
            width: 25px;
            margin-right: 15px;
        }
        .label{
            font-size: 20px;
            color:white;
            font-family: Overpass;
            margin: 0px;
            cursor: pointer;
        }
        .selected-item{
            color: #FF5B64;
        }   
        .material-icons{
            font-size: 24px;
            color: white;
            justify-self: flex-end;
            user-select: none;
            cursor: pointer;
        }
        
    }
    
    .submenu{
        height: auto;
        width: 160px;
        margin-left: 40px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .submenu-item{
            color: white;
            cursor: pointer;
            .white{
                color: white;
                background: none !important;
            }
            .selected-item{
                color: #FF5B64;
            }
        }
    }
    .fadein, .fadeout{
        // opacity: 0;
        // transform:translate(0, -60px);
        display: none;
        // visibility: hidden;
        // overflow: hidden;
        // transition: transform 0.4s ease-in;
    }
    .fadein{
        // transform:translate(0, 0px);
        // opacity: 1;
        display: flex;
        // visibility: visible;
    }
}
.item-main{
        display: flex;
        flex-direction: row;
        height: 40px;
        width: 200px;
        justify-content: flex-start;
        align-items: center;
        
        .menu-icon{
            display: flex;
            height: 25px;
            width: 25px;
            margin-right: 15px;
        }
        .label{
            font-size: 20px;
            color:white;
            font-family: Overpass;
            margin: 0px;
            cursor: pointer;
            .white{
                color: white;
                background: none !important;
            }
            .selected-item{
                color: #FF5B64;
            }
        }
        .material-icons{
            font-size: 24px;
            color: white;
            cursor: pointer;
            justify-self: flex-end;
        }
    }

</style>
<script>
import { mapActions, mapState } from 'vuex';
export default {
    name: 'MenuItem',
    props:{
        icon:{
            type: String
        },
        label:{
            type: String
        },
        subMenu:{
            type: Boolean
        },
        subMenuItems:{
            type: Array
        },
        location:{
            type: Object
        }
    },
    data(){
        let subMenuVisible = false;
        let iconUrl;
        let hidden = true;
        let active = false;
        let id;
        return{
            subMenuVisible,
            iconUrl,
            hidden,
            active,
            id, 
        }
    },
    computed:{
        ...mapState([
            'gotInstallation'
        ])
    },
    mounted(){
        let pre = '../assets/sidemenu/';
        this.iconUrl = `${pre}${this.icon}.png`;
    },
    methods:{
        ...mapActions([
            'setDisplayMessage'
        ]),
        changeState(){
            // console.log('call change state')
            this.hidden = !this.hidden;
        },
        checkGithubIntegration(){
            if(this.gotInstallation){
                // console.log('installation found')
            } else {
                // console.log('installation not found');
                this.setDisplayMessage(true)
            }
        }
    }
    
}
</script>