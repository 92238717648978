*<template>
    <div class="whole">
        <div class="page-header">
            <label>Worklog</label>
        </div>
        <hr />
        <div class="filters-row">
            <template>
                <fish-select search multiple v-model="selected_repos" style="width: 150px; height: auto;" hint="Repos">
                    <fish-option v-for="(repo, index) in repo_options" :key="index" :index="repo.id" :content="repo.name"></fish-option>
                </fish-select>
            </template>
            <template>
                <fish-button @click="applyFilters()">Apply</fish-button>
            </template>
        </div>
        <div class="secondary-header">
            <label>Commits Timeline</label>
        </div>
        <div class="developer">
            <div class="top-bar">
                <div class="dev-name">
                    Anish Gupta's Commits
                </div>
                <div class="sort"></div>
            </div>
            <div class="table">
                <div class="titles">
                    <label for="" class="date">Date</label>
                    <label for="" class="time">Time</label>
                    <label for="" class="cid">Commit ID</label>
                    <label for="" class="msg">Commit Message</label>
                    <label for="" class="ltc">Lead Time to Change</label>
                    <label for="" class="focus">Focus</label>
                </div>
                <hr />
                <div class="row">
                    <label for="" class="date">Fri, 15 May</label>
                    <div class="time">
                        <label for="">12:30 PM</label>
                        <div>
                            <div class="quad"></div>
                            <div class="line"></div>
                        </div>
                    </div>
                    <label for="" class="cid">25e17br4</label>
                    <label for="" class="msg">Fix to the signup button for the hedwig landing page</label>
                    <label for="" class="ltc">Not merged</label>
                    <div for="" class="focus">
                        <div>
                            <DoughnutChart :size="'small'"/>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <label for="" class="date">Fri, 15 May</label>
                    <div class="time">
                        <label for="">12:30 PM</label>
                        <div>
                            <div class="quad filled"></div>
                            <div class="line"></div>
                        </div>
                    </div>
                    <label for="" class="cid">25e17br4</label>
                    <label for="" class="msg">Fix to the signup button for the hedwig landing page</label>
                    <label for="" class="ltc">3 hrs</label>
                    <div for="" class="focus">
                        <div>
                            <DoughnutChart :size="'small'"/>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <label for="" class="date">Fri, 15 May</label>
                    <div class="time">
                        <label for="">12:30 PM</label>
                        <div>
                            <div class="quad"></div>
                            <div class="line"></div>
                        </div>
                    </div>
                    <label for="" class="cid">25e17br4</label>
                    <label for="" class="msg">Fix to the signup button for the hedwig landing page</label>
                    <label for="" class="ltc">Not merged</label>
                    <div for="" class="focus">
                        <div>
                            <DoughnutChart :size="'small'"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.whole{
    margin-top: 40px;
    height: 100%;
    width: 98%;
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .page-header{
        display: flex;
        flex-direction: row;
        width: inherit;
        justify-content: space-between;
        align-items: center;
        label{
            font-size: 50px;
            font-weight: bold;
            height: auto;
            color: #190845;
        }
    }
    .filters-row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: inherit;
        align-items: baseline;
        margin-bottom: 20px;
    }
    .secondary-header{
        font-family: Overpass;
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 61px;

        color: #FF5B64;
    }
    .developer{
        width: 100%;
        height: auto;
        border: 1px #BDBDBD solid;
        padding: 24px 38px 20px 38px;
        .top-bar{
            display: flex;
            flex-direction: row;
            width: inherit;
            margin-left: 10px;
            align-items: center;
            justify-content: space-between;
            .dev-name{
                font-family: Overpass;
                font-style: normal;
                font-weight: bold;
                font-size: 30px;
                color: #5F5B5B;
                line-height: 46px;
            }
            .sort{

            }
        }
        .table{
            display: flex;
            flex-direction: column;
            width: 100%;
            // padding-left: 18px;
            // padding-right: 18px;
            hr {
                height: 1px;
                background-color: red;
                width: 100%;
            }
            .titles{
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                label{
                    font-family: Overpass;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 28px;
                    color: #5F5B5B;
                    // width: 150px;
                }
                .date{
                    width: 80px;
                }
                .time{
                    margin-right: 50px;
                    width: 150px;
                }
                .cid{
                    width: 90px;
                }
                .msg{
                    width: 200px;
                }
                .ltc{
                    width: 120px;
                }
                .focus{
                    width: 80px;
                }
            }
            .row{
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                height: 60px;
                label{
                    font-family: Overpass;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 25px;
                    color: #2D353C;
                    // width: 80px;
                }
                .date{
                    width: 80px;
                }
                .time{
                    // margin-right: 50px;
                    // width: 80px;
                    display: flex;
                    flex-direction: row;
                    width: 150px;
                    justify-content: space-between;
                    label{

                    }
                    .line{
                        border: 2px solid #bdbdbd;
                        box-sizing: border-box;
                        // transform: rotate(-45deg);
                        width: 18px;
                        height: 40px;
                        display: none;
                    }
                    .quad{
                        border: 2px solid #FF5B64;
                        box-sizing: border-box;
                        transform: rotate(-45deg);
                        width: 18px;
                        height: 18px;
                    }
                    .filled{
                    
                        background: #FF5B64;
                    }
                }
                .cid{
                    width: 90px;
                }
                .msg{
                    width: 200px;
                }
                .ltc{
                    width: 120px;
                }
                .focus{
                    width: 80px;
                    div{
                        width: 50px;
                        height: 50px;
                        margin: auto;
                    }
                }
            }
        }
    }
}
</style>
<script>
import axios from 'axios';
import DoughnutChart from '@/components/DoughnutChart.vue';
export default {
    name: 'CommitTimeline',
    components: {
        DoughnutChart
    },
    data(){
        return {
            repo_options: [], selected_repos: []
        }
    },
    methods:{
        getRepos(){
            axios.get(`${process.env.VUE_APP_BASEURL}/get_org_repos`,{
                params:{
                    org_id: this.$route.params.id
                }
            }).then((response) => {
                //   // console.log(response.data)
                this.repo_options = response.data
            })
        },
    },
    created(){
        this.getRepos();
    }

}
</script>