<template>
    <div class="container">
        <template v-if="active">
            <b-tabs type="is-toggle" expanded v-on:change="changeTab($event)">
                <b-tab-item label="Overview">
                    <div class="charts">
                        <div class="chrt">
                            <BarChart />
                        </div>
                        <div class="chrt">
                            <LineChart />
                        </div>
                        <div class="chrt">
                            <DoughnutChart />
                        </div>
                        <div class="chrt">
                            <BarChart />
                        </div>
                    </div>
                </b-tab-item>
                <b-tab-item label="Open PRs">
                    <div class="table">
                        <div class="table-header">
                            <label class="pullrequest">Pull Request</label>
                            <label class="reviewer">Reviewer</label>
                        </div>
                        <div class="table-rows">
                            <label class="pullrequest">[Repository1] PullRequestLink</label>
                            <label class="reviewer">Anish</label>
                        </div>
                    </div>
                </b-tab-item>
                <b-tab-item label="Turnaround Time">
                    <div class="table">
                        <div class="table-header">
                            <label class="pullrequest">Pull Request</label>
                            <label class="reviewer">Reviewer</label>
                            <label class="trt">Turnaround Time</label>
                        </div>
                        <div class="table-rows">
                            <label class="pullrequest">[Repository1] PullRequestLink</label>
                            <label class="reviewer">Anish</label>
                            <label class="trt">20 Days</label>
                        </div>
                    </div>
                </b-tab-item>
                <b-tab-item label="Code Churn">
                    <div class="table">
                        <div class="table-header">
                            <label class="pullrequest">Pull Request</label>
                            <label class="reviewer">Reviewer</label>
                            <label class="trt">PR Size</label>
                        </div>
                        <div class="table-rows">
                            <label class="pullrequest">[Repository1] PullRequestLink</label>
                            <label class="reviewer">Anish</label>
                            <label class="trt">+20 -7</label>
                        </div>
                    </div>
                </b-tab-item>
                <b-tab-item label="PR Size">
                    PR SIZE
                </b-tab-item>
            </b-tabs>
        </template>
        <template v-else>
            <div class="disabled-container">
                <!-- <label class="header">COMING SOON!</label>
                <label class="sub-header">Under works</label> -->
                <img src="../assets/under_construction_PNG65.png" height="250" width="400">
            </div>
        </template>
    </div>
</template>

<script>
import Buefy from 'buefy';
import Vue from 'vue';
import BarChart from '@/components/BarChart.vue';
import LineChart from '@/components/LineChart.vue';
import DoughnutChart from '@/components/DoughnutChart.vue';
import { mapGetters } from 'vuex';
export default {
    name: 'Dashboard',
    components: {
        BarChart,
        LineChart,
        DoughnutChart,
    },
    beforeCreate() {
        Vue.use(Buefy)
    },
    data (){
        let visibility = 0;
        let active = false;
        return {
            visibility,
            active
        }
    },
    methods: {
        changeTab: function($event) {
            this.visibility = $event
        }
    },
    computed: {
        ...mapGetters([
            'getSlackState',
            'getAccessToken',
            'getInstallations',
        ]),
    },
    created(){
        this.getSlackState;
        this.getAccessToken;
        this.getInstallations;
    }
}
</script>

<style lang="scss" scoped>
.container {
    width: 80%;
    .charts{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                width: 100%;
                justify-content: space-between;
                .chrt{
                    padding: 5px;
                    width: 50%;
                    
                }
            }
    .b-tabs .tab-content {
            padding: 0px !important;
        }
    .table {
        display: flex;
        flex-direction: column;
        .table-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            height: 50px;
            padding-top: 10px;
            border-top: 2px solid rgb(202, 202, 202);
            border-left: 2px solid rgb(202, 202, 202);
            border-right: 2px solid rgb(202, 202, 202);
            padding-left: 10px;
            padding-right: 10px;
            label {
                font-weight: bold;
            }
            .pullrequest {
                width: 350px;
                text-align: left;
            }
            .reviewer {
                width: 180px;
                text-align: center;                
            }
            .trt {
                width: 180px;
                text-align: center;
            }
        }
        .table-rows {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border: 2px solid rgb(202, 202, 202);
            height: 50px;
            padding-top: 10px;
            padding-left: 10px;
            padding-right: 10px;
            .pullrequest {
                width: 350px;
                text-align: left;
            }
            .reviewer {
                width: 180px;
                text-align: center;                                
            }
            .trt {
                width: 180px;
                text-align: center;                                
            }
            
        }
    }
}
.disabled-container{
    font-size: 50px;
    margin-top: 80px;
    // margin: auto;
    margin-left: 340px;
    color: white;
    font-weight: bold;
    letter-spacing: 3px;
    display: flex;
    flex-direction: column;
    height: 100px;
    justify-content: space-between;
}
</style>