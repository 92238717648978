<template>
    <div>
    <div class="section-insights">
        <div class="container">
            <!-- <div v-if="hasPrs"> -->
                <div class="box">
                    <!-- <div class="boxHeader">
                        <div class="headerContent">
                            <h3>{{ total_prs }} Open PR(s)</h3>
                            <form class="filters">
                                 <md-field>
                                    <md-select v-model="sort" @md-selected="applySort($event)" class="set">
                                        <md-option value="age-old" >Oldest</md-option>
                                        <md-option value="age-new">Newest</md-option>
                                        <md-option value="size-large">Largest</md-option>
                                    </md-select>
                                </md-field>
                                <md-field>
                                    <md-select v-model="repos" placeholder="All Repos" @md-selected="repoValueChanged()" class="set" multiple>                          
                                        <md-option v-for="repo in org_repos" :value="repo.id" :key="repo.id">{{repo.name}}</md-option>
                                    </md-select>
                                </md-field>
                                <md-field>
                                    <md-select v-model="teams" placeholder="All Teams" @md-selected="teamValueChanged()" class="set" multiple>                          
                                        <md-option v-for="team in github_teams" :value="team.team_id" :key="team.team_id">{{team.team_name}}</md-option>
                                    </md-select>
                                </md-field>
                                <md-field>
                                    <md-select v-model="authors" placeholder="All Authors" @md-selected="authorValueChanged()" class="set" multiple>                          
                                        <md-option v-for="auth in authors_opts" :value="auth" :key="auth">{{auth}}</md-option>
                                    </md-select>
                                </md-field>
                                <md-field>
                                    <md-select v-model="time" @md-selected="filterByTime()" class="set">
                                        <md-option value="all">All</md-option>
                                        <md-option value="14">14d</md-option>
                                        <md-option value="30">1 month</md-option>
                                    </md-select>
                                </md-field>
                                
                                
                            </form>
                            <md-button @click="applyFilters()" class="md-dense md-raised md-primary">Apply</md-button>
                            <md-button @click="clearAllFilters()" class="md-dense md-primary">Clear</md-button>
                        </div>
                    </div> -->
                    <div class="flex-table prs">
                        <div class="row-header">
                            <div class="col-left">
                                <span>Pull request</span>
                            </div>
                            <div class="col-right">
                                <span>Size</span>
                            </div>
                            <div class="col-right">
                                <span>Approvals</span>
                            </div>
                        </div>
                        <!-- <div class="row-body" v-for="pr in prList" :key="pr.pr_id">
                            <div class="col-left">
                                <div class="pr">
                                    <div class="avatar">
                                        <img :title="pr.payload.user.login" width="100%" :src="pr.payload.user.avatar_url">
                                    </div>
                                    <div class="pr-info">
                                        <div class="pr-title">
                                            <span class="repo">[{{ pr.payload.head.repo.name }}#{{ pr.payload.number }}]</span>
                                            <a target="_blank" :href="pr.payload.html_url" rel="noopener noreferrer">{{ pr.payload.title }}</a>
                                        </div> 
                                        <div class="pr-metadata">
                                           <span> {{pr.age}} days old </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-right">
                                <span class="text-green"><strong>+{{ pr.payload.additions }}</strong></span>
                                <span class="text-red"><strong>-{{ pr.payload.deletions }}</strong></span>
                            </div>
                            <div class="col-right approval">
                                <!-- <img width="14" height="14" title="0 approvals" src="https://d23pdqum5wojjd.cloudfront.net/assets/app/thumbsup-aa51e0195bfeb4c5320a5a1503233be5ed5e46cadad93e415c282548f36df799.svg"> -->
                                <i class="material-icons thumbs-up">thumb_up</i>
                                <span>0</span>
                            </div>
                        </div> -->
                    </div>
                </div>
            <!-- </div> -->
            <!-- <div v-else> -->
                <div class="box">
                    <label>THERE ARE NO OPEN PULL REQUESTS</label>
                </div>
            <!-- </div> -->
        <div class="lds-ring" v-if="loading"><div></div><div></div><div></div><div></div></div>
        </div>
    </div> 
    </div>     

</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>

</style>