<template>
    <div class="container">
        <div class="header">
            <div class="page-header">
                #{{channelName}}
            </div>
            <div class="bottom-header">
                <button v-on:click="changeIntended()">Change</button>
                <button v-on:click="disableChannel()">Disable</button>
                <div v-if="changeClicked">
                    <b-select placeholder="Select channel" expanded v-model="change_channel" >
                        <option v-for="channel in availableChannels" :value="channel" :key="channel.id">{{channel.name}}</option>
                    </b-select>
                    <button v-on:click="changeChannel()"> Save </button>
                    <button v-on:click="hideChange()"> Cancel </button>
                </div>
            </div>
        </div>
        <hr class="hr" />
        <div class="settings-body">
            <div class="container-one">
                <label class="header-one">Reminder Schedule</label>

                <b-tabs type="is-toggle" size="is-small" class="block" v-model="form.has_review_request" v-on:change="changeTab($event)">
                    <b-tab-item label="Has Review Request"></b-tab-item>
                    <b-tab-item label="Not"></b-tab-item>
                </b-tabs>
            </div>
            <div class="group-container">
                <div class="inside-container">
                    <label>Time</label>
                    <b-dropdown v-model="form.time" multiple aria-role="list">
                        <button class="button" type="button" slot="trigger">
                            <span>Selected ({{ form.time.length }})</span>
                            <b-icon icon="menu-down"></b-icon>
                        </button>

                        <b-dropdown-item v-for="time in timeList"  :value="time.value" :key="time.value" aria-role="listitem">
                            <span>{{time.label}}</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
                <div class="inside-container">
                    <label>Days</label>
                    <div class="outer-box">
                        <div class="cover-box">
                            <b-checkbox v-model="form.days" native-value=1>Mon</b-checkbox>
                        </div>
                        <div class="cover-box">
                            <b-checkbox v-model="form.days" native-value=2>Tue</b-checkbox>
                        </div>
                        <div class="cover-box">
                            <b-checkbox v-model="form.days" native-value=3>Wed</b-checkbox>
                        </div>
                        <div class="cover-box">
                            <b-checkbox v-model="form.days" native-value=4>Thu</b-checkbox>
                        </div>
                        <div class="cover-box">
                            <b-checkbox v-model="form.days" native-value=5>Fri</b-checkbox>
                        </div>
                        <div class="cover-box">
                            <b-checkbox v-model="form.days" native-value=6>Sat</b-checkbox>
                        </div>
                        <div class="cover-box">
                            <b-checkbox v-model="form.days" native-value=7>Sun</b-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-one">
                <label class="header-one">Filters</label>
            </div>   
            <div class="group-container">
                <div class="inside-container">
                    <label>Remind Author After Reviews</label>
                    <div class="outer-box">
                        <div class="cover-box-2">
                            <b-radio v-model="form.remind_author_after_reviews" native-value="all">All Reviews</b-radio>
                        </div>
                        <div class="cover-box-2">
                            <b-radio v-model="form.remind_author_after_reviews" native-value="one">1 Review</b-radio>
                        </div>
                        <div class="cover-box-2">
                            <b-radio v-model="form.remind_author_after_reviews" native-value="two">2 Reviews</b-radio>
                        </div>
                        <div class="cover-box-2">
                            <b-radio v-model="form.remind_author_after_reviews" native-value="three">3 Reviews</b-radio>
                        </div>
                    </div>
                </div>
                <div class="inside-container">
                    <label>Get PRs of specific Github team</label>
                    
                    <md-field>
                        <md-select style="background-color: white;" v-model="abc" placeholder="All Teams" multiple>                          
                            <md-option v-for="team in github_teams" :value="team.team_id" :key="team.team_id">{{team.team_name}}</md-option>
                        </md-select>
                    </md-field>
                    
                </div>
                <div class="inside-container">
                    <label>Repositories</label>
                    
                    <md-field>
                        <md-select style="background-color: white;" v-model="bcf" placeholder="All Repositories" multiple>
                            <md-option v-for="repo in org_repos" :value="repo.id" :key="repo.id">{{repo.name}}</md-option>
                        </md-select>
                    </md-field>
                </div>
                <div class="inside-container">
                    <label>Min. Age</label>
                    <b-numberinput v-model="form.minAge"></b-numberinput>
                </div>
                <div class="inside-container">
                    <label>Min. Staleness</label>
                    <b-numberinput v-model="form.minStale"></b-numberinput>
                </div>
                <div class="inside-container">
                    <label>Real-time Notifications</label>
                    <b-checkbox v-model="form.openRT" native-value="opened">PR Opened</b-checkbox>
                    <b-checkbox v-model="form.closeRT" native-value="closed">PR Closed</b-checkbox>
                </div>
            </div>
            <b-button @click="updateSettings()" class="save-btn">Save Settings</b-button>
        </div>
    </div>
</template>
<script>
import Buefy from 'buefy';
import Vue from 'vue';
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css';
import { mapState, mapActions } from 'vuex';
import axios from 'axios';

Vue.use(VueMaterial)
export default {
    name: 'TeamSettings',
    beforeCreate() {
        Vue.use(Buefy)
    },
    computed: {
        ...mapState([
            'teamSetting',
            'timeList'
        ])
    },
    data() {
        let availableChannels = [];
        let installedChannelList = [];
        let channelList = [];
        let changeClicked = false;
        let abc = [];
        let bcf = [];
        return {
            isPublic: true,
            channelName:"",
            change_channel:"",
            availableChannels,
            channelList,
            installedChannelList,
            changeClicked,
            github_teams: [],
            org_repos: [],
            abc,
            bcf,
            form: {
                has_review_request: 0,
                time: [],
                days: [],
                remind_author_after_reviews: "",
                team: [],
                repos: [],
                minAge: 0,
                minStale: 0,
                openRT: "",
                closeRT: "",
            },
        }
    },
    created() {
        this.fetchRepoAndTeams();
        // // console.log(this.form.team)
        // console.log(this.teamSetting);
        if(this.teamSetting){
            this.form.minAge = this.teamSetting.team_settings.minAge;
            this.form.minStale = this.teamSetting.team_settings.minStale;
            this.form.time = this.teamSetting.team_settings.time;
            this.form.days = this.teamSetting.team_settings.days;
            this.form.has_review_request = this.teamSetting.team_settings.has_review_request;
            this.form.remind_author_after_reviews = this.teamSetting.team_settings.remind_author_after_reviews;
            this.form.team = this.teamSetting.team_settings.team;
            this.form.repos = this.teamSetting.team_settings.repos;
            this.form.openRT = this.teamSetting.team_settings.openRT;
            this.form.closeRT = this.teamSetting.team_settings.closeRT;
            this.channelName = this.teamSetting.name;
            
            if(this.teamSetting.team_settings.team == "all"){
                this.abc = [];
            }
            if(this.teamSetting.team_settings.repos){
                this.bcf = [];
            }
        }
        else {
            //Call backend for data
            // console.log('backend call')
            axios.get(`${process.env.VUE_APP_BASEURL}/get_channel`, {
                params: {
                    channel_id: this.$route.params.channel_id
                }
            }).then((response) => {
                this.form = response.data.channel[0].team_settings
                // console.log(this.form);
                this.channelName = response.data.channel[0].name
            }).catch((error) => {
                // console.log(error)
            })
        }
    },
    methods: {
        updateSettings: function() {
            // console.log(this.form);
            // this.form.team = this.abc;
            // this.form.repos = this.bcf;
            if(this.abc.length == 0){
                this.form.team = "all";
            } else {
                this.form.team = this.abc;
            }
            if(this.bcf.length == 0){
                this.form.repos = "all";
            } else{
                this.form.repos = this.bcf;
            }

            axios.post(`${process.env.VUE_APP_BASEURL}/slack/updateChannelSettings`, {
                settings: this.form,
                channel_id: this.$route.params.channel_id
            }).then((response) => {
                // console.log(response.data.team_settings);
                // response.data.team_settings
                this.setSettings(response.data);
            })
            // // console.log(this.abc)
        },
        changeTab: function($event) {
            // console.log(this.form.has_review_request)
        },
        disableChannel(){
            // console.log('disable channel');
            // let channel_name = this.channelName;
            axios.post(`${process.env.VUE_APP_BASEURL}/slack/disable_channel`, {
                channel_id: this.$route.params.channel_id
            }).then((response) => {
                // console.log(response.data);

                this.$router.replace({ name: 'notification-rules', params: {id: this.$route.params.id}})
            })
        },
        changeIntended(){

             axios.get(`${process.env.VUE_APP_BASEURL}/get_channels`, {
                    params:{
                        org_id: this.$route.params.id
                    }
                }).then((response) => {
                    if(response.data.channels.length != 0){
                        this.hasChannel = true;
                        this.installedChannelList = response.data.channels


                        const accessToken = localStorage.getItem("slack_token");
                        axios.post(`${process.env.VUE_APP_BASEURL}/slack/all_channels`, {
                            token: accessToken,
                        }).then((response_two) => {
                            this.channelList = response_two.data;
                            // console.log(this.channelList)
                            // console.log(this.installedChannelList)
                            let installedChannelIds = this.installedChannelList.map((arr) => {
                                return arr.channel_id;
                            })

                            // console.log(installedChannelIds);
                            
                            this.channelList.forEach((arr) => {
                                if(!installedChannelIds.includes(arr.id)){
                                    // console.log(arr.id)
                                    this.availableChannels.push(arr)
                                }
                            })
                        // console.log(this.availableChannels)

                        }).catch((error) => {
                            // console.log(error);
                        });
                    } else {
                        this.hasChannel = false;
                    }
                    // console.log(response.data)
                })

            this.changeClicked = true;
        },
        changeChannel(){
            // fetch channel details
            let channel_id = this.$route.params.channel_id
            let selected_id = this.change_channel.id;
            // console.log(this.change_channel);
            axios.post(`${process.env.VUE_APP_BASEURL}/slack/changeChannel`, {
                channel_id: channel_id,
                selected_id: selected_id,
                settings: this.form
            }).then((response) => {
                // console.log(response.data);

                this.channelName = response.data.name;
                this.form = response.data.team_settings;
                this.setSettings(response.data);
                this.changeClicked = false;
            })
            // send update and disable request for corresponding channels
        },
        hideChange(){
            this.changeClicked = false;
        },
        fetchRepoAndTeams(){
            axios.get(`${process.env.VUE_APP_BASEURL}/get_teams`, {
                params: {
                    org_id: this.$route.params.id
                }
            }).then((response) => {
                // console.log('teams')
                // console.log(response.data)
                this.github_teams = response.data;
                
            })

            axios.get(`${process.env.VUE_APP_BASEURL}/get_org_repos`, {
                params: {
                    org_id: this.$route.params.id
                }
            }).then((response) => {
                // console.log('repos')
                // console.log(response.data)
                this.org_repos = response.data;
            })
        },
        ...mapActions(['setSettings'])
    },
}
</script>
<style lang="scss" scoped>
.container {
    width: 80%;
    .hr {
        height: 1px;
        background-color: gray;
    }
    .header {
        .page-header {
            font-size: 30px;
            text-align: left;
            margin-bottom: 20px;
            margin-top: 40px;
            color: #190845;
            font-weight: bold;
        }
        .bottom-header {
            text-align: left;
            button {
                font-family: 'Raleway';
                margin: 10px;
                color: #190845;
                background: none;
                border: #190845 0.5px solid;
                border-radius: 2px;
            }
        }
    }
    .settings-body {
        color: #190845;
            .container-one {
                display: flex;
                flex-direction: row;
                height: 30px;
                justify-content: space-between;
                .header-one {
                    font-size: 22px;
                    font-weight: 600;
                }
            }
            .group-container {
                .inside-container {
                    display: flex;
                    flex-direction: row;
                    margin-left: 150px;
                    justify-content: space-between;
                    margin-top: 10px;
                    .outer-box {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        .cover-box {
                            width: 110px;
                            height: 40px;
                            border: 0.5px solid #b8b5b5;
                            padding: 10px;
                        }
                        .cover-box-2 {
                            width: 140px;
                            height: 43px;
                            border: 0.5px solid #b8b5b5;
                            padding: 10px;
                        }
                    }
                    .dropdown-content{
                        height: 80px;
                        overflow: scroll;
                    }
                }
                
            }
        .save-btn {
            margin-top: 50px;
            transition: background-color 0.1s linear;
        }
        .save-btn:hover{
            background-color: #190845;
            color: white;
            transition: background-color 0.1s linear;
        }
    }
}
</style>