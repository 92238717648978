<template>
    
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
    name: 'auth',
    data(){
        let url = `${process.env.VUE_APP_BASEURL}/auth/github?redirect_uri=${window.location}`
        return {
            url
        }
    },
    computed:{
        ...mapState([
            'gotInstallation',
            'installations'
        ])
    },
    methods:{
        ...mapActions([
            'getInstallations',
            'doLogin'
        ]),
        login(token){
            this.doLogin(token)
        }
    },
    created(){
        // console.log(window.location);

        
        if(window.location.search.includes('token')){
            const token = window.location.search.split("=")[1]
            this.login(token);
            this.getInstallations(token).then(() => {
                if(this.gotInstallation){
                    let org_id = this.installations[0].account.id;
                    this.$router.push({name: 'review-metrics', params: {id: org_id}})
                }
                else{
                    this.$router.push({name: 'integrations'})
                }
            })
        }
        else {
            if(localStorage.getItem('accessToken')){
                let accessToken = localStorage.getItem('accessToken');
                this.getInstallations(accessToken).then(() => {
                    if(this.gotInstallation){
                        let org_id = this.installations[0].account.id;
                        localStorage.setItem('userOrg', org_id);
                        this.$router.push({name: 'review-metrics', params: {id: org_id}})
                    }
                    else{
                        this.$router.push({name: 'integrations'})
                    }
                })
            } else {
                // send for login request
                window.location = this.url;
            }
        }

        
    }
}
</script>