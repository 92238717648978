<template>
    <div class="whole">
        <div class="page-header">
            <label>PR Overview</label>
        </div>
        <hr />
        <div class="filters-row">
            <template>
                <fish-select search multiple v-model="selected_teams" style="width: 150px; height: auto;" hint="Teams">
                    <fish-option v-for="(team, index) in team_options" :key="index" :index="team.team_id" :content="team.team_name"></fish-option>
                </fish-select>
            </template>
            <template>
                <fish-select search multiple v-model="selected_repos" style="width: 150px; height: auto;" hint="Repos">
                    <fish-option v-for="(repo, index) in repo_options" :key="index" :index="repo.id" :content="repo.name"></fish-option>
                </fish-select>
            </template>
            <template>
                <fish-select v-model="time_period" style="width: 150px; height: auto;" hint="Time Period">
                    <fish-option index="7d" content="7 Days"></fish-option>
                    <fish-option index="14d" content="14 Days"></fish-option>
                    <fish-option index="30d" content="1 Month"></fish-option>
                </fish-select>
            </template>
            <!-- <template>
                <fish-select search multiple v-model="selected_repos" style="width: 150px; height: auto;" hint="Weekends">
                    <fish-option v-for="(repo, index) in repo_options" :key="index" :index="repo.name" :content="repo.name"></fish-option>
                </fish-select>
            </template> -->
            <template>
                <fish-button @click="applyFilters()">Apply</fish-button>
            </template>
        </div>
        <div class="outer-box">
            <div class="graph-column">
                <!-- <AreaBarChart v-if="dataLoaded" :graphData="graphData" /> -->
                <BarAreaChart v-if="dataLoaded" :chartData="graphData" :location="'openprs'"/>
            </div>
            <div class="line">
                <hr />
            </div>
            <div v-if="metricsData" class="stats-column">
                <div class="stat">
                    <div class="top-label">{{metricsData.current_open_prs}}</div>
                    <div class="bottom-label">Current Open PRs</div>
                </div>
                <div class="stat colored">
                    <div class="top-label">{{metricsData.current_loc_for_review}}</div>
                    <div class="bottom-label">Current LOC for Review</div>
                </div>
            </div>
        </div>
        <div class="open-prs">
            <div class="sub-header">
                <div class="label">
                    <label>Open PRs</label>    
                </div>      
            </div>
            <div class="pr-list" v-for="(pr, index) in prList" :key='index'>
                <PRCard :pr="pr" />
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.whole{
    margin-top: 40px;
    height: 100%;
    width: 98%;
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .page-header{
        display: flex;
        flex-direction: row;
        width: inherit;
        justify-content: space-between;
        align-items: center;
        label{
            font-size: 50px;
            font-weight: bold;
            height: auto;
            color: #190845;
        }
    }
    .filters-row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: inherit;
        align-items: baseline;
        margin-bottom: 20px;
    }
    .outer-box{
        display: flex;
        flex-direction: row;
        border: 3px solid #e5e5e5;
        width: inherit;
        height: 45vh;
        z-index: 1;
        justify-content: space-between;
        // position: absolute;
        padding: 0 10px 0 10px;
        .graph-column{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: inherit;
            height: 75%;
        }
        .line{
            width: 5%;
            hr {
                    width: 2px;
                    height: 38vh;
                    background-color: #e5e5e5;
            }
        }
        .stats-column{
            display: flex;
            flex-direction: column;
            height: 99%;
            width: 20%;
            justify-content: space-around;
            margin-right: 2vh;
            align-items: center;
            .stat{
                display: flex;
                flex-direction: column;
                height: 20%;
                width: 100%;
                justify-content: flex-end;
                align-items: center;
                background: #8753DE;
                color: white;
                .top-label{
                    font-size: 40px;
                    font-weight: bold;
                    line-height: 20px;
                    font-family: Overpass;
                }
                .bottom-label{
                    font-size: 14px;
                    font-weight: normal;
                    line-height: 28px;
                    font-family: Overpass;
                }
            }
            .colored{
                background: #AAD4C8;
            }
        }
    }
    .open-prs{
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        width: inherit;
        align-items: flex-start;
        .sub-header{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: inherit;
            .label{
                font-family: Overpass;
                font-style: normal;
                font-weight: bold;
                font-size: 40px;
                line-height: 61px;
                color: #8753DE;
            }
        }
        .pr-list{
            display: flex;
            flex-direction: column;
            height: auto;
            width: inherit;
            margin-bottom: 10px;
        }
    }
}
</style>
<script>
import axios from 'axios';
import AreaBarChart from '@/components/AreaBarChart';
import BarAreaChart from '@/components/BarAreaChart.vue';
import PRService from '../services/prService';
import PRCard from '@/components/PRCard';
export default {
    name: 'openprs',
    components:{
        AreaBarChart, PRCard, BarAreaChart
    },
    data(){
        let team_options = [], repo_options = [], selected_repos = [], selected_teams, time_period, dataLoaded = false, graphData = [], metricsData, prList = [];
        return {
            team_options, repo_options, selected_repos, selected_teams, time_period, dataLoaded, graphData, metricsData,
            filters: {
                teams: [],
                authors: [],
                repos: []
            }, prList
        }
    },
    methods:{
        getTeams(){
            axios.get(`${process.env.VUE_APP_BASEURL}/get_teams`,{
                params:{
                    org_id: this.$route.params.id
                }
            }).then((response) => {
                this.team_options = response.data
            })
        },
        getRepos(){
            axios.get(`${process.env.VUE_APP_BASEURL}/get_org_repos`,{
                params:{
                    org_id: this.$route.params.id
                }
            }).then((response) => {
                //   // console.log(response.data)
                this.repo_options = response.data
            })
        },
        async getOpenPrsGraph(){
            return await PRService.getDummyOpenPrs();
        },
        getGraphData(){
            this.getOpenPrsGraph().then((response) => {
                console.log(response.data);
                this.graphData = response.data.graph;
                this.metricsData = response.data.metrics;
            })
        },
        applyFilters(){
            console.log(this.selected_repos);
            console.log(this.selected_teams);
            this.filters.repos = this.selected_repos;
            this.filters.teams = this.selected_teams;

            if(this.filters.repos.length > 0 || this.filters.teams.length > 0 || this.filters.authors.length > 0){
                axios.post(`${process.env.VUE_APP_BASEURL}/filterPrs`,{
                    filters: this.filters,
                    org_id: this.$route.params.id,
                    offset: 0      
                }).then((response) => {
                    this.loading = false;
                    console.log(response.data.prs)
                    this.prList = response.data.prs;
                    // this.prList = _.union(this.prList, response.data.prs)
                    // if (response.data.prs.length > 0) this.applySort();
                    // this.prs_no_filters = this.prList;
                })
            }
        },
        initialPRFetch(){
            axios.get(`${process.env.VUE_APP_BASEURL}/get_repos`,{
                params: {
                    org_id: this.$route.params.id,
                    offset: 0
                }
            }).then((response) => {
                // console.log(response.data)
                if(response.data.error_message2){
                
                    this.orgError = true;
                    this.$router.push({name: 'install-again'}).catch(err => {})
                    //NO ORGANISATION FOUND DISPLAY ERROR
                    // possible redirect to an errors page asking to install application again
                } else if(response.data.prs) {
                    // this.hasPrs = true;
                    this.prList = response.data.prs;
                    // this.prs_no_filters = response.data.prs;
                    // this.total_prs = response.data.count;
                    // this.applySort();

                    // this.filterByTime();
                } else if(response.data.total_prs == 0) {
                    //NO PRS MESSAGE DISPLAY
                    this.hasPrs = false;
                }
                
            });
        }
    },
    created(){
        this.getTeams();
        this.getRepos();
        this.getGraphData();
        this.initialPRFetch();
    },
    watch:{
        graphData: function(){
            console.log('watching graph data');
            console.log(this.graphData);
            if(this.graphData.length > 0){
                this.dataLoaded = true;
            }
        },
    }
}
</script>