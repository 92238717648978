<template>
    <!-- <div> -->
        <!-- <div v-if="isAccountsPage">
            <Header />
        </div>
        <div v-else>
            <InstallsHeader />
        </div>
        <router-view />
        <div v-if="gotInstallation">
            <h3>CHOOSE ACCOUNT</h3>
            <OrganisationList v-for="item in orgData" :key="item.account.id" v-bind:item="item" v-bind:link="item.account.id"/>
        </div> -->
        <router-view />

    <!-- </div> -->
</template>

<script>
import InstallApp from '@/components/InstallApp.vue';
import OrganisationList from '@/components/OrganisationList.vue';
import InstallsHeader from '@/components/InstallsHeader.vue';
import Header from '@/components/Header.vue';
import { mapState, mapActions } from 'vuex';

export default {
    name: 'install',
    components: {
        InstallApp,
        OrganisationList,
        InstallsHeader,
        Header
    },
    computed: {
        ...mapState([
            'gotInstallation',
            'installations',
            'isAccountsPage'
        ])
    },
    methods: {
        ...mapActions([
            'getInstallations'
        ])
    },
    created(){
        if(this.$route.name == 'install'){
            this.$router.push({name: 'install-home'})
        }
    }
};
</script>
