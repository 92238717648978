<template>
    <div>
        <div ref="tinyBarLine"></div>
    </div>
</template>
<style lang="scss" scoped>

</style>
<script>
import Plotly from 'plotly.js/dist/plotly';
export default {
    name: 'tinyGraph',
    props:{
        graphData:{
            required: false
        }
    },
    data(){
        var lessthan8 = {
        x: [0, 1, 2, 3, 4, 5],
        y: [1.3, 1, 1.3, 0.7, 0.8, 0.9],
        type: 'bar',
        name: '<8 hrs',
        marker: {color: '#1B1D45'},
        };

        var lessthan24 = {
        x: [0, 1, 2, 3, 4, 5],
        y: [1, 0.5, 0.7, 1.2, 0.3, 0.4],
        type: 'bar',
        name: '<24hrs',
        marker: {color: '#F95A63'},
        };

        var lessthan48 = {
        x: [0, 1, 2, 3, 4, 5],
        y: [1, 0.5, 0.7, 1.2, 0.3, 0.4],
        type: 'bar',
        name: '<48hrs',
        marker: {color: '#AAD4C8'},
        };

        var morethan48 = {
        x: [0, 1, 2, 3, 4, 5],
        y: [1, 0.5, 0.7, 1.2, 0.3, 0.4],
        type: 'bar',
        name: '48+hrs',
        marker: {color: '#8A6EDD'},
        };


        let layout = {
            showlegend: false,
            margin: {
                l: 0,
                r: 0,
                b: 0,
                t: 0,
                pad: 0
            },
            height: 56,
            width:122,
            font: {family: 'Overpass'},
            legend: {orientation: "h"},
            bargroup: 'group',
            bargap: 0.3,
            bargroupgap: 0.1,
            xaxis:{
                visible: false
            },
            yaxis:{
                visible: false
            }
        };
        let data = [lessthan8, lessthan24, lessthan48, morethan48];
        return{
            data,
            layout
        }
    },
    mounted(){
        console.log(Object.values(this.graphData));
        this.prepareGraph();
        // this.prepareTinyGraph();
    },
    methods:{
        prepareGraph(){
            let finalData = this.sortData(Object.values(this.graphData)[0]);
            console.log(finalData)
            
            let lineGraph = this.$refs.tinyBarLine;
            Plotly.newPlot(lineGraph, Object.values(finalData), this.layout, {displayModeBar: false, responsive: true, staticPlot: true});
        },
        sortData(data){
            // console.log(data);
            let months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

            var lessthan8 = {
                x: [],
                y: [],
                type: 'bar',
                name: '<8 hrs',
                marker: {color: '#1B1D45'},
            };

            var lessthan24 = {
                x: [],
                y: [],
                type: 'bar',
                name: '<24hrs',
                marker: {color: '#F95A63'},
            };

            var lessthan48 = {
                x: [],
                y: [],
                type: 'bar',
                name: '<48hrs',
                marker: {color: '#AAD4C8'},
            };

            var morethan48 = {
                x: [],
                y: [],
                type: 'bar',
                name: '48+hrs',
                marker: {color: '#8A6EDD'},
            };

            
            data.forEach((elem) => {
                console.log(elem);
                let lt8 = 0, lt24 = 0, lt48 = 0, gt48 = 0;
                let d =  new Date(elem.date);
                let month = months[d.getMonth()];
                let date = d.getDate();
                lessthan8.x.push(`${date}-${month}`);
                lessthan24.x.push(`${date}-${month}`);
                lessthan48.x.push(`${date}-${month}`);
                morethan48.x.push(`${date}-${month}`);
                elem.rtt.forEach((rtt) => {
                    if(rtt < 8){  
                        lt8++;                      
                    } else if(rtt > 8 && rtt < 24){
                        lt24++;
                        
                    } else if(rtt > 24 && rtt < 48){
                        lt48++;                        
                    } else {
                        gt48++;
                    }
                })
                lessthan8.y.push(lt8);
                lessthan24.y.push(lt24);
                lessthan48.y.push(lt48);
                morethan48.y.push(gt48);
            })
            

            return {lessthan8: lessthan8, lessthan24: lessthan24, lessthan48: lessthan48, morethan48: morethan48};

        },
    }
}
</script>