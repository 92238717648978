<template>
    <div id="graph-container">
        <canvas ref="chart"></canvas>
    </div>
</template>
<style lang="scss" scoped>

</style>
<script>
import { Chart, DoughnutController } from "chart.js";
import { RadialLinearScale, Title } from 'chart.js';
import { Point } from 'chart.js';
import { Line, Arc } from 'chart.js';
import { Legend } from 'chart.js';
Chart.register(DoughnutController, RadialLinearScale, Point, Line, Arc, Legend, Title)
export default {
    name: 'DoughnutChart',
    props: {
        chartData: {
            type: Object
        },
        size: {
            type: String
        }
    },
    mounted() {
        // let values = this.sortData(this.chartData)
        var chart = this.$refs.chart;
        var ctx = chart.getContext("2d");
        let showLegend;
        if(this.size == 'small'){
            showLegend = false;
        } else {
            showLegend = true;
        }
        var myChart = new Chart(ctx, {
            type: 'doughnut',
            data: {
                    labels: ["Churn Code", "New Code"],
                    datasets: [{
                        label: 'Code Churn',
                        data: [12,19],
                        backgroundColor: [
                            '#FF5B64',
                            '#AAD4C8',
                        ],
                        borderColor: [
                            '#FF5B64',
                            '#AAD4C8',
                        ],
                        borderWidth: 1
                    }]
                },
                options: {
                    title: {
                        display: showLegend,
                        text: 'Code Focus',
                        fontSize: 25,
                        fontStyle: 'bold'
                    },
                    legend:{
                        display: showLegend,
                        position:'bottom',
                        fullWidth: 'false',
                        labels:{
                            boxWidth: 12,
                            fontSize: 14,
                            fontFamily: 'Overpass'
                        }
                    }
                }
        });
    },
    methods:{
        sortData(data){
            let values = [];
            values[0] = data.churn_code;
            values[1] = data.new_code;

            return values;
        }
    }
}
</script>