<template>
    <div class="container">
        <div class="header">
            <div class="page-header">
                Personal Settings
            </div>
        </div>
        <hr class="hr"/>
        <br/>
        <div class="settings-body">
            <div class="container-one">
                <label class="header-one">Reminder Schedule</label>
                <b-tabs type="is-toggle" size="is-small" class="block" v-model="form.ignore_prs" v-on:change="changeTab($event)">
                    <b-tab-item label="Ignore PRs with all approvals"></b-tab-item>
                    <b-tab-item label="Ignore PRs with 1 approval"></b-tab-item>
                </b-tabs>
            </div>
            <div class="group-container">
                <div class="inside-container">
                    <label>Time</label>
                    <b-dropdown v-model="form.time" multiple aria-role="list">
                        <button class="button" type="button" slot="trigger">
                            <span>Selected ({{ form.time.length }})</span>
                            <b-icon icon="menu-down"></b-icon>
                        </button>

                        <b-dropdown-item v-for="time in timeList" :value="time.value" :key="time.value" aria-role="listitem">
                            <span>{{time.label}}</span>
                        </b-dropdown-item>

                        <!-- <b-dropdown-item value="09:00" aria-role="listitem">
                            <span>9:00 am</span>
                        </b-dropdown-item>

                        <b-dropdown-item value="14:48" aria-role="listitem">
                            <span>07:00 am</span>
                        </b-dropdown-item> -->
                    </b-dropdown>
                </div>
                <div class="inside-container">
                    <label>Days</label>
                    <div class="outer-box">
                        <div class="cover-box">
                            <b-checkbox v-model="form.days" native-value=1>Mon</b-checkbox>
                        </div>
                        <div class="cover-box">
                            <b-checkbox v-model="form.days" native-value=2>Tue</b-checkbox>
                        </div>
                        <div class="cover-box">
                            <b-checkbox v-model="form.days" native-value=3>Wed</b-checkbox>
                        </div>
                        <div class="cover-box">
                            <b-checkbox v-model="form.days" native-value=4>Thu</b-checkbox>
                        </div>
                        <div class="cover-box">
                            <b-checkbox v-model="form.days" native-value=5>Fri</b-checkbox>
                        </div>
                        <div class="cover-box">
                            <b-checkbox v-model="form.days" native-value=6>Sat</b-checkbox>
                        </div>
                        <div class="cover-box">
                            <b-checkbox v-model="form.days" native-value=7>Sun</b-checkbox>
                        </div>
                    </div>
                </div>
                <div class="inside-container">
                    <label>Reminders for</label>
                    <div class="check-list">
                        <b-checkbox v-model="form.reminders_for" native-value="to_you">Review requests assigned to you</b-checkbox>
                        <b-checkbox v-model="form.reminders_for" native-value="to_your_team">Review requests assigned to your team</b-checkbox>
                    </div>
                </div>
            </div>
            <div class="container-one">
                <label class="header-one">Real-time Notifications</label>
            </div>   
            <div class="group-container">
                <div class="inside-container">
                    <label>Get Notifications for</label>
                    <div class="check-list">
                        <b-checkbox v-model="form.realTime" native-value="you">You are assigned a review</b-checkbox>
                        <b-checkbox v-model="form.realTime" native-value="team">Your team is assigned a review</b-checkbox>
                        <b-checkbox v-model="form.realTime" native-value="pr_approved">Your PR is approved or has changes requested</b-checkbox>
                        <b-checkbox v-model="form.realTime" native-value="pr_merged">Someone merges your PR</b-checkbox>
                        <b-checkbox v-model="form.realTime" native-value="pr_comment">Someone comments on your PR</b-checkbox>
                        <b-checkbox v-model="form.realTime" native-value="comment_mentioned">You are mentioned in a comment</b-checkbox>
                        <b-checkbox v-model="form.realTime" native-value="pr_merge_conflict">Your PR has merge conflict</b-checkbox>
                    </div>
                </div>
            </div>
            <b-button class="save-btn" @click="submit()">Save Settings</b-button>
        </div>

    </div>
</template>
<script>
import Buefy from 'buefy';
import Vue from 'vue';
import axios from 'axios';
import { mapState } from 'vuex';
export default {
    name: 'PersonalSettings',
    beforeCreate() {
        Vue.use(Buefy)
    },
    data () {
        let number;
        return {
            isPublic: true,
            number,
            form: {
                days: [],
                reminders_for: [],
                realTime: [],
                time: [],
                ignore_prs: 0
            }
        }
    },
    computed:{
        ...mapState([
            'timeList'
        ])
    },
    methods: {
        submit: function() {
            // console.log(this.form.time)

            axios.post(`${process.env.VUE_APP_BASEURL}/slack/updateUserDm`, {
                settings: this.form,
                user_name: JSON.parse(localStorage.getItem("githubUser")).login
            }).then((response) => {
                // console.log(response.data)
                this.form = response.data.user_dm_settings
            })

        },
        changeTab: function($event) {
            // console.log(this.form.ignore_prs)
        }
    },
    beforeMount() {
        //get values from database
        if(localStorage.getItem("githubUser")) {
            let user = JSON.parse(localStorage.getItem("githubUser"))
            axios.get(`${process.env.VUE_APP_BASEURL}/get_slack_user`, {
                params: {
                    user_name: user.login
                }
            }).then((response) => {
                this.form = response.data.user[0].user_dm_settings
            }).catch((error) => {
                // console.log(error);
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.container {
    width: 80%;
    .hr {
        height: 1px;
        background-color: gray;
    }
    .header {
        .page-header {
            font-size: 30px;
            text-align: left;
            margin-bottom: 20px;
            margin-top: 40px;
            color: #190845;
            font-weight: bold;
        }
        .bottom-header {
            
            button {
                margin: 20px;
                color: blue;
                background: none;
                border: none;
            }
        }
    }
    .settings-body {
        color: #190845;
            .container-one {
                display: flex;
                flex-direction: row;
                height: 30px;
                justify-content: space-between;
                .header-one {
                    font-size: 22px;
                    font-weight: 600;
                }

            }
            .group-container {
                .inside-container {
                    display: flex;
                    flex-direction: row;
                    margin-left: 150px;
                    justify-content: space-between;
                    margin-top: 10px;
                    align-items: baseline;
                    .check-list {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        // margin: 0px;
                    }
                    .outer-box {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        .cover-box {
                            width: 110px;
                            height: 40px;
                            border: 0.5px solid #b8b5b5;
                            padding: 10px;
                        }
                        .cover-box-2 {
                            width: 140px;
                            height: 43px;
                            border: 0.5px solid #b8b5b5;
                            padding: 10px;
                        }
                    }
                }
            }
        .save-btn {
            margin-top: 50px;
            transition: background-color 0.1s linear;
        }
        .save-btn:hover{
            background-color: #190845;
            color: white;
            transition: background-color 0.1s linear;
        }
    }
}
</style>