<template>
    <div class="graph-container">
        <canvas id="bargroup"></canvas>
    </div>
</template>
<style lang="scss" scoped>
.graph-container{
    height: 200px;
width: 700px;
}
</style>
<script>
import { Chart, BarController, LineController, CategoryScale, Rectangle, Title } from "chart.js";
import { LinearScale } from 'chart.js';
import { Point } from 'chart.js';
import { Line } from 'chart.js';
import { Legend } from 'chart.js';
// import { color } from 'chart.js/helpers';
Chart.register(BarController, LineController, LinearScale, Point, Line, Legend, CategoryScale, Rectangle, Title)
export default {
    name: 'barlinechartjs',
    props: {
        chartData: {
            type: Object
        },
    },
    mounted(){
        // let {labels, acc} = this.sortDataTwo(this.chartData);
        console.log(this.chartData)
        let dataset = [this.chartData.lessThan8, this.chartData.lessThan24, this.chartData.lessThan48, this.chartData.moreThan48];
        let ctx = document.getElementById('bargroup').getContext("2d");
        let myChart = new Chart(ctx, {
            data: {
                labels: this.convertLabels(this.chartData.dates),
                datasets: dataset
            },
            options: {
                scales: {
                    x:{
                        gridLines: {
                            color: '#FFFFFF'
                        }
                    },
                    y:{
                        gridLines: {
                            color: '#FFFFFF'
                        }
                    }
                },
                legend:{
                    position: 'top'
                }
            }
        })
    },
    methods:{
        // sortData(data){
        //     let months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

        //     let labels = [];


        //     var lessthan8 = {
        //         data: [],
        //         label: '<8 hrs',
        //         backgroundColor: '#1B1D45'
        //     };

        //     var lessthan24 = {
        //         data: [],
        //         label: '<24hrs',
        //         backgroundColor: '#F95A63'
        //     };

        //     var lessthan48 = {
        //         data: [],
        //         label: '<48hrs',
        //         backgroundColor: '#AAD4C8'
        //     };

        //     var morethan48 = {
        //         data: [],
        //         type: 'bar',
        //         label: '48+hrs',
        //         backgroundColor: '#8A6EDD'
        //     };
            
        //     data.forEach((elem) => {
        //         let lt8 = 0, lt24 = 0, lt48 = 0, gt48 = 0;
        //         let d =  new Date(elem.date);
        //         let month = months[d.getMonth()];
        //         let date = d.getDate();
        //         labels.push(`${date}-${month}`);
        //         elem.rtt.forEach((rtt) => {
        //             if(rtt <= 8){  
        //                 lt8++;                      
        //             } else if(rtt > 8 && rtt <= 24){
        //                 lt24++;
                        
        //             } else if(rtt > 24 && rtt <= 48){
        //                 lt48++;                        
        //             } else {
        //                 gt48++;
        //             }
        //         })
        //         lessthan8.data.push(lt8);
        //         lessthan24.data.push(lt24);
        //         lessthan48.data.push(lt48);
        //         morethan48.data.push(gt48);
        //     })

        //     return {labels, lessthan8, lessthan24, lessthan48, morethan48};
        // },
        // sortDataTwo(data){
        //     let months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

        //     let labels = [];

        //     let acc = data.reduce((accumulator, elem) => {
        //         let d =  new Date(elem.date);
        //         let month = months[d.getMonth()];
        //         let date = d.getDate();
        //         labels.push(`${date}-${month}`);
        //         accumulator.lessthan8 = elem.lessThan8;
        //         accumulator.lessthan24 = elem.lessThan24;
        //         accumulator.lessthan48 = elem.lessThan48;
        //         accumulator.morethan48 = elem.moreThan48;
        //         return accumulator;
        //     }, {})
        //     return {labels, acc}; 
        // },
        convertLabels(labels){

            let months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
            let new_labels = [];
            labels.forEach((elem) => {
                let d =  new Date(elem);
                let month = months[d.getMonth()];
                let date = d.getDate();
                new_labels.push(`${date}-${month}`);
            })

            console.log(new_labels)
            return new_labels;
        }
    }
}
</script>