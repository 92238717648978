<template>
    <div class="whole">
        <div class="page-header">
          <label>PR Overview</label>
          <!-- <SearchBox /> -->
        </div>
        <hr />
        <!-- FILTERS -->
        <div class="filters-row">
            <template>
                <fish-select search multiple v-model="selected_teams" style="width: 150px; height: auto;" hint="Teams">
                    <fish-option v-for="(team, index) in team_options" :key="index" :index="team.team_id" :content="team.team_name"></fish-option>
                </fish-select>
            </template>
            <template>
                <fish-select search multiple v-model="selected_repos" style="width: 150px; height: auto;" hint="Repos">
                    <fish-option v-for="(repo, index) in repo_options" :key="index" :index="repo.id" :content="repo.name"></fish-option>
                </fish-select>
            </template>
            <template>
                <fish-select v-model="time_period" style="width: 150px; height: auto;" hint="Time Period">
                    <fish-option index="7d" content="7 Days"></fish-option>
                    <fish-option index="14d" content="14 Days"></fish-option>
                    <fish-option index="30d" content="1 Month"></fish-option>
                </fish-select>
            </template>
            <!-- <template>
                <fish-select search multiple v-model="selected_repos" style="width: 150px; height: auto;" hint="Weekends">
                    <fish-option v-for="(repo, index) in repo_options" :key="index" :index="repo.name" :content="repo.name"></fish-option>
                </fish-select>
            </template> -->
            <template>
                <fish-button @click="applyFilters()">Apply</fish-button>
            </template>
        </div>
        <div class="outer-box" v-if="noData">
            <div class="graph-column">
                <div class="view-selector">
                   <fish-select v-model="selectedMetric" @change="changeView()">
                        <fish-option index="prt" content="PR Throughput"></fish-option>
                        <fish-option index="rtt" content="PRs with (x) RTT"></fish-option>
                        <fish-option index="prct" content="PR Cycle Time"></fish-option>
                        <fish-option index="mpr" content="Merged PR(s)"></fish-option>
                        <fish-option index="cc" content="Comment Coverage"></fish-option>
                    </fish-select> 
                </div>
                <div class="graph">
                    <NewLineChart v-if="dataLoaded && selectedMetric == 'prt'" :graphData="graphData" :graphType="graphType" :key="componentKey"/>
                    <!-- <BarLineCombo v-if="selectedMetric == 'rtt'"  :graphData="rttData" :key="componentKey"/> -->
                    <BarLineChartJS v-if="selectedMetric == 'rtt' && rttLoaded" :chartData="rttData" :key="componentKeyy" />
                    <StackedBarChart v-if="dataLoadedStacked && selectedMetric == 'prct'" :graphData="cycleData" :key="componentKey4" />
                    <PRBarLineChartJS v-if="selectedMetric == 'mpr'" :chartData="mergeData" :key="componentKey2" />
                    <CommentBarLineChartJS v-if="selectedMetric == 'cc'" :chartData="commentsData" :key="componentKey3" />
                </div>
            </div>
            <template v-if="metricsData && selectedMetric == 'prt'">
                <div class="stats-column">
                    <div class="stat">
                        <div class="top-label">{{metricsData.total}}</div>
                        <div class="bottom-label">Total PRs Opened</div>
                    </div>
                    <div class="stat white">
                        <div class="top-label">{{metricsData.avg_prs}}</div>
                        <div class="bottom-label">Avg. PRs Opened</div>
                    </div>
                    <div class="stat">
                        <div class="top-label">{{metricsData.trend.type}}{{metricsData.trend.trend}}%</div>
                        <div class="bottom-label">Trend</div>
                    </div>
                    <div class="stat white">
                        <div class="top-label">{{metricsData.min}}</div>
                        <div class="bottom-label">Minima</div>
                    </div>
                    <div class="stat">
                        <div class="top-label">{{metricsData.max}}</div>
                        <div class="bottom-label">Maxima</div>
                    </div>
                </div>
            </template>
            <template v-if="rttMetricsData && selectedMetric == 'rtt'">
                <div class="stats-column">
                    <div class="stat">
                        <div class="top-label">{{rttMetricsData.total_lt_8}}</div>
                        <div class="bottom-label">Total PRs Opened</div>
                    </div>
                    <div class="stat white">
                        <div class="top-label">{{rttMetricsData.avg_lt_8}}</div>
                        <div class="bottom-label">Avg. PRs Opened</div>
                    </div>
                    <div class="stat">
                        <div class="top-label">{{rttMetricsData.trend.sign}}{{rttMetricsData.trend.value}}%</div>
                        <div class="bottom-label">Trend</div>
                    </div>
                    <div class="stat white">
                        <div class="top-label">{{rttMetricsData.minima}}</div>
                        <div class="bottom-label">Minima</div>
                    </div>
                    <div class="stat">
                        <div class="top-label">{{rttMetricsData.maxima}}</div>
                        <div class="bottom-label">Maxima</div>
                    </div>
                </div>
            </template>
            <template v-if="cycleMetricsData && selectedMetric == 'prct'">
                <div class="stats-column">
                    <div class="stat">
                        <div class="top-label">{{cycleMetricsData.avg_pr_cycle_time}}</div>
                        <div class="bottom-label">Total PRs Opened</div>
                    </div>
                    <div class="stat white">
                        <div class="top-label">{{cycleMetricsData.avg_merge_time}}</div>
                        <div class="bottom-label">Avg. PRs Opened</div>
                    </div>
                    <div class="stat">
                        <div class="top-label">{{cycleMetricsData.trend.sign}}{{cycleMetricsData.trend.value}}%</div>
                        <div class="bottom-label">Trend</div>
                    </div>
                    <div class="stat white">
                        <div class="top-label">{{cycleMetricsData.minima}}</div>
                        <div class="bottom-label">Minima</div>
                    </div>
                    <div class="stat">
                        <div class="top-label">{{cycleMetricsData.maxima}}</div>
                        <div class="bottom-label">Maxima</div>
                    </div>
                </div>
            </template>
            <template v-if="mergeMetricsData && selectedMetric == 'mpr'">
                <div class="stats-column">
                    <div class="stat">
                        <div class="top-label">{{mergeMetricsData.total_prs_merged}}</div>
                        <div class="bottom-label">Total PRs Merged</div>
                    </div>
                    <div class="stat white">
                        <div class="top-label">{{mergeMetricsData.avg_prs_merged}}</div>
                        <div class="bottom-label">Avg. PRs Merged</div>
                    </div>
                    <div class="stat">
                        <div class="top-label">{{mergeMetricsData.trend.sign}}{{mergeMetricsData.trend.value}}%</div>
                        <div class="bottom-label">Trend</div>
                    </div>
                    <div class="stat white">
                        <div class="top-label">{{mergeMetricsData.minima}}</div>
                        <div class="bottom-label">Minima</div>
                    </div>
                    <div class="stat">
                        <div class="top-label">{{mergeMetricsData.maxima}}</div>
                        <div class="bottom-label">Maxima</div>
                    </div>
                </div>
            </template>
            <template v-if="commentsMetricsData && selectedMetric == 'cc'">
                <div class="stats-column">
                    <div class="stat">
                        <div class="top-label">{{commentsMetricsData.total_comments}}</div>
                        <div class="bottom-label">Total PRs Merged</div>
                    </div>
                    <div class="stat white">
                        <div class="top-label">{{commentsMetricsData.avg_comments}}</div>
                        <div class="bottom-label">Avg. PRs Merged</div>
                    </div>
                    <div class="stat">
                        <div class="top-label">{{commentsMetricsData.trend.sign}}{{commentsMetricsData.trend.value}}%</div>
                        <div class="bottom-label">Trend</div>
                    </div>
                    <div class="stat white">
                        <div class="top-label">{{commentsMetricsData.minima}}</div>
                        <div class="bottom-label">Minima</div>
                    </div>
                    <div class="stat">
                        <div class="top-label">{{commentsMetricsData.maxima}}</div>
                        <div class="bottom-label">Maxima</div>
                    </div>
                </div>
            </template>
        </div>
        <div v-else class="message">
            We need atleast 3 days worth of data for the graphs to give something meaningful to you
        </div>
        <Modal v-if="showModal" :graphData="selectedUserGraph" :metricsData="selectedUserMetrics" @closeModal="showModal = false" />
        <div class="contributors">
            <div class="contributors-header">
                <div class="label">
                    <label>Contributors</label>    
                </div>      
            </div>
            <template v-if="selectedMetric == 'prt'">
                <template v-if="tinyGraphArray.length == 0">
                    <div class="contributors-class" >
                        <div v-for="(graph, index) in tempArray" :key="index" class="block" @click="showFullGraph(graph)">
                            <div class="up">
                                <!-- <div class="label-name">{{Object.keys(graph)[0]}}</div> TEMPORARY COMMENT -->
                                <!-- <div class="label-count">10</div> -->
                                <div class="label-name">
                                    Joey
                                </div>
                            </div>
                            <div class="down" v-if="selectedMetric == 'prt'">
                                <TinyLineGraph v-if="loaded" :graphData="graph"/>
                            </div>
                            <div class="down" v-if="selectedMetric == 'rtt'">
                                <TinyBarLineCombo :graphData="graph"/>
                            </div>
                            <div class="down" v-if="selectedMetric == 'prct'">
                                <TinyStackedBar />
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="message small">
                        There are no new contributors.
                    </div>
                </template>
            </template>
            <template v-if="selectedMetric == 'rtt'">
                <template v-if="rttByContributor.length != 0">
                    <div class="contributors-class" >
                        <div v-for="(graph, index) in rttByContributor" :key="index" class="block">
                            <div class="up">
                                <div class="label-name">{{Object.keys(graph)[0]}}</div>
                                <div class="label-count">10</div>
                                <!-- <div class="label-name">
                                    Joey
                                </div> -->
                            </div>
                            <!-- <div class="down" v-if="selectedMetric == 'prt'">
                                <TinyLineGraph v-if="loaded" :graphData="graph"/>
                            </div> -->
                            <div class="down" v-if="selectedMetric == 'rtt'">
                                <!-- <TinyBarLineCombo :graphData="graph"/> -->
                                <SmallBarLine :chartData="graph" />
                            </div>
                            <!-- <div class="down" v-if="selectedMetric == 'prct'">
                                <TinyStackedBar />
                            </div> -->
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="message small">
                        There are no new contributors.
                    </div>
                </template>
            </template>
            <template v-if="selectedMetric == 'prct'">
                <template v-if="cycleByContributor.length != 0">
                    <div class="contributors-class" >
                        <div v-for="(graph, index) in cycleByContributor" :key="index" class="block">
                            <div class="up">
                                <div class="label-name">{{Object.keys(graph)[0]}}</div>
                                <div class="label-count">10</div>
                                <!-- <div class="label-name">
                                    Joey
                                </div> -->
                            </div>
                            <!-- <div class="down" v-if="selectedMetric == 'prt'">
                                <TinyLineGraph v-if="loaded" :graphData="graph"/>
                            </div> -->
                            <!-- <div class="down" v-if="selectedMetric == 'rtt'">
                                <TinyBarLineCombo :graphData="graph"/>
                            </div> -->
                            <div class="down" v-if="selectedMetric == 'prct'">
                                <TinyStackedBar :graphData="graph"/>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="message small">
                        There are no new contributors.
                    </div>
                </template>
            </template>
            <template v-if="selectedMetric == 'mpr'">
                <template v-if="mergeContributor.length != 0">
                    <div class="contributors-class" >
                        <div v-for="(graph, index) in mergeContributor" :key="index" class="block">
                            <div class="up">
                                <div class="label-name">{{Object.keys(graph)[0]}}</div>
                                <div class="label-count">10</div>
                                <!-- <div class="label-name">
                                    Joey
                                </div> -->
                            </div>
                            <!-- <div class="down" v-if="selectedMetric == 'prt'">
                                <TinyLineGraph v-if="loaded" :graphData="graph"/>
                            </div> -->
                            <!-- <div class="down" v-if="selectedMetric == 'rtt'">
                                <TinyBarLineCombo :graphData="graph"/>
                            </div> -->
                            <div class="down" v-if="selectedMetric == 'mpr'">
                                <!-- <TinyMergedGraph :graphData="graph"/> -->
                                <SmallPRBarLineChartJS :chartData="graph" />
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="message small">
                        There are no new contributors.
                    </div>
                </template>
            </template>
            <template v-if="selectedMetric == 'cc'">
                <template v-if="commentsContributor.length != 0">
                    <div class="contributors-class" >
                        <div v-for="(graph, index) in commentsContributor" :key="index" class="block">
                            <div class="up">
                                <div class="label-name">{{Object.keys(graph)[0]}}</div>
                                <div class="label-count">10</div>
                                <!-- <div class="label-name">
                                    Joey
                                </div> -->
                            </div>
                            <!-- <div class="down" v-if="selectedMetric == 'prt'">
                                <TinyLineGraph v-if="loaded" :graphData="graph"/>
                            </div> -->
                            <!-- <div class="down" v-if="selectedMetric == 'rtt'">
                                <TinyBarLineCombo :graphData="graph"/>
                            </div> -->
                            <div class="down" v-if="selectedMetric == 'cc'">
                                <!-- <TinyCommentsGraph :graphData="graph"/> -->
                                <SmallCommentBarLineChartJS :chartData="graph" />
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="message small">
                        There are no new contributors.
                    </div>
                </template>
            </template>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.whole{
    // margin: 40px 30px 20px 30px;
    margin-top: 40px;
    height: 100%;
    width: 98%;
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .page-header{
        display: flex;
        flex-direction: row;
        width: inherit;
        justify-content: space-between;
        align-items: center;
        label{
            font-size: 50px;
            font-weight: bold;
            height: auto;
            color: #190845;
        }
    }
    .filters-row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: inherit;
        align-items: baseline;
        margin-bottom: 20px;
    }
    .outer-box{
        display: flex;
        flex-direction: row;
        border: 3px solid #e5e5e5;
        width: inherit;
        height: 55vh;
        z-index: 1;
        // position: absolute;
        .graph-column{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: inherit;
            height: 80%;
            .graph{
                margin: auto;
                height: 100%;
            }
            .selector{
                // height: 20%;
            }
            .view-selector{
                width: 200px;
                height: 10%;
            }
        }
        .stats-column{
            display: flex;
            flex-direction: column;
            height: inherit;
            width: 20%;
            .stat{
                display: flex;
                flex-direction: column;
                height: 20%;
                width: 100%;
                justify-content: flex-end;
                align-items: center;
                background: rgba(170, 212, 200, 0.5);
                color: #FF5B64;
                .top-label{
                    font-size: 40px;
                    font-weight: bold;
                    line-height: 20px;
                    font-family: Overpass;
                }
                .bottom-label{
                    font-size: 18px;
                    font-weight: normal;
                    line-height: 28px;
                    font-family: Overpass;
                }
            }
            .white{
                background: white;;
            }
        }
    }
    .contributors{
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        width: inherit;
        align-items: flex-start;
        .contributors-header{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: inherit;
            .label{
                font-family: Overpass;
                font-style: normal;
                font-weight: bold;
                font-size: 40px;
                line-height: 61px;
                color: #190845;
            }
        }
        .contributors-class{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: inherit;
            justify-content: flex-start;
            align-items: flex-end;
            border: 2px solid #e5e5e5;
            .block{
                width: 163px;
                height: 130px;
                border-left: 2px solid #e5e5e5;
                border-bottom: 2px solid #e5e5e5;
                border-right: 2px solid #e5e5e5;
                margin-top: 1.5rem;
                cursor: pointer;
                .up{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: flex-start;
                    .label-name{
                        font-size: 20px;
                    }
                    .label-count{
                        background: #AAD4C8;
                        width: 60px;
                        // border: 1px solid red;
                        border-radius: 10px;
                    }
                }
                .down{
                    
                }
            }
            .block:last-child{
                margin-bottom: 1.5rem;
            }
        }
    }
    .message{
        font-size: 50px;
        height: 80px;
        line-height: 80px;
        background: none;
        margin-top: 10vh;
        margin-bottom: 10vh;
    }
    .small{
        font-size: 25px;
        margin-top: 0;
    }
    
}
</style>
<script>
import SearchBox from '@/components/SearchBox';
import NewLineChart from '@/components/NewLineChart';
import TinyLineGraph from '@/components/TinyLineGraph';
import BarLineCombo from '@/components/BarLineCombo';
import StackedBarChart from '@/components/StackedBarChart';
import TinyBarLineCombo from '@/components/TinyBarLineCombo';
import TinyStackedBar from '@/components/TinyStackedBar';
import PRBarLine from '@/components/PRBarLine';
import CommentCoverageBarLine from '@/components/CommentCoverageBarLine';
import TinyMergedGraph from '@/components/TinyMergedGraph';
import TinyCommentsGraph from '@/components/TinyCommentsGraph';
import Modal from '@/components/Modal';
import Vue from 'vue';
import FishUI from 'fish-ui';
import PRService from '@/services/prService';
import BarLineChartJS from '@/components/BarLineChartJS.vue';
import SmallBarLine from '@/components/SmallBarLine.vue';
import PRBarLineChartJS from '@/components/PRBarLineChartJS.vue';
import SmallPRBarLineChartJS from '@/components/SmallPRBarLineChartJS.vue';
import CommentBarLineChartJS from '@/components/CommentBarLineChartJS.vue';
import SmallCommentBarLineChartJS from '@/components/SmallCommentBarLineChartJS.vue'; 
import axios from 'axios';

Vue.use(FishUI);
export default {
    name: 'review-metrics',
    components:{
        SearchBox, NewLineChart, TinyLineGraph, Modal, BarLineCombo, StackedBarChart, 
        TinyBarLineCombo, TinyStackedBar, PRBarLine, CommentCoverageBarLine, TinyMergedGraph, TinyCommentsGraph, BarLineChartJS, SmallBarLine, PRBarLineChartJS, SmallPRBarLineChartJS, CommentBarLineChartJS, SmallCommentBarLineChartJS
    },
    data(){
        let team_options = [];
        let repo_options = [];
        let selected_repos = [];
        let selected_teams = [];
        let time_period;
        let dataLoaded = false;
        let dataLoadedStacked = false;
        let loaded = false;
        let graphData = [];
        let graphType = 'throughput';
        let metricsData;
        let cycleMetricsData;
        let rttMetricsData;
        let mergeMetricsData;
        let commentsMetricsData;
        let arr = [1,2,3,4,5,6,7,8,9,10];
        let tinyGraphArray = [];
        let componentKey = 0;
        let componentKey2 = 0;
        let componentKey3 = 0;
        let componentKey4 = 0;
        let componentKeyy = 0;
        let showModal = false;
        let selectedUserGraph = [];
        let selectedUserMetrics;
        let noData = false;
        let selectedMetric =  'prt';
        let rttData = {};
        let rttLoaded = false;
        let cycleData = [];
        let tempArray = [1,2,3], rttByContributor = [], cycleByContributor = [], mergeData = [], mergeContributor = [], commentsData = [], commentsContributor = [] 
        return{
            team_options, repo_options, selected_repos, selected_teams, time_period, graphType, graphData, dataLoaded,
            metricsData, rttMetricsData, cycleMetricsData, arr, tinyGraphArray, loaded, componentKey, componentKey2,
            showModal, selectedUserGraph, selectedUserMetrics, noData, selectedMetric, rttData, tempArray, cycleData,
            rttByContributor, cycleByContributor, mergeData, mergeMetricsData, mergeContributor, commentsData, commentsContributor, commentsMetricsData,
            componentKey3, componentKey4, dataLoadedStacked, componentKeyy, rttLoaded
        }
    },
    methods:{
        changeView(){
            console.log(this.selectedMetric);
            if(this.selectedMetric == 'rtt'){
                this.setRTTGraph();
            } else if(this.selectedMetric == 'prt'){
                this.getThroughput();
            } else if(this.selectedMetric == 'prct'){
                this.setCycleGraph();
            } else if(this.selectedMetric == 'mpr'){
                this.setMergedPRgraph();
            } else if(this.selectedMetric == 'cc'){
                this.setCommentCoverageGraph();
            }
        },
        getTeams(){
            axios.get(`${process.env.VUE_APP_BASEURL}/get_teams`,{
                params:{
                    org_id: this.$route.params.id
                }
            }).then((response) => {
                this.team_options = response.data
            })
        },
        getRepos(){
            axios.get(`${process.env.VUE_APP_BASEURL}/get_org_repos`,{
                params:{
                    org_id: this.$route.params.id
                }
            }).then((response) => {
                //   // console.log(response.data)
                this.repo_options = response.data
            })
        },
        async getThroughput(){
            return await PRService.getThroughput(this.$route.params.id);
        },
        async getTinyGraphData(){
            return await PRService.getTinyGraphData(this.$route.params.id);
        },
        async getRTT(){
            return await PRService.getDummyData();
        },
        async getCycleTime(){
            return await PRService.getDummyDataCycle();
        },
        async getRTTByContributor(){
            return await PRService.getDummyRTTContributor();
        },
        async getCycleByContributor(){
            return await PRService.getDummyCycleContributor();
        },
        async getMergedPRs(){
            return await PRService.getDummyMergedPRs();
        },
        async getMergedPRsContributor(){
            return await PRService.getDummyMergedPRContributor();
        },
        async getComments(){
            return await PRService.getDummyComments();
        },
        async getCommentsContributor(){
            return await PRService.getDummyCommentsContributor();
        },
        setRTTGraph(){
            this.getRTT().then((response) => {
                console.log(response.data);
                this.rttData = response.data.graph;
                this.rttLoaded = true;
                this.rttMetricsData =  response.data.metrics;
                this.componentKeyy = !this.componentKeyy
            });
            this.getRTTByContributor().then((response) => {
                // console.log(response.data);
                this.rttByContributor = response.data;
            })
        },
        setCycleGraph(){
            this.getCycleTime().then((response) => {
                console.log(response.data);
                this.cycleData = response.data.graph;
                this.cycleMetricsData = response.data.metrics;
                this.componentKey4 = !this.componentKey4;
                this.dataLoadedStacked = true;
            })
            this.getCycleByContributor().then((response) => {
                this.cycleByContributor = response.data;
                console.log(response.data);
            })
        },
        setMergedPRgraph(){
            this.getMergedPRs().then((response) => {
                console.log(response.data);
                this.mergeData = response.data.graph;
                this.mergeMetricsData = response.data.metrics;
                this.componentKey2 = !this.componentKey2;
            })
            this.getMergedPRsContributor().then((response) => {
                this.mergeContributor = response.data;
            })
        },
        setCommentCoverageGraph(){
            this.getComments().then((response) => {
                this.commentsData = response.data.graph;
                this.commentsMetricsData = response.data.metrics;
                this.componentKey3 = !this.componentKey3
            })
            this.getCommentsContributor().then((response) => {
                this.commentsContributor = response.data;
            })
        },
        showFullGraph(graph){
            PRService.getUserWeeklyMetrics(this.$route.params.id, Object.keys(graph)[0]).then((response) => {
                this.selectedUserMetrics = response.data;
            })
            this.selectedUserGraph = graph
            this.showModal = true;
        },
    },
    created(){
        // console.log('created parent');
        this.getThroughput().then((response) => {
            console.log(response.data)
            console.log(response.data.graph);
            if(response.data.graph[0].pr_opened.length == 0 && response.data.graph[1].pr_closed.length == 0 
                && response.data.graph[2].pr_merged.length == 0 && response.data.graph[3].pr_review.length ==0){
                this.noData = true;
            }
            this.graphData = response.data.graph;
            this.metricsData = response.data.metrics;
        });
        this.getTinyGraphData().then((response) => {
            console.log(response.data);
            this.tinyGraphArray = response.data;
        })
        this.getTeams();
        this.getRepos();
    },
    watch: {
        graphData: function(){
            // console.log('watching graph data');
            if(this.graphData.length > 0){
                this.dataLoaded = true;
            }
        },
        tinyGraphArray: function(){
            // console.log('watching');
            if(this.tinyGraphArray.length > 0){
                this.loaded = true;
            }
        }
    }
}
</script>