<template>
    <div class="graph-container" :class="full_size ? 'big-size' : 'small-size'">
        <canvas id="barArea"></canvas>
    </div>
</template>
<style lang="scss" scoped>
.graph-container{
}
.big-size{
    width: 750px;
    height: 200px;
}
.small-size{
    height: 220px;
    width: 395px;
}
</style>
<script>
import { Chart, BarController, LineController, CategoryScale, Rectangle, Title, Filler } from "chart.js";
import { LinearScale } from 'chart.js';
import { Point } from 'chart.js';
import { Line } from 'chart.js';
import { Legend } from 'chart.js';
Chart.register(BarController, LineController, LinearScale, Point, Line, Legend, CategoryScale, Rectangle, Title, Filler)
export default {
    name: 'BarAreaChart',
    props:{
        chartData:{
            type: Array
        },
        location: {
            type: String
        }
    },
    data(){
        let full_size = false;
        return {
            full_size
        }
    },
    mounted(){

        if(this.location == 'openprs'){
            this.full_size = true;
        }

        let {labels, bData, lData} = this.sortData(this.chartData);

        let ctx = document.getElementById('barArea').getContext("2d");
        let myChart = new Chart(ctx, {
            data: {
                labels: labels,
                datasets: [{
                    type: 'bar',
                    label: 'Open PRs',
                    barPercentage: 0.9,
                    barThickness: 20,
                    // maxBarThickness: 8,
                    // minBarLength: 12,
                    data: bData,
                    yAxisID: 'A',
                    backgroundColor: '#8774BE',
                    order: 0
                },
                {   type: 'line',
                    label: 'LOC',
                    data: lData,
                    fill: 'origin',
                    backgroundColor: 'rgba(170, 212, 200, 0.7)',
                    yAxisID: 'B',
                    lineTension: 0,
                    pointRadius: 0,
                    borderWidth: 1,
                    order: 1
                }]
            },
            options: {
                scales: {
                    x:{
                        gridLines: {
                            color: '#FFFFFF'
                        }
                    },
                    A: {
                        type: 'linear',
                        position: 'left',
                        gridLines: {
                            color: '#FFFFFF'
                        }
                    },
                    B: {
                        type: 'linear',
                        position: 'right',
                        gridLines: {
                            color: '#FFFFFF'
                        }
                    }
                },
                legend:{
                    position: 'bottom'
                }
            }
        })
    },
    methods:{
        sortData(data){
            let months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

            let labels = [];
            let bData = [];
            let lData = [];
            data.forEach((elem) => {
                let d = new Date(elem.date);
                let month = months[d.getMonth()];
                let date = d.getDate();
                labels.push(`${date}-${month}`);
                bData.push(elem.open_prs);
                lData.push(elem.loc);
            })

            return {labels, bData, lData};
        }
    }
}
</script>
