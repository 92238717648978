<template>
    <div>
        <div ref="tinyStackedBar"></div>
    </div>
</template>
<style lang="scss" scoped>

</style>
<script>
import Plotly from 'plotly.js/dist/plotly';
export default {
    name: 'tinyGraph',
    props:{
        graphData:{
            required: false
        }
    },
    data(){
        let trace1 = {
            x: ['18', '19', '20', '21', '22', '23', '24'],
            y: [20, 14, 23, 13, 51, 44, 32],
            name: 'PR Opening Time',
            type: 'bar',
            marker: {
                    color: '#1B1D45',
                    width: 0.4
                }
        };

        let trace2 = {
            x: ['18', '19', '20', '21', '22', '23', '24'],
            y: [20, 14, 23, 13, 51, 34, 32],
            name: 'Reaction Time',
            type: 'bar',
            marker: {
                    color: '#F95A63',
                    width: 0.4
                }
        };

        let trace3 = {
            x: ['18', '19', '20', '21', '22', '23', '24'],
            y: [20, 14, 23, 13, 21, 44, 32],
            name: 'Approval Time',
            type: 'bar',
                    marker: {
                    color: '#AAD4C8',
                    width: 0.4
                }

        };

        let trace4 = {
            x: ['18', '19', '20', '21', '22', '23', '24'],
            y: [12, 14, 23, 13, 51, 44, 32],
            name: 'Merge Time',
            type: 'bar',
                    marker: {
                    color: '#8A6EDD',
                    width: 0.4
                }

        };


        let layout = {
            showlegend: false,
            margin: {
                l: 0,
                r: 0,
                b: 0,
                t: 0,
                pad: 0
            },
            height: 56,
            width:122,
            font: {family: 'Overpass'},
            barmode: 'stack', 
            legend: {orientation: "h", traceorder: "normal"},
            xaxis:{
                visible: false
            },
            yaxis:{
                visible: false
            }
        };
        let data = [trace1, trace2, trace3, trace4];
        return{
            data,
            layout
        }
    },
    mounted(){
        this.prepareGraph();
    },
    methods:{
        prepareGraph(){
            let finalData = this.sortData(Object.values(this.graphData)[0]);
            console.log(finalData);
            let graph = this.$refs.tinyStackedBar;
            Plotly.newPlot(graph, finalData, this.layout, {displayModeBar: false, responsive: true, staticPlot: true});
        },
        sortData(data){
            console.log(data);
            let months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

            let trace1 = {
                x:[],
                y:[],
                name: 'PR Opening Time',
                type: 'bar',
                marker: {
                        color: '#1B1D45'
                    }
            };

            let trace2 = {
                x:[],
                y:[],
                name: 'Reaction Time',
                type: 'bar',
                marker: {
                        color: '#F95A63'
                    }
            };

            let trace3 = {
                x:[],
                y:[],
                name: 'Approval Time',
                type: 'bar',
                        marker: {
                        color: '#AAD4C8'
                    }

            };

            let trace4 = {
                x:[],
                y:[],
                name: 'Merge Time',
                type: 'bar',
                        marker: {
                        color: '#8A6EDD'
                    }
            };

            data.pr_opening.forEach((elem) => {
                // console.log(elem)
                let d =  new Date(elem.date);
                let month = months[d.getMonth()];
                let date = d.getDate();

                trace1.x.push(`${date}-${month}`);
                trace1.y.push(elem.time)
            })

            data.reaction_time.forEach((elem) => {
                let d =  new Date(elem.date);
                let month = months[d.getMonth()];
                let date = d.getDate();

                trace2.x.push(`${date}-${month}`);
                trace2.y.push(elem.time)
            })

            data.approval_time.forEach((elem) => {
                let d =  new Date(elem.date);
                let month = months[d.getMonth()];
                let date = d.getDate();

                trace3.x.push(`${date}-${month}`);
                trace3.y.push(elem.time)
            })

            data.merge_time.forEach((elem) => {
                let d =  new Date(elem.date);
                let month = months[d.getMonth()];
                let date = d.getDate();

                trace4.x.push(`${date}-${month}`);
                trace4.y.push(elem.time)
            })

            return [trace1, trace2, trace3, trace4]
        }
    }
}
</script>