<template>
    <div class="graph-container">
        <canvas ref="commentbarline"></canvas>
    </div>
</template>
<style lang="scss" scoped>
.graph-container{
height: 200px;
width: 700px;
}
</style>
<script>
import { Chart, BarController, LineController, CategoryScale, Rectangle, Title } from "chart.js";
import { LinearScale } from 'chart.js';
import { Point } from 'chart.js';
import { Line } from 'chart.js';
import { Legend } from 'chart.js';
// import { color } from 'chart.js/helpers';
Chart.register(BarController, LineController, LinearScale, Point, Line, Legend, CategoryScale, Rectangle, Title)
export default {
    name: 'prbarline',
    props: {
        chartData: {
            type: Array
        }
    },
    mounted(){
        let {labels, bData, lData} = this.sortData(this.chartData);
        console.log(bData)
        var chart = this.$refs.commentbarline;
        var ctx = chart.getContext("2d");
        let myChart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: [{
                    label: 'Comments',
                    barPercentage: 0.9,
                    barThickness: 20,
                    data: bData,
                    backgroundColor: '#FF5B64',
                    order: 1
                },
                {   label: 'Comment Coverage',
                    type: 'line',
                    data: lData,
                    backgroundColor: 'transparent',
                    lineTension: 0,
                    pointRadius: 1,
                    borderWidth: 4,
                    borderColor: '#AAD4C8',
                    order: 0
                }]
            },
            options:{    
                scales: {
                    x:{
                        // display: false,
                        gridLines: {
                            color: '#FFFFFF'
                        }
                    },
                    y: {
                        gridLines: {
                            color: '#FFFFFF'
                        }
                    }
                },
                legend: {
                    position: 'top'
                }
            },
        })
    },
    methods: {
        sortData(data){
            let months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

            let labels = [];
            let bData = [];
            let lData = [];
            data.forEach((elem) => {
                let d = new Date(elem.date);
                let month = months[d.getMonth()];
                let date = d.getDate();
                labels.push(`${date}-${month}`);
                bData.push(elem.comments);
                lData.push(elem.comment_coverage);
            })

            return {labels, bData, lData};
        }
    }
}
</script>