<template>
    <div class="whole">
        <div class="page-header">
            <label>Overview</label>
            <div v-if="globalDisplayProperty" class="temp-switches">
                <fish-select v-model="send_data" style="width: 150px; height: auto;" hint="Data" @change="changeHandler">
                    <fish-option index="true" content="Full Data"></fish-option>
                    <fish-option index="half" content="Partial Data"></fish-option>
                    <fish-option index="false" content="No data"></fish-option>
                </fish-select>
            </div>
        </div>
        <hr />
        <div class="filters-row">
            <template>
                <fish-select search multiple v-model="selected_teams" style="width: 150px; height: auto;" hint="Teams">
                    <fish-option v-for="(team, index) in team_options" :key="index" :index="team.team_id" :content="team.team_name"></fish-option>
                </fish-select>
            </template>
            <template>
                <fish-select search multiple v-model="selected_repos" style="width: 150px; height: auto;" hint="Repos">
                    <fish-option v-for="(repo, index) in repo_options" :key="index" :index="repo.id" :content="repo.name"></fish-option>
                </fish-select>
            </template>
            <template>
                <fish-select v-model="time_period" style="width: 150px; height: auto;" hint="Time Period">
                    <fish-option index="7d" content="7 Days"></fish-option>
                    <fish-option index="14d" content="14 Days"></fish-option>
                    <fish-option index="30d" content="1 Month"></fish-option>
                </fish-select>
            </template>
            <!-- <template>
                <fish-select search multiple v-model="selected_repos" style="width: 150px; height: auto;" hint="Weekends">
                    <fish-option v-for="(repo, index) in repo_options" :key="index" :index="repo.name" :content="repo.name"></fish-option>
                </fish-select>
            </template> -->
            <template>
                <fish-button @click="applyFilters()">Apply</fish-button>
            </template>
        </div>
        <div class="stats-row" v-if="stats">
            <div class="boxxed">
                <div class="top">
                    {{stats.commit_volume}}
                </div>
                <hr />
                <div class="bottom">
                    <label class="head">Commit Volume</label>
                    <label class="num">5%</label>
                </div>
            </div>
            <div class="boxxed">
                <div class="top">
                    {{stats.efficiency}}
                </div>
                <hr />
                <div class="bottom">
                    <label class="head">Efficiency</label>
                    <label class="num">12%</label>
                </div>
            </div>
            <div class="boxxed">
                <div class="top">
                    {{stats.avg_lttc}} hrs
                </div>
                <hr />
                <div class="bottom">
                    <label class="head long">Avg. Lead time to change</label>
                    <label class="num">5%</label>
                </div>
            </div>
            <div class="boxxed">
                <div class="top">
                    {{stats.review_rate}}%
                </div>
                <hr />
                <div class="bottom">
                    <label class="head">Review Rate</label>
                    <label class="num">5%</label>
                </div>
            </div>
        </div>
        <div class="graphs">
            <div class="top">
                <!-- <OverviewSankeyChart /> -->
                <!-- <ChartSankey v-if="sLoaded" :chartData="sankeyData" /> -->
                <!-- <CommitBarLine v-if="dataLoaded" :type="type" :height="height" :width="width" :graphData="commitVolumeData" /> -->
                <template v-if="ifCommitDataFound">
                    <div class="graph-div">
                        <label>Commit Throughput</label>
                        <BarLineCommit v-if="dataLoaded" :chartData="commitVolumeData" />
                    </div>
                </template>
                <template v-else>
                    <div class="no-data-message">
                        <label for="">Sorry, but we couldn't find any data</label>
                    </div>
                </template>
            </div>
            <div class="bottom">
                <!-- <RadarChart v-if="snapLoaded" :graphData="snapshotData"/> -->
                <template v-if="ifSnapshotDataFound">
                    <div class="graph-div">
                        <label>Summary Percentile</label>
                        <VueRadarChart :chartData="snapshotData" v-if="snapLoaded" />
                    </div>
                </template>
                <template v-else>
                    <div class="no-data-message">
                        <label for="">Sorry, but we couldn't find any data</label>
                    </div>
                </template>
                <!-- <DonutChart v-if="codeFocusLoaded" :graphData="codeFocusData" /> -->
                <template v-if="ifCodeFocusDataFound">
                    <div class="graph-div">
                        <label for="">Code Focus</label>
                        <DoughnutChart v-if="codeFocusLoaded" :chartData="codeFocusData" />
                    </div>
                </template>
                <template v-else>
                    <div class="no-data-message">
                        <label for="">Sorry, but we couldn't find any data</label>
                    </div>
                </template>
                <!-- <AreaBarChart v-if="loaded" :height="height" :width="width" :type="type" :graphData="graphData" /> -->
                <template v-if="ifgraphDataFound">
                    <div class="graph-div">
                        <label for="">PR Throughput</label>
                        <BarAreaChart v-if="loaded" :chartData="graphData"/>
                    </div>
                </template>
                <template v-else>
                    <div class="no-data-message">
                        <label for="">Sorry, but we couldn't find any data</label>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.whole{
    margin-top: 40px;
    height: 100%;
    width: 98%;
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .page-header{
        display: flex;
        flex-direction: row;
        width: inherit;
        justify-content: space-between;
        align-items: center;
        label{
            font-size: 50px;
            font-weight: bold;
            height: auto;
            color: #190845;
        }
    }
    .filters-row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: inherit;
        align-items: baseline;
        margin-bottom: 20px;
    }
    .stats-row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        height: 100px;
        .boxxed{
            display: flex;
            flex-direction: column;
            width: 225px;
            height: inherit;
            align-items: center;
            justify-content: space-around;
            border-radius: 5px;
            border: 2px #BDBDBD solid;
            hr{
                height: 1px;
                width: 90%;
                background-color: #BDBDBD;
                margin: -10px;
            }
            .top{
                font-size: 40px;
                font-weight: bold;
            }
            .bottom{
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                width: 100%;
                .head{
                    font-size: 18px;
                }
                .long{
                    font-size: 14px;
                }
            }
        }
    }
    .graphs{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        .top{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: baseline;
            margin-top: 30px;
            .graph-div{
                display: flex;
                flex-direction: column;
                justify-content: center;
                label{
                    font-family: Overpass;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 25px;
                    line-height: 38px;
                }
            }
        }
        .bottom{
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            width: 100%;
            height: 300px;
            .graph-div{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: inherit;
                label{
                    font-family: Overpass;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 25px;
                    line-height: 38px;
                }
            }
            .no-data-message{
                margin-right: 30px;
            }
        }
    }
}
</style>
<script>
import axios from 'axios';
import OverviewSankeyChart from '@/components/OverviewSankeyChart.vue';
import ChartSankey from '@/components/ChartSankey.vue';
import CommitBarLine from '@/components/CommitBarLine.vue';
import DonutChart from '@/components/DonutChart.vue';
import DoughnutChart from '@/components/DoughnutChart.vue'
// import RadarChart from '@/components/RadarChart.vue';
import AreaBarChart from '@/components/AreaBarChart.vue';
import PRService from '@/services/prService';
import VueRadarChart from '@/components/VueRadarChart.vue';
import BarAreaChart from '@/components/BarAreaChart.vue';
import BarLineCommit from '@/components/BarLineCommit.vue';
export default {
    name: 'Overview',
    components:{
        OverviewSankeyChart, CommitBarLine, DonutChart, AreaBarChart, VueRadarChart, DoughnutChart, BarAreaChart, BarLineCommit, ChartSankey
    },
    data(){
        let team_options = [], repo_options = [], selected_repos = [], selected_teams, time_period, dataLoaded = false, commitVolumeData = [], metricsData, height, width, type, graphData = [],
        loaded = false, snapshotData, snapLoaded = false, codeFocusData, codeFocusLoaded = false, stats, sankeyData = [], sLoaded = false,
        ifCommitDataFound, ifSnapshotDataFound, ifCodeFocusDataFound, ifgraphDataFound, send_data = "true";
        return {
            team_options, repo_options, selected_repos, selected_teams, time_period, dataLoaded, commitVolumeData, metricsData, height, width, type, graphData, loaded, snapshotData, snapLoaded,
            codeFocusData, codeFocusLoaded, stats, sankeyData, sLoaded, ifCommitDataFound, ifSnapshotDataFound, ifCodeFocusDataFound, ifgraphDataFound, send_data
        }
    },
    methods:{
        getTeams(){
            axios.get(`${process.env.VUE_APP_BASEURL}/get_teams`,{
                params:{
                    org_id: this.$route.params.id
                }
            }).then((response) => {
                this.team_options = response.data
            })
        },
        getRepos(){
            axios.get(`${process.env.VUE_APP_BASEURL}/get_org_repos`,{
                params:{
                    org_id: this.$route.params.id
                }
            }).then((response) => {
                //   // console.log(response.data)
                this.repo_options = response.data
            })
        },
        async getCommitVolume(send_data){
            return await PRService.getDummyCommitVolume(send_data);
        },
        async getOpenPrsGraph(send_data){
            return await PRService.getDummyOpenPrs(send_data);
        },
        async getSnapshot(send_data){
            return await PRService.getDummySnapshot(send_data);
        },
        async getCodeFocus(send_data){
            return await PRService.getDummyCodeFocus(send_data);
        },
        async getDummyStats(){
            return await PRService.getDummyOverviewStats();
        },
        async getSankeyChartData(){
            return await PRService.getDummySankeyData();
        },
        getGraphData(send_data){
            this.getOpenPrsGraph(send_data).then((response) => {
                console.log(response.data);
                if(response.data.graph.length > 0){
                    this.graphData = response.data.graph;
                    this.ifgraphDataFound = true;
                } else {
                    this.ifgraphDataFound = false;
                }
                this.metricsData = response.data.metrics;
            })
        },
        getDummyCommitVolume(send_data){
            this.getCommitVolume(send_data).then((response) => {
                console.log(response.data);
                if(response.data.graph.length > 0){
                    console.log('more than 0')
                    this.ifCommitDataFound = true;
                    this.commitVolumeData = response.data.graph;
                    this.dataLoaded = true;
                } else {
                    this.ifCommitDataFound = false;
                }
                // this.commitVolumeMetrics = response.data.metrics;
            })
        },
        getSnapshotData(send_data){
            this.getSnapshot(send_data).then((response) =>{
                console.log(response.data)
                if(response.data == "no_data"){
                    this.ifSnapshotDataFound = false;
                } else {
                    this.snapshotData = response.data;
                    this.ifSnapshotDataFound = true;
                }
            })
        },
        getCodeFocusData(send_data){
            this.getCodeFocus(send_data).then((response) => {
                if(response.data == "no_data"){
                    this.ifCodeFocusDataFound = false;
                } else {
                    this.codeFocusData = response.data;
                    this.ifCodeFocusDataFound = true;
                }
            })
        },
        getOverviewStats(){
            this.getDummyStats().then((response) => {
                console.log(response.data);
                this.stats = response.data;
            })
        },
        getSankeyChart(){
            this.getSankeyChartData().then((response) => {
                console.log(response.data);
                this.sankeyData = response.data;
            })
        },
        changeHandler(){
            this.getDummyCommitVolume(this.send_data);
            this.getGraphData(this.send_data);
            this.getSnapshotData(this.send_data);
            this.getCodeFocusData(this.send_data);
        }
    },
    created(){
        this.getTeams();
        this.getRepos();
        this.getDummyCommitVolume(this.send_data);
        this.getGraphData(this.send_data);
        this.getSnapshotData(this.send_data);
        this.getCodeFocusData(this.send_data);
        this.getOverviewStats();
        this.getSankeyChart();
        this.height = 210;
        this.width = 445;
        this.type = "overview";
    },
    watch:{
        commitVolumeData: function(){
            if(this.commitVolumeData.length > 0){
                this.dataLoaded = true;
            }
        },
        graphData: function(){
            if(this.graphData.length > 0){
                this.loaded = true;
            }
        },
        snapshotData: function(){
            if(this.snapshotData){
                this.snapLoaded = true;
            }
        },
        codeFocusData: function(){
            if(this.codeFocusData){
                this.codeFocusLoaded = true;
            }
        },
        sankeyData: function(){
            if(this.sankeyData.length > 0){
                this.sLoaded = true;
            }
        }
    }
}
</script>