<template>
    <div class="graph-container">
        <canvas ref="smallbarline"></canvas>
    </div>
</template>
<style lang="scss" scoped>
.graph-container{
    height: 130px;
    width: 150px;
}
</style>
<script>
import { Chart, BarController, LineController, CategoryScale, Rectangle, Title } from "chart.js";
import { LinearScale } from 'chart.js';
import { Point } from 'chart.js';
import { Line } from 'chart.js';
import { Legend } from 'chart.js';
// import { color } from 'chart.js/helpers';
Chart.register(BarController, LineController, LinearScale, Point, Line, Legend, CategoryScale, Rectangle, Title)
export default {
    name: 'smallbarline',
    props: {
        chartData: {
            type: Object
        },
        size: {
            type: String
        }
    },
    mounted(){
        let {labels, lessthan8, lessthan24, lessthan48, morethan48} = this.sortData(Object.values(this.chartData)[0]);
        let dataset = [lessthan8, lessthan24, lessthan48, morethan48];
        console.log(dataset)
        var chart = this.$refs.smallbarline;
        var ctx = chart.getContext("2d");
        let myChart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: dataset
            },
            options: {
                scales: {
                    x:{
                        display: false,
                        gridLines: {
                            color: '#FFFFFF'
                        }
                    },
                    y: {
                        display: false,
                        gridLines: {
                            color: '#FFFFFF'
                        }
                    }
                },
                legend:{
                    display: false,
                    // position: 'bottom'
                }
            }
        })
    },
    methods:{
        sortData(data){
            let months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

            let labels = [];


            var lessthan8 = {
                data: [],
                label: '<8 hrs',
                backgroundColor: '#1B1D45'
            };

            var lessthan24 = {
                data: [],
                label: '<24hrs',
                backgroundColor: '#F95A63'
            };

            var lessthan48 = {
                data: [],
                label: '<48hrs',
                backgroundColor: '#AAD4C8'
            };

            var morethan48 = {
                data: [],
                type: 'bar',
                label: '48+hrs',
                backgroundColor: '#8A6EDD'
            };
            
            data.forEach((elem) => {
                let lt8 = 0, lt24 = 0, lt48 = 0, gt48 = 0;
                let d =  new Date(elem.date);
                let month = months[d.getMonth()];
                let date = d.getDate();
                labels.push(`${date}-${month}`);
                elem.rtt.forEach((rtt) => {
                    if(rtt < 8){  
                        lt8++;                      
                    } else if(rtt > 8 && rtt < 24){
                        lt24++;
                        
                    } else if(rtt > 24 && rtt < 48){
                        lt48++;                        
                    } else {
                        gt48++;
                    }
                })
                lessthan8.data.push(lt8);
                lessthan24.data.push(lt24);
                lessthan48.data.push(lt48);
                morethan48.data.push(gt48);
            })

            return {labels, lessthan8, lessthan24, lessthan48, morethan48};
        }
    }
}
</script>