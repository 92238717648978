<template>
    <div class="graph-container">
        <canvas id="radar"></canvas>
    </div>
</template>
<style lang="scss" scoped>
.graph-container{
    // height: 400px;
    // width: 400px;
}
</style>
<script>
import { Chart, LinearScale, RadarController } from "chart.js";
import { RadialLinearScale } from 'chart.js';
import { Point } from 'chart.js';
import { Line, Title } from 'chart.js';
Chart.register(LinearScale, RadarController, RadialLinearScale, Point, Line, Title)
export default {
    name: 'radar-chart',
    props:{
        chartData:{
            type: Object
        }
    },
    mounted(){

        let theta = this.sortData(this.chartData);

        let ctx = document.getElementById('radar').getContext("2d");
        let myChart = new Chart(ctx, {
            type: 'radar',
            data: {
                labels: ['Total Commits','Efficiency','Merge Rate', 'Average LTA', 'Review Rate', 'Reaction Time'],
                datasets: [{
                    data: theta,
                    backgroundColor: "transparent",
                    borderColor: "rgb(27, 29, 69)",
                    pointStyle: 'circle',
                    radius: 1,
                    fill: false
                }]
            },
            options:{
                responsive: true,
                legend: false,
                tooltip: false,
                title:{
                    display: false,
                    text: 'Summary Percentile',
                    fontSize: 20,
                    fontStyle: 'bold'
                }
            }
        })
    },
    methods:{
        sortData(graphData){
            let theta = []
            theta[0] = graphData.total_commits;
            theta[1] = graphData.efficiency;
            theta[2] = graphData.merge_rate;
            theta[3] = graphData.average_lta;
            theta[4] = graphData.review_rate;
            theta[5] = graphData.reaction_time;
            return theta;
        }
    }

}
</script>