<template>
<div class="graph-container">
     <canvas id="sankeychart"></canvas>
</div>
</template>
<style lang="scss" scoped>
.graph-container{
    width: 400px;
    height: 250px;
}
</style>
<script>
import { Chart, LinearScale } from "chart.js";
import { SankeyController, Flow } from "chartjs-chart-sankey";

Chart.register(LinearScale, SankeyController, Flow);

export default {
  name: "App",
  props:{
      chartData: {
          type: Array
      }
  },
  mounted(){
    var ctx = document.getElementById("sankeychart").getContext("2d");

    var colors = {
      Pushed: "#302057",
      Abandoned: "#42494F",
      Open: "#FF6B73",
      Reviewed: "#B2D8CD",
      Closed: "#EBEEF0",
      Merged: "Gray",
      Approved: "#9364E1"
    };

    function getColor(name) {
      return colors[name] || "green";
    }

    new Chart(ctx, {
      type: "sankey",
      data: {
        datasets: [
          {
            data: this.chartData,
            colorFrom: c => getColor(c.dataset.data[c.dataIndex].from),
            colorTo: c => getColor(c.dataset.data[c.dataIndex].to)
          }
        ]
      },
        options: {
            scales: {
                x: {
                    offset: false,
                }
            },
            layout: {
                padding: {
                    right: 20
                }
            }
        }
    });
  }
};
</script>
