<template>
    <div>
        <div ref="codeBarChart"></div>
    </div>
</template>
<style lang="scss" scoped>

</style>
<script>
import Plotly from 'plotly.js/dist/plotly';
export default {
    name: 'CodeBarLine',
    props:{
        graphData:{
            required: true
        }
    },
    data(){

        var trace1 = {
            x: [1, 2, 3, 4],
            y: [1, 4, 9, 16],
            name: 'Trace1',
            type: 'bar'
        };
        var trace2 = {
            x: [1, 2, 3, 4],
            y: [6, -8, -4.5, 8],
            name: 'Trace2',
            type: 'bar'
        };

        var data = [trace1, trace2];
        var layout = {
            xaxis: {title: 'X axis'},
            yaxis: {title: 'Y axis'},
            barmode: 'relative',
            bargap: 0.05,
            showlegend: true,
            margin: { t: 20},
            height: 315,
            width: 800,
            font: {family: 'Overpass'},
            legend: {orientation: "h"},
        };

        return{
            layout, data
        }
    },
    mounted(){
        this.prepareGraph();
        // let graph = this.$refs.codeBarChart;
        // Plotly.newPlot(graph, this.data, this.layout, {displayModeBar: false, responsive: true, staticPlot: true});
    },
    methods:{
        prepareGraph(){
            let finalData = this.sortData(this.graphData);
            console.log(finalData)
            
            let graph = this.$refs.codeBarChart;
            Plotly.newPlot(graph, finalData, this.layout, {displayModeBar: false, responsive: true, staticPlot: true});
        },
        sortData(data){
            console.log(data);
            let months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

            let barUp = {
                x: [],
                y: [], //date
                type: 'bar',
                name: 'New LOC',
                marker: {color: '#AAD4C8'},
                width: []
            }

            let barDown = {
                x: [],
                y: [],
                type: 'bar',
                name: 'Churn',
                marker: {color: '#FF5B64'},
                width: []
            };
            
            data.forEach((elem) => {
                console.log(elem);
                let d =  new Date(elem.date);
                let month = months[d.getMonth()];
                let date = d.getDate();
                barUp.x.push(`${date}-${month}`);
                barUp.y.push(elem.new_loc);
                barDown.x.push(`${date}-${month}`)
                barDown.y.push(elem.churn);
                barUp.width.push(0.3);
                barDown.width.push(0.3)
            })
             return [barUp, barDown]

        },
    },
}
</script>