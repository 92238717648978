<template>
    <div class="whole">
        <div class="page-header">
            <label>Worklog</label>
        </div>
        <hr />
        <div class="filters-row">
            <template>
                <fish-select search multiple v-model="selected_repos" style="width: 150px; height: auto;" hint="Repos">
                    <fish-option v-for="(repo, index) in repo_options" :key="index" :index="repo.id" :content="repo.name"></fish-option>
                </fish-select>
            </template>
            <template>
                <fish-button @click="applyFilters()">Apply</fish-button>
            </template>
        </div>
        <div class="secondary-header">
            <label>Developer's Snapshot</label>
        </div>
        <div class="developer">
            <div class="dev-name search">
                <!-- <label>Anish Gupta</label> -->
                 <v-autocomplete :items="new_items" v-model="item" :get-label="getLabel" :component-item='template' @update-items="updateItems">
                </v-autocomplete>
            </div>
        </div>
        <div class="stats-table">
                <div class="table-border">
                    <div class="table-header">
                        <div class="heading">

                        </div>
                        <div class="heading">
                            <label>

                            total commits
                            </label>
                        </div>
                        <div class="heading">
                            <label>

                            efficiency
                            </label>
                        </div>
                        <div class="heading">
                            <label>

                            merge rate
                            </label>
                        </div>
                        <div class="heading">
                            <label>

                            average LTA
                            </label>
                        </div>
                        <div class="heading">
                            <label>

                            review rate
                            </label>
                        </div>
                        <div class="heading">
                            <label>

                            reaction time
                            </label>
                        </div>
                    </div>
                    <div class="table-body">
                        <div class="info titles">
                            <label for="" class="row-1">absolute</label>
                            <label for="" class="title-small mg">team average</label>
                            <label for="" class="title-small mg">WoW Growth</label>
                            <label for="" class="title-small mg">MoM Growth</label>
                        </div>
                        <div class="info subs-row">
                            <label for="" class="row-1">
                                229
                            </label>
                            <label for="" class="mg">
                                167
                            </label>
                            <label for="" class="mg">
                                167
                            </label>
                            <label for="" class="mg">
                                167
                            </label>
                        </div>
                        <div class="info subs-row">
                            <label for="" class="row-1">
                                75%
                            </label>
                            <label for="" class="mg">
                                65%
                            </label>
                            <label for="" class="mg">
                                65%
                            </label>
                            <label for="" class="mg">
                                65%
                            </label>
                        </div>
                        <div class="info subs-row">
                            <label for="" class="row-1">
                                65%
                            </label>
                            <label for="" class="mg">
                                56%
                            </label>
                            <label for="" class="mg">
                                56%
                            </label>
                            <label for="" class="mg">
                                56%
                            </label>
                        </div>
                        <div class="info subs-row">
                            <label for="" class="row-1">
                                12 hrs
                            </label>
                            <label for="" class="mg">
                                8 hrs
                            </label>
                            <label for="" class="mg">
                                8 hrs
                            </label>
                            <label for="" class="mg">
                                8 hrs
                            </label>
                        </div>
                        <div class="info subs-row">
                            <label for="" class="row-1">
                                36%
                            </label>
                            <label for="" class="mg">
                                56%
                            </label>
                            <label for="" class="mg">
                                56%
                            </label>
                            <label for="" class="mg">
                                56%
                            </label>
                        </div>
                        <div class="info subs-row">
                            <label for="" class="row-1">
                                5.6 hrs
                            </label>
                            <label for="" class="mg">
                                3.5 hrs
                            </label>
                            <label for="" class="mg">
                                3.5 hrs
                            </label>
                            <label for="" class="mg">
                                3.5 hrs
                            </label>
                        </div>
                    </div>
                </div>
        </div>
        <div class="graphs">
            <DoughnutChart v-if="codeFocusLoaded" :chartData="codeFocusData" />
            <hr class="hr" />
            <VueRadarChart :chartData="radarData" v-if="radarDataLoaded" />
            <hr class="hr" />
            <div class="top-repos">
                <ProgressBar :color="'#8753DE'" :percentage="50"/>
                <ProgressBar :color="'#AAD4C8'" :percentage="18"/>
                <ProgressBar :color="'#FF5B64'" :percentage="20"/>
                <ProgressBar :color="'#190845'" :percentage="4"/>
                <ProgressBar :color="'#BDBDBD'" :percentage="10"/>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.whole{
    margin-top: 40px;
    height: 100%;
    width: 98%;
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .page-header{
        display: flex;
        flex-direction: row;
        width: inherit;
        justify-content: space-between;
        align-items: center;
        label{
            font-size: 50px;
            font-weight: bold;
            height: auto;
            color: #190845;
        }
    }
    .filters-row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: inherit;
        align-items: baseline;
        margin-bottom: 20px;
    }
    .secondary-header{
        font-family: Overpass;
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 61px;

        color: #FF5B64;
    }
    .developer{
        .dev-name{
            font-family: Overpass;
            font-style: normal;
            font-weight: bold;
            font-size: 35px;
        }
        .charts-area{
            
        }
    }
    .stats-table{
        width: 100%;
        height: 246px;
        margin-top: 10px;
        .table-border{
            width: inherit;
            height: 246px;
            // background-color: red;
            border: 1px solid #BDBDBD;
            display: flex;
            flex-direction: column;
            .table-header{
                height: 60px;
                display: flex;
                flex-direction: row;
                margin-top: 5px;
                .heading:first-child{
                    border-left: none;
                    margin-left: 10px;
                }
                .heading{
                    font-family: Overpass;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    text-transform: capitalize;
                    border-left: 1px solid #BDBDBD;
                    border-bottom: 1px solid #BDBDBD;
                    // margin: auto;
                    width: 146px;
                    // text-align: center;
                    display: flex;
                    align-items: center;
                    label{
                        // vertical-align: middle;
                        margin: 0 auto;
                    }
                    
                }
            }
            .table-body{
                display: flex;
                flex-direction: row;
                height: 100%;
                .titles{

                }
                .info{
                    display: flex;
                    flex-direction: column;
                    color: #190845;
                    width: 146px;
                    border-left: 1px solid #BDBDBD;
                    text-transform: capitalize;
                    font-size: 20px;
                    margin-top: -5px;
                    .row-1{
                        font-size: 32px;
                        font-weight: bold;
                        color: #8753DE;
                        margin-bottom: 30px;
                        margin-top: 15px;
                    }
                    .title-small{
                        font-family: Overpass;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 18px; 
                    }
                    .mg{
                        margin-bottom: 20px;
                    }
                }
                .info:first-child{
                    border-left: none;
                    margin-left: 10px;
                    color: #190845;
                }
                .subs-row{
                    margin-bottom: 10px;
                }
            }
        }
    }
    .graphs{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 30px;
        .hr{
            height: 270px;
            width: 3px;
            background-color: #e5e5e5;
        }
        .top-repos{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 90%;
            margin-left: 40px;
        }
    }
}
</style>
<script>
import axios from 'axios';
import DoughnutChart from '@/components/DoughnutChart.vue';
import VueRadarChart from '@/components/VueRadarChart.vue';
import ProgressBar from '@/components/ProgressBar.vue';
import PRService from '@/services/prService';
import Vue from 'vue';
import Autocomplete from 'v-autocomplete';
import 'v-autocomplete/dist/v-autocomplete.css';
import ItemTemplate from '@/components/ItemTemplate';
Vue.use(Autocomplete);
export default {
    name: 'snapshot',
    components: {
        DoughnutChart, VueRadarChart, ProgressBar, ItemTemplate
    },
    data(){
        let repo_options= [], selected_repos, codeFocusData, codeFocusLoaded = false, radarData, radarDataLoaded = false, item, new_items = [];
        return {
            repo_options, selected_repos, codeFocusData, codeFocusLoaded, radarData, radarDataLoaded, template: ItemTemplate,
            items: [{id: 1, name: 'Anish Gupta'}, {id: 2, name: 'Baljeet Kumar'}, {id: 3, name: 'Kavita Kumari'}], item: {id: 1, name: 'Anish Gupta'},
            new_items
        }
    },
    methods:{
        async getCodeFocus(){
            return await PRService.getDummyCodeFocus();
        },
        async getSnapshot(){
            return await PRService.getDummySnapshot();
        },
        getCodeFocusData(){
            this.getCodeFocus().then((response) => {
                console.log(response.data);
                this.codeFocusData = response.data;
            })
        },
        getSnapshotData(){
            this.getSnapshot().then((response) =>{
                console.log(response.data)
                this.radarData = response.data;
            })
        },
        getRepos(){
            axios.get(`${process.env.VUE_APP_BASEURL}/get_org_repos`,{
                params:{
                    org_id: this.$route.params.id
                }
            }).then((response) => {
                //   // console.log(response.data)
                this.repo_options = response.data
            })
        },
        updateItems(text){
            console.log(text);
            if(text.length > 0){
                console.log('running method')
                this.new_items = this.filterItems(text);
            } else {
                this.new_items = this.items
            }
        },
        filterItems(text){
            let filteredItems = this.items.reduce((acc, elem) => {
                                    console.log(elem);
                                    if(elem.name.toLowerCase().includes(text.toLowerCase())){
                                        acc.push(elem);
                                    }
                                    return acc;
                                }, [])

            console.log(filteredItems);
            return filteredItems;
        },
        getLabel(item){
            console.log(item)
            return item.name;
        }
    },
    created(){
        this.getRepos();
        this.getCodeFocusData();
        this.getSnapshotData();
    },
    watch:{
        codeFocusData: function(){
            if(this.codeFocusData){
                this.codeFocusLoaded = true;
            }
        },
        radarData: function(){
            if(this.radarData){
                this.radarDataLoaded = true;
            }
        },
    }

}
</script>