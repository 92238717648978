<template>
    <div v-if="loggedIn">
        <template v-if="showThis">
            <section id="header" class="header">
                <div class="container">
                    <div class="logo collapsible_content">
                        <button class="expand_content" @click="dropMenu()">
                            <img v-bind:src="avatarUrl" alt="avatar">
                        </button>
                        <div class="expanded_content position-left" id="menu_dropper">
                            <div class="accountDropdown loading">
                                <h3><span>Your organizations<span></span></span></h3>
                                <ul v-for="elem in list" v-bind:key="elem.account.id">
                                    <li>
                                        <a @click="openOrg(elem.account.id)">{{elem.account.login}}</a>
                                    </li>
                                </ul>
                                <a class="btn btn-grey" href="https://github.com/apps/go-hedwig/installations/new">Add organization</a>
                            </div>
                        </div>
                    </div>
                    <div class="nav">
                        <!-- <div class="nav-item"> -->
                            <router-link class="nav-item nav-link dashboard" active-class="selectedtab" :to="dashboardUrl">Dashboard</router-link>
                        <!-- </div> -->
                        <!-- <div class="nav-item"> -->
                            <router-link class="nav-item nav-link leaderboard" active-class="selectedtab" :to="leaderboardUrl">Leaderboard</router-link>
                        <!-- </div> -->
                        <!-- <div class="nav-item"> -->
                            <router-link class="nav-item nav-link prs" active-class="selectedtab" :to="prsUrl">Pullrequests</router-link>
                        <!-- </div> -->
                        <div class="collapsible_content">
                            <a class="slack nav-item nav-link expand_content" style="padding: 12px;" @click="showDropdown()">Reminders</a>
                            <div class="expanded_content position-center width-250" id="dropper">
                                <div class="subnav">
                                    <div class="subnav-link" @click="hide()">
                                        <router-link :to="teamRemindersUrl">Team reminders</router-link>
                                    </div>
                                    <div class="subnav-link" @click="hide()">
                                        <router-link :to="personalRemindersUrl">My DM settings</router-link>
                                    </div>
                                    <div class="subnav-link" @click="hide()">
                                        <router-link :to="addUsersUrl">Add users</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="user">
                        <div class="avatar">
                            <img v-bind:src="user.avatar_url" alt="userAvatar">
                        </div>
                        <button class="logout-btn" @click="logout">Log out</button>
                    </div>
                </div>
            </section>
        </template>
        <template v-if="!showThis">
            <section id="header" class="header">
                <div class="container-log">
                    <!-- <div class="logo collapsible_content"> -->
                        <!-- <button class="expand_content load_accounts">
                            <img v-bind:src="avatarUrl" alt="avatar">
                        </button> -->
                    <!-- </div> -->
                    <div class="user">
                        <div class="avatar" v-if="user">
                            <img v-bind:src="user.avatar_url" alt="userAvatar">
                        </div>
                        <button @click="logout" class="logout-btn">Log out</button>
                    </div>
                </div>
            </section>
        </template>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import axios from 'axios';
export default {
    name: 'Header',
    methods: {
        ...mapActions([
            'logout'
        ]),
        hide: function(){
            // console.log("brurra")
            this.dropState = false;
            document.getElementById("dropper").style.display = "none";
        },
        showDropdown: function(){
            if(!this.dropState){
                this.dropState = true
                document.getElementById("dropper").style.display = "block"
            } else {
                this.dropState = false;
                document.getElementById("dropper").style.display = "none"

            } 
        },
        createHeadersUrl: function(id){
            this.prsUrl = "/installs/" + id + "/prs";
            this.teamRemindersUrl = "/installs/" + id + "/notification_rules";
            this.personalRemindersUrl = "/installs/" + id + "/personal-settings";
            this.addUsersUrl = "/installs/" + id + "/slack_users";
            this.avatarUrl = `https://avatars1.githubusercontent.com/u/${id}?v=4`;
            this.leaderboardUrl = "/installs/" + id + "/leaderboard";
            this.dashboardUrl = "/analytics/" + id + "/overview";
        },
        dropMenu: function(){
            if(!this.menuDropState){
                this.menuDropState = true;
                document.getElementById("menu_dropper").style.display = "block" ;
                if(!this.orgLoaded){
                    axios.get(`${process.env.VUE_APP_BASEURL}/installations`, {
                        params: {
                            token: localStorage.getItem('accessToken')
                        }
                    }).then((response) => {
                        // console.log(response)
                        this.list = response.data.installations;
                        this.orgLoaded = true;
                    })
                }
                

            } else {
                this.menuDropState = false;
                document.getElementById("menu_dropper").style.display = "none"
            }
        },
        openOrg: function(id){
            this.$router.push({ name: 'getting-started', params: {id: id}}).catch(err => {})
        }
    },
    data(){
        let showThis;
        let dropState = false;
        let menuDropState = false;
        let prsUrl;
        let teamRemindersUrl;
        let personalRemindersUrl;
        let addUsersUrl;
        let avatarUrl;
        let list;
        let user;
        let loggedIn;
        let leaderboardUrl;
        let dashboardUrl;
        let orgLoaded;
        return{
            showThis,
            dropState,
            prsUrl,
            teamRemindersUrl,
            personalRemindersUrl,
            addUsersUrl,
            menuDropState,
            avatarUrl,
            list,
            user,
            loggedIn,
            leaderboardUrl,
            dashboardUrl,
            orgLoaded,
        }
    },
    computed: {
        ...mapState([

        ])
    },
    watch: {
        $route(to, from){
            // console.log('route changed')
            // // console.log(this.$route)
            if(this.$route.name == 'pullrequests' || this.$route.name == 'getting-started'
                || this.$route.name == 'leaderboard' || this.$route.name == 'add-users' || this.$route.name == 'dashboard'){
                    this.showThis = true;
                    this.loggedIn = true;
                    
            }
            else if(this.$route.name == 'home'){
                this.loggedIn = false;
            }
            else if(this.$route.name == 'new-dashboard' || this.$route.name == 'overview-page' 
            || this.$route.name == 'author-leaderboard' || this.$route.name == 'reviewer-leaderboard' || this.$route.name == 'review-metrics' 
            || this.$route.name == 'team-settings' || this.$route.name == 'personal-settings' || this.$route.name == 'notification-rules'){
                this.loggedIn = false;
                this.showThis = false;
            }
            else{
                this.showThis = false;
                this.loggedIn = true;
            }
            let id = this.$route.params.id;
            this.createHeadersUrl(id)
        }
    },
    created(){
        if(this.$route.name == 'pullrequests' || this.$route.name == 'getting-started'
                || this.$route.name == 'leaderboard' || this.$route.name == 'add-users' || this.$route.name == 'dashboard'){
                    this.showThis = true;
                    this.loggedIn = true;

        }
         else if(this.$route.name == 'home'){
                this.loggedIn = false;
            }
            else if(this.$route.name == 'new-dashboard' || this.$route.name == 'overview-page' 
            || this.$route.name == 'author-leaderboard' || this.$route.name == 'reviewer-leaderboard' || this.$route.name == 'review-metrics'
            || this.$route.name == 'team-settings' || this.$route.name == 'personal-settings' || this.$route.name == 'notification-rules'){
                this.showThis = false;
                this.loggedIn = false;
            }
        else{
            this.showThis = false;
                    this.loggedIn = true;

        }
        let id = this.$route.params.id;
        if(localStorage.getItem('githubUser')){
            this.user = JSON.parse(localStorage.getItem('githubUser'))
        }
        this.createHeadersUrl(id);
    }
}
</script>

<style lang="scss" scoped>

.header{
    padding-top: 0.65em;
    margin-bottom: 1em;
    .container{
        display: flex;
        align-items: center;
        text-align: center;
        max-width: 98%;
        justify-content: space-between;
        height: 50px;
        .logo{
            flex: 0 0 4.5em;
            img {
                background: #fff;
                height: 1.75em;
                width: 1.75em;
                border-radius: 3px;
                margin-right: 0.45em;
                box-shadow: 0 2px 5px 0 rgba(60,66,87,0.1), 0 1px 1px 0 rgba(0,0,0,0.07);
            }
            .expand_content {
                display: flex;
                align-items: center;
            }
            .expand_content::after {
                content: "";
                border: solid #666;
                border-width: 0 1.5px 1.5px 0;
                display: inline-block;
                transform: rotate(45deg);
                vertical-align: middle;
                margin-top: -0.125em;
                width: 0.25em;
                height: 0.25em;
            }
            .expanded_content{
                .accountDropdown {
                    min-height: 3em;
                    padding: 1.5em;
                    width: 15em;
                    position: relative;
                    text-align: left;
                    h3 {
                        position: relative;
                        font-size: 1em;
                        margin: 0;
                        z-index: 0;
                        margin-bottom: 1em;
                        display: block;
                        // font-size: 1.17em;
                        margin-block-start: 1em;
                        margin-block-end: 1em;
                        margin-inline-start: 0px;
                        margin-inline-end: 0px;
                        font-weight: bold;
                        span {
                            display: inline-block;
                            padding: 0 0.5em;
                            padding-left: 0;
                            background: #2d2d2d;
                            color: white;
                        }
                    }
                    ul {
                        display: block;
                        margin-block-start: 1em;
                        margin-block-end: 1em;
                        margin-inline-start: 0px;
                        margin-inline-end: 0px;
                        padding-inline-start: 40px;
                        list-style-type: none;
                        padding: 0;
                        margin: 0;
                        li {
                            margin-bottom: 0.5em;
                            display: list-item;
                            text-align: -webkit-match-parent;
                            a {
                                color: white;
                                text-decoration: none;
                            }
                        }
                        li:last-child {
                            margin-bottom: 0;
                        }
                    }
                    .btn {
                        margin-top: 1.5em;
                        width: 100%;
                        text-align: center;
                        padding-left: 0;
                        padding-right: 0;
                        border-radius: 0.25em;
                        display: inline-block;
                        font-size: 0.95em;
                        font-weight: 700;
                        padding: 0.6em 1em;
                        text-decoration: none;
                        cursor: pointer;
                        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.18);
                    }
                    .btn.btn-grey {
                        color: #805baf;
                        background-color: white;
                        background-image: linear-gradient(-180deg, #fafbfc 0%, #eff3f6 90%);
                        border: 1px solid rgba(27,31,35,0.2);
                    }
                }
            }
        }
        .nav{
            // text-align: center;
            display: flex;
            flex-direction: row;
            // background-color: red;
            height: 50px;
            width: 600px;
            justify-content: space-around;
            .nav-item{
                display: block;
                // margin: 0 auto;
                position: relative;
                height: 50px;
                width: 150px;
                text-align: center;
                transition: background-color 0.5s;
                // transition: color 0.5s;
                
            }
            .nav-link{
                    display: inline-block;
                    font-size: 1.25em;
                    padding-top: 12px;
                    text-decoration: none;
                    font-weight: 700;
                    font-family: 'Raleway';
                    color: white;
                    
                    // text-align: center;
                    // transition: all 0.1s ease-in;
                    // background-repeat: no-repeat;
                    // background-position: 0 center;
                    // background-size: 1.25em;
                    // padding: 0.15em 0 0.15em 1.5em;
                    .subnav{
                        text-align: left;
                        padding: 1em 2em;
                        .subnav-link{
                            margin: 1em 0;
                            a {
                                text-decoration: none;
                                color: white;
                            }
                        }
                    }
                }
            .selectedtab{
                    color: white;
                    border-bottom: #ff7315;
                    border-style: solid;
                    border-top: none;
                    border-left: none;
                    border-right: none;
                    border-radius: 2px;
                } 
            .subnav {
                text-align: left;
                padding: 1em 2em;
                .subnav-link {
                    margin: 1em 0;
                    a {
                        text-decoration: none;
                        color: white;
                    }
                }
            }   
            .nav-item:hover{
                background-color: white;
            }
            .nav-item:hover.nav-link{
                // background-color: white;
                color: #ff7315;
            }
        }
        .user {
            right: 0;
            display: flex;
            flex-direction: row;
            
            align-items: center;
            .avatar{
                // height: 25px;
                // width: 25px;
                // border-radius: 50px;
                border-radius: 1.75em;
                height: 1.75em;
                width: 1.75em;
                overflow: hidden;
                display: inline-block;
                box-shadow: 0 2px 5px 0 rgba(60,66,87,0.1), 0 1px 1px 0 rgba(0,0,0,0.07);
            }
            .logout-btn{
                background: none;
                color: white;
                border: none;
                font-weight: 700;
                cursor: pointer;
            }
        }
        .collapsible_content .expanded_content.position-left {
            left: -8px;
        }

        .collapsible_content .expanded_content.position-left::after {
            left: 14px;
        }
        .collapsible_content .expand_content {
            cursor: pointer;
            background: none;
            padding: 0;
            border: none;
            outline: none;
        }
        .collapsible_content .expanded_content {
            position: absolute;
            border-radius: 4px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.2), 0 5px 10px rgba(0,0,0,0.05);
            background: #2d2d2d;
            margin-top: 10px;
            top: 100%;
            z-index: 5;
            display: none;
        }
        .collapsible_content .expanded_content::after {
            content: ' ';
            position: absolute;
            top: 0;
            margin-top: -7px;
            transform: rotate(45deg);
            width: 12px;
            height: 12px;
            background: #2d2d2d;
            border: 1px solid #2d2d2d;
            border-right: 0;
            border-bottom: 0;
        }
        .collapsible_content .expanded_content.position-center.width-250 {
            width: 200px;
            margin-left: -45px;
        }
    }
    .container-log {
        justify-content: flex-end;
        align-items: flex-end;
        .user {
            right: 0;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            .avatar{
                height: 25px;
                width: 25px;
                border-radius: 50px;
            }
            .logout-btn{
                background: none;
                color: white;
                border: none;
                font-weight: 700;
                cursor: pointer;
            }
        }
    }
}

/* #header .nav .nav-item .nav-link.dashboard {
    background-image: url(//d23pdqum5wojjd.cloudfront.net/assets/marketing_pages/pull-analytics-a7bcc7bf18795698824ce75ab39370b35cbb2544889c495cdfae31b7a112514d.svg);
}

#header .nav .nav-item .nav-link.leaderboard {
    background-image: url(//d23pdqum5wojjd.cloudfront.net/assets/app/pulse-f9a2db635a220eb046e8a7f11afbd5b56a7d72b3be929efe01ca144910c34455.svg);
}

#header .nav .nav-item .nav-link.prs {
    background-image: url(//d23pdqum5wojjd.cloudfront.net/assets/app/git-commit-768c2d053626ca38a134153a18ad099414417bffd987449a74fb515d16f89ec9.svg);
}

#header .nav .nav-item .nav-link.slack {
    background-image: url(//d23pdqum5wojjd.cloudfront.net/assets/slack-mark-monotone-6e7fcc2a2ce2a8548fd82b196e7b85a023b04f3be54f2a2140d43b1d92ed87ab.svg);
} */


button {
    text-transform: none;
}

button {
    overflow: visible;
}

button {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}


h1, h2, h3, h4, h5 {
    letter-spacing: -0.005em;
}
</style>