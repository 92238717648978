<template>
    <div v-bind:class="{'sidemenu-main': true, begone: hideMenu}">
        <div class="logo">
            <img src="../assets/white-on-transparent.png" height="100" width="150"/>
        </div>
        <hr />
        <div class="menu-items">
            <div class="menu-item">
                <MenuItem v-for="menuItem in menuItems" :key="menuItem.label"  
                :icon="menuItem.icon" :label="menuItem.label" :subMenu="menuItem.subMenu" 
                :subMenuItems="menuItem.subMenuItems" :location="menuItem.location" />
            </div>
        </div>
        <div class="logout" @click="logout()" >
            <img src="../assets/logout.png"/>
            <label class="logout-btn">LOGOUT</label>
        </div>
        <div class="footer-copyright">
            <hr style="width: 225px; height: 1px; color: white;"/>
            <label class="text">
                ⓒ 2020 Roxorlabs, Inc.
            </label>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.sidemenu-main{
    display:flex;
    flex-direction: column;
    background-color: #2D353C;
    justify-content: center;
    align-items: center ;
    width: 225px;
    height: 100vh;
    // position: fixed;
    .logo{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        margin-bottom: -10px;
    }
    .menu-items{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: inherit;
        width: 200px;
        .menu-item{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            height: 40px;
            width: 200px;
            margin-top: 10px;
        }
    }
    .logout{
        cursor: pointer;
        // height: 0px;
        // width: 60px;
        .logout-btn{
            color: white;  
            cursor: pointer;     
        }
    }
    .footer-copyright{
        // margin: 0 auto;

        .text{
            color: white;
            margin-bottom: 10px;
            font-size: 18px;
            // line-height: 28px;
        }
    }
}
.begone{
    display: none;
}
</style>
<script>
import MenuItem from '@/components/MenuItem.vue';
import { mapState, mapActions } from 'vuex';

export default {
    name: 'SideMenu',
    components: {
        MenuItem
    },
    data(){
        let hideMenu = false;
        const menuItems = [
            {
                icon: "overview",
                label: 'Overview',
                subMenu: false,
                location: { name: 'overview-page', params: { id: this.$route.params.id} }
            },
            {
                icon: "workload", 
                label: 'Workload',
                subMenu: false,
                location: { name: 'workload', params: { id: this.$route.params.id} }
            },
            {
                icon: "worklog", 
                label: 'Worklog',
                subMenu: true,
                subMenuItems: [
                    // {label:'Daily Activity', location: { name: 'daily-activity', params: { id: this.$route.params.id}}},
                    {label: 'Snapshot', location: { name: 'snapshot', params: { id: this.$route.params.id}}},
                    {label:'Commit Timeline', location: { name: 'commit-timeline', params: { id: this.$route.params.id}}}
                ]
            },
            {
                icon: "leaderboard",
                label: 'Leaderboard',
                subMenu: true,
                subMenuItems: [
                    {label: 'Reviewers', location: {name: 'reviewer-leaderboard', params: { id: this.$route.params.id}}},
                    {label:'Authors', location: { name: 'author-leaderboard', params: { id: this.$route.params.id}}}
                ]
            },
            {
                icon: "pr-overview",
                label: 'PR Overview',
                subMenu: true,
                subMenuItems: [
                    {label: 'Review Metrics', location: {name: 'review-metrics', params: { id: this.$route.params.id}}},
                    {label:'Open PRs', location: {name: 'open-prs', params: { id :this.$route.params.id}}}
                ]
            },
            {
                icon: "settings",
                label: "Settings",
                subMenu: true,
                subMenuItems: [
                    {label: 'Team Settings', location: {name: 'notification-rules', params: { id: this.$route.params.id}}},
                    {label: 'Personal Settings', location: {name: 'personal-settings', params: { id: this.$route.params.id}}},
                    {label: 'Add Users', location: {name: 'add-users', params: {id: this.$route.params.id}}},
                    {label: 'Integrations', location: {name: 'auth-integrations',  params: {id: this.$route.params.id}}},
                    // {label: 'Repositories', location: {name: 'repositories', params: {id: this.$route.params.id}}}
                ]
            }
        ];
        return{
            menuItems,
            hideMenu
        }
    },
    computed:{
        ...mapState(
            ['slackState']
        )
    },
    watch:{
        $route(to, from){
            if(this.$route.name == 'auth'){
                this.hideMenu = true;
            }
            else{
                this.hideMenu = false;
            }
        }
    },
    created(){
        if(this.$route.name == 'auth'){
                this.hideMenu = true;
            }
        else{
            this.hideMenu = false;
        }
    },
    methods:{
        ...mapActions([
            'logout'
        ]),
    }
}
</script>