<template>
    <div>
        <div ref="barChart"></div>
    </div>
</template>
<style lang="scss" scoped>

</style>
<script>
import Plotly from 'plotly.js/dist/plotly';
export default {
    name: 'BarLineCombo',
    props:{
        graphData:{
            required: true
        }
    },
    data(){
        let layout = {
                showlegend: true,
                margin: { t: 20},
                height: 325,
                width: 800,
                font: {family: 'Overpass'},
                legend: {orientation: "h"},
                bargroup: 'group',
                bargap: 0.3,
                bargroupgap: 0.1
            }
        return{
            layout
        }
    },
    mounted(){
        this.prepareGraph();
    },
    methods:{
        prepareGraph(){
            let finalData = this.sortData(this.graphData);
            console.log(finalData)
            
            let graph = this.$refs.barChart;
            Plotly.newPlot(graph, Object.values(finalData), this.layout, {displayModeBar: false, responsive: true, staticPlot: true});
        },
        sortData(data){
            // console.log(data);
            let months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

            var lessthan8 = {
                x: [],
                y: [],
                type: 'bar',
                name: '<8 hrs',
                marker: {color: '#1B1D45'},
            };

            var lessthan24 = {
                x: [],
                y: [],
                type: 'bar',
                name: '<24hrs',
                marker: {color: '#F95A63'},
            };

            var lessthan48 = {
                x: [],
                y: [],
                type: 'bar',
                name: '<48hrs',
                marker: {color: '#AAD4C8'},
            };

            var morethan48 = {
                x: [],
                y: [],
                type: 'bar',
                name: '48+hrs',
                marker: {color: '#8A6EDD'},
            };

            
            data.forEach((elem) => {
                console.log(elem);
                let lt8 = 0, lt24 = 0, lt48 = 0, gt48 = 0;
                let d =  new Date(elem.date);
                let month = months[d.getMonth()];
                let date = d.getDate();
                lessthan8.x.push(`${date}-${month}`);
                lessthan24.x.push(`${date}-${month}`);
                lessthan48.x.push(`${date}-${month}`);
                morethan48.x.push(`${date}-${month}`);
                elem.rtt.forEach((rtt) => {
                    if(rtt < 8){  
                        lt8++;                      
                    } else if(rtt > 8 && rtt < 24){
                        lt24++;
                        
                    } else if(rtt > 24 && rtt < 48){
                        lt48++;                        
                    } else {
                        gt48++;
                    }
                })
                lessthan8.y.push(lt8);
                lessthan24.y.push(lt24);
                lessthan48.y.push(lt48);
                morethan48.y.push(gt48);
            })
            

            return {lessthan8: lessthan8, lessthan24: lessthan24, lessthan48: lessthan48, morethan48: morethan48};

        },
    },
    
}
</script>