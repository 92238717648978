<template>
    <div class="modal">  
        <div class="outer-box">
            <div class="graph-column">
                <label class="name"> {{Object.keys(graphData)[0]}}</label>
                <div class="graph">
                    <NewLineChart :graphData="graphData" :graphType="graphType"/>
                </div>
            </div>
            <div class="stats-column" v-if="metricsData">
                <div class="stat">
                    <div class="top-label">{{metricsData.total}}</div>
                    <div class="bottom-label">Total PRs Opened</div>
                </div>
                <div class="stat white">
                    <div class="top-label">{{metricsData.avg_prs}}</div>
                    <div class="bottom-label">Avg. PRs Opened</div>
                </div>
                <div class="stat">
                    <div class="top-label">{{metricsData.trend.type}}{{metricsData.trend.trend}}%</div>
                    <div class="bottom-label">Trend</div>
                </div>
                <div class="stat white">
                    <div class="top-label">{{metricsData.min}}</div>
                    <div class="bottom-label">Minima</div>
                </div>
                <div class="stat">
                    <div class="top-label">{{metricsData.max}}</div>
                    <div class="bottom-label">Maxima</div>
                </div>
            </div>
            <div class="close" @click="closeModal()">
                <label>
                    &times;
                </label>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    .modal{
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.4);
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
        position: fixed;
        z-index: 200;
        // top: 14vh;
        // left: 19vh;

        // padding: 40px;box-sizing: border-box;
        .close{
            font-size: 40px;
            position: relative;
            width: 10px;
            cursor: pointer;
        }
        .close:hover{
            font-size: 45px;
        }
        .outer-box{
            display: flex;
            flex-direction: row;
            width: 85vw;
            height: 55vh;
            margin: auto;
            
            background: #fefefe;
            justify-content: space-between;
            align-items: flex-start;
            // left: 22vh;
            box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.4);
            padding: 15px;
            .graph-column{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: inherit;
                height: 80%;
                .name{
                    font-size: 30px;
                }
                .graph{
                    height: 70%;
                }
                .selector{
                    height: 20%;
                }
            }
            .stats-column{
                display: flex;
                flex-direction: column;
                height: inherit;
                width: 20%;
                .stat{
                    display: flex;
                    flex-direction: column;
                    height: 18%;
                    width: 100%;
                    justify-content: flex-end;
                    align-items: center;
                    background: rgba(170, 212, 200, 0.5);
                    color: #FF5B64;
                    .top-label{
                        font-size: 40px;
                        font-weight: bold;
                        line-height: 20px;
                        font-family: Overpass;
                    }
                    .bottom-label{
                        font-size: 18px;
                        font-weight: normal;
                        line-height: 28px;
                        font-family: Overpass;
                    }
                }
                .white{
                    background: white;;
                }
            }
        }
    }
</style>
<script>
import NewLineChart from '@/components/NewLineChart';
export default {
    name: 'modal',
    components:{
        NewLineChart
    },
    props:{
        graphData:{
            required: true
        },
        metricsData:{
            required: true
        }
    },
    data(){
        return{
            graphType: 'single-user'
        }
    },
    created(){
        // console.log('asdas')
    },
    methods:{
        closeModal(){
            this.$emit('closeModal')
        }
    }
}
</script>