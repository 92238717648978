<template>
    <div>
        <div ref="mergeBarChart"></div>
    </div>
</template>
<style lang="scss" scoped>
</style>
<script>
import Plotly from 'plotly.js/dist/plotly';
export default {
    name: 'BarLineCombo',
    props:{
        graphData:{
            required: true
        }
    },
    data(){
        var layout = {
            showlegend: false,
            margin: {
                l: 0,
                r: 0,
                b: 0,
                t: 0,
                pad: 0
            },
            height: 56,
            width:122,
            font: {family: 'Overpass'},
            barmode: 'stack', 
            legend: {orientation: "h", traceorder: "normal"},
            xaxis:{
                visible: false
            },
            yaxis:{
                visible: false
            }
        };

        return{
            layout
        }
    },
    mounted(){
        this.prepareGraph();
    },
    methods:{
        prepareGraph(){
            let finalData = this.sortData(Object.values(this.graphData)[0]);
            console.log(finalData)
            
            let lineGraph = this.$refs.mergeBarChart;
            Plotly.newPlot(lineGraph, Object.values(finalData), this.layout, {displayModeBar: false, responsive: true, staticPlot: true});
        },
        sortData(data){
            let months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

            let bar = {
                x: [],
                y: [], //date
                type: 'bar',
                name: 'Commits/Day',
                marker: {color: '#FF5B64'},
                width: []
            }

            let line = {
                x: [],
                y: [],
                name: 'Avg. Commits/Author/Day',
                mode: 'lines',
                line: {color: '#AAD4C8'}
            };
            
            data.forEach((elem) => {
                console.log(elem);
                let d =  new Date(elem.date);
                let month = months[d.getMonth()];
                let date = d.getDate();
                bar.x.push(`${date}-${month}`);
                bar.y.push(elem.comments);
                line.x.push(`${date}-${month}`)
                line.y.push(elem.comment_coverage);
                bar.width.push(0.3)
            })
             return [line, bar]
        }
    }
}
</script>