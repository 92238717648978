<template>
  <div class="home" v-if="!loggingIn">
    <!-- <div class="logo">
      <img src="../assets/hedwig-white.png" />
      <label class="intro-text">
        <vue-typed-js :backSpeed=10 :strings="['Forget reviewing your Pullrequests?', 'Install Hedwig for Github and get reminded for your Pullrequests regularly']" :loop=true>
          <p class="typing"></p>
        </vue-typed-js>
      </label>
    </div> -->
    <!-- <LoginBtn msg="Welcome to Hedwig"/>
  </div>
  <div v-else class="loader">
    <img src="../assets/91.gif" /> -->
  </div>
</template>

<script>
// @ is an alias to /src
import Vue from 'vue';
import LoginBtn from '@/components/LoginBtn.vue';
import { mapActions, mapState } from 'vuex';

// Vue.use(VueTypedJs);
export default {
  name: 'home',
  components: {
    LoginBtn,
  },
  computed: {
    ...mapState([
      'loggingIn',
      'loginError',
      'loginSuccessful',
    ]),
  },
  methods: {
    ...mapActions([
      'doLogin',
    ]),
    login(token){
      this.doLogin(token)
    }
  },
  data(){
    let loggedIn = false;
    return {
      loggedIn
    }
  },
  created(){
    // const token = window.location.search.split("=")[1]
    // if(token) {
    //   this.login(token)
    // }
    // else {
    //   // console.log('else');
    // }

    if(localStorage.getItem('userOrg')){
      let org_id = localStorage.getItem('userOrg');
      this.$router.replace({name: 'review-metrics', params: {id: org_id}});
    } else {
      this.$router.push({name: 'auth'})
    }


  },
};
</script>
<style lang="scss" scoped>
.home {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  .logo{
    margin-top: 50px;
    height: 35%;
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .intro-text{
      font-weight: 600;
      font-style: italic;
      color: white;
    }
  }
}
.loader{
  height: 200px;
  width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  // margin: 200px;
}
</style>