<template>
    <div>
        <div ref="barLineChart"></div>
    </div>
</template>
<style lang="scss" scoped>

</style>
<script>
import Plotly from 'plotly.js/dist/plotly';
export default {
    name: 'PRBarLine',
    props:{
        graphData:{
            required: true
        }
    },
    data(){

        var trace1 = {
            x: [0, 1, 2, 3, 4, 5],
            y: [1.5, 1, 1.3, 0.7, 0.8, 0.9],
            type: 'scatter'
        };

        var trace2 = {
            x: [0, 1, 2, 3, 4, 5],
            y: [1, 0.5, 0.7, -1.2, 0.3, 0.4],
            type: 'bar'
        };

        var data = [trace1, trace2];
        let layout = {
                showlegend: true,
                margin: { t: 20},
                height: 315,
                width: 800,
                font: {family: 'Overpass'},
                legend: {orientation: "h"},
            }
        return{
            layout, data
        }
    },
    mounted(){
        this.prepareGraph();
    },
    methods:{
        prepareGraph(){
            console.log(this.graphData);
            let finalData = this.sortData(this.graphData);
            console.log(finalData)
            
            let graph = this.$refs.barLineChart;
            Plotly.newPlot(graph, finalData, this.layout, {displayModeBar: false, responsive: true, staticPlot: true});
        },
        sortData(data){
            // console.log(data);
            let months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

            let bar = {
                x: [],
                y: [], //date
                type: 'bar',
                name: 'Merged PRs',
                marker: {color: '#6951AE'},
                width: []
            }

            let line = {
                x: [],
                y: [],
                // type: 'lines',
                name: 'Merge Rate',
                mode: 'lines',
                line: {color: '#AAD4C8'}
            }
            data.forEach((elem) => {
                console.log(elem);
                let d =  new Date(elem.date);
                let month = months[d.getMonth()];
                let date = d.getDate();
                bar.x.push(`${date}-${month}`);
                bar.y.push(elem.merged_prs);
                line.x.push(`${date}-${month}`)
                line.y.push(elem.merge_rate);
                bar.width.push(0.3)
            })
             return [line, bar]

        },
    },
    
}
</script>