<template>
    <div>
        <div ref="lineGraph"></div>
    </div>
</template>
<style lang="scss">

</style>
<script>
import Plotly from 'plotly.js/dist/plotly';
export default {
    name: 'NewLineChart',
    props: {
        graphData:{
            required: true
        },
        graphType: {
            type: String,
            required: false
        }
    },
    data(){
        let line1 = {
            y: [16, 25, 35, 24, 15, 31],
            x: ['Jan-02', 'Jan-03', 'Jan-04', 'Jan-05', 'Jan-06', 'Jan-07'],
            type: 'Scatter',
            name: 'Dev 1'
        };
        let line2 = {
            y: [10, 12, 16, 14, 23, 43],
            x: ['Jan-02', 'Jan-03', 'Jan-04', 'Jan-05', 'Jan-06', 'Jan-07'],
            type: 'Scatter',
            name: 'Dev 2'
        };
        let layout = {
            showlegend: true,
            margin: { t: 20},
            height: 225,
            width: 800,
            font: {family: 'Overpass'}
            
        }
        let data = [line1, line2];
        let gd = [];
        return{
            gd,
            data,
            layout
        }
    },
    mounted(){
        // console.log('created')
        if(this.graphType == 'throughput' && this.graphData){
            this.prepareThroughputGraph();
        }
        else if(this.graphType == 'leaderboard'){
            // console.log('is it getting called again?')
            this.prepareGraph();
        }
        else if(this.graphType == 'single-user'){
            this.prepareUserGraph();
        }
    },
    methods:{
        prepareGraph(){
            // console.log('prepare graph called');
            let months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
            
            let colors = ['#1B1D45', '#AAD4C8', '#F95A63', '#FFC13D', '#6A54AE']; 
            // console.log(this.graphData)
            let preparedData = this.graphData.reduce((accumulator, currentValue) => {
                // // console.log(accumulator);
                if(!accumulator.hasOwnProperty(currentValue.github_login)){
                    // add property
                    accumulator[currentValue.github_login] = {
                        x: [],
                        y: [],
                        line: {
                            color: colors[colors.length - 1]
                        }
                    };
                    colors.pop();
                    let d =  new Date(currentValue.date);
                    let month = months[d.getMonth()];
                    let date = d.getDate(); 
                    accumulator[currentValue.github_login].x.push(`${month} - ${date}`);
                    accumulator[currentValue.github_login].y.push(currentValue.count);
                    accumulator[currentValue.github_login].type = 'Scatter';
                    accumulator[currentValue.github_login].name = currentValue.github_login;
                    // accumulator[currentValue.github_login].line.color = colors[i];
                    
                    return accumulator;
                } else{
                    // push data to key
                    let d =  new Date(currentValue.date);
                    let month = months[d.getMonth()];
                    let date = d.getDate(); 
                    accumulator[currentValue.github_login].x.push(`${month} - ${date}`);
                    // accumulator[currentValue.github_login].x.push(currentValue.date);
                    accumulator[currentValue.github_login].y.push(currentValue.count);
                    // accumulator[currentValue.github_login].line[color] = colors[i];
                    
                    // i++;
                    return accumulator;
                }
            }, {});
            // console.log(preparedData)
            // return preparedData;
            let lineGraph = this.$refs.lineGraph;
            Plotly.newPlot(lineGraph, Object.values(preparedData), this.layout, {displayModeBar: false, responsive: true, staticPlot: true});
            // return preparedData;
        },
        prepareThroughputGraph(){
            // take the Array
            let months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
            // console.log('called throughput graph');
            // // console.log(this.graphData);
            let colors = ['#1B1D45', '#AAD4C8', '#F95A63', '#FFC13D'];
            let i = 0;
            let scatter_graph = this.graphData.reduce((accumulator, currentValue) => {
                let temp = currentValue[Object.keys(currentValue)[0]]
                let obj_in = temp.reduce((acc, cVal) => {
                    let d =  new Date(cVal.date);
                    let month = months[d.getMonth()];
                    let date = d.getDate();
                    acc.x.push(`${month} - ${date}`);
                    acc.y.push(cVal.count)
                    acc.line.color = colors[i];
                    i++;
                    return acc;
                },{x:[], y:[], line: {color: ''}});
                // // console.log(obj_in);
                obj_in.name = Object.keys(currentValue)[0];
                // obj_in.type = 'Scatter';
                obj_in.mode = 'lines';
                accumulator.push(obj_in);
                return accumulator;
            }, []);
            // console.log(scatter_graph);
            let lineGraph = this.$refs.lineGraph;
            Plotly.newPlot(lineGraph, Object.values(scatter_graph), this.layout, {displayModeBar: false, responsive: true, staticPlot: true});
        },
        prepareUserGraph(){
            // console.log(this.graphData);
            // take the Array
            let months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
            // console.log('called user graph');
            // // console.log(Object.keys(this.graphData));
            let currentObject = this.graphData[Object.keys(this.graphData)[0]]
            // console.log(currentObject);
            let scatter_graph = currentObject.reduce((accumulator, currentValue) => {
                let temp = currentValue[Object.keys(currentValue)[0]]
                let obj_in = temp.reduce((acc, cVal) => {
                    let d =  new Date(cVal.time);
                    let month = months[d.getMonth()];
                    let date = d.getDate();
                    acc.x.push(`${month} - ${date}`);
                    acc.y.push(cVal.count)
                    return acc;
                },{x:[], y:[]});
                // // console.log(obj_in);
                obj_in.name = Object.keys(currentValue)[0];
                obj_in.mode = 'lines';
                accumulator.push(obj_in);
                return accumulator;
            }, []);
            // console.log(scatter_graph);
            let lineGraph = this.$refs.lineGraph;
            Plotly.newPlot(lineGraph, Object.values(scatter_graph), this.layout, {displayModeBar: false, responsive: true, staticPlot: true});
        }
    }
}
</script>