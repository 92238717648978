<template>
    <div class="card-temp">
        <div class="card" @click="show()">
            <div class="left">
                <div class="pr-name">
                    <div class="icon">
                        <img src="../assets/folder.png" />
                    </div>
                    <div class="name">Hedwig_testing</div>
                    <span class="icon material-icons" @click="openPRLink()">
                        launch
                    </span>
                </div>
                <div class="pr-details">
                    <div class="author">
                        <div class="icon">
                            <img src="../assets/profile.png" />
                        </div>
                        <label>By <span class="name">Anish Gupta,</span></label>
                    </div>
                    <div class="date">Opened 3 days ago</div>
                </div>
            </div>
            <div class="center">
                <div class="pr">
                    <div class="pr-number">{{ pr.payload.head.repo.name }} #{{pr.payload.number}},</div>
                    <div class="pr-message">{{pr.payload.title}}</div>
                </div>
                <div class="details">
                    <div class="reviewers">2 reviewers</div>
                    <div class="comments">{{ pr.payload.comments }} comments</div>
                    <div class="size">
                        <label>Size: </label>
                        <label class="plus">+{{ pr.payload.additions }}</label>
                        <label class="minus">-{{ pr.payload.deletions }}</label>
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="last-activity">
                    <label class="title">Last activity: &nbsp;</label>
                    <label class="days">2 Days ago</label>
                </div>
                <div class="approved-by">Approved by: Rajeev P</div>
            </div>
        </div>
        <div v-bind:class="{'card-expansion': true, 'slideout': hidden, 'slidein': !hidden}">
            <div class="reviewers">
                <label class="r">Reviewers: </label>
                <label class="names">Baljeet Kumar, Anish Gupta</label>
            </div>
            <div class="bottom">
                <label class="message">
                    Make Lat and Long_ values consistent for something
                </label>
                <div class="description-block">
                    <label class="h">Description</label>
                    <div class="description">
                        1. Removed the Alpha buttons
                        2. Added the new beta button to sign-up
                    </div>
                </div>
                <label class="comments">1 Comment(s)</label>
                <div class="comment">
                    <div class="commenter">
                        <div class="icon">
                            <img src="../assets/profile.png" />
                        </div>
                        <label class="name">Anish Gupta</label>
                    </div>
                    <div class="time">
                        Tue, 5May’20, 16:40 PM
                    </div>
                    <div class="view-button">
                        <div class="btn">
                            <label>View</label>
                        </div>
                    </div>
                </div>
                <label class="approvals">1 Approval</label>
                <div class="approval">
                    <div class="approver">
                        <div class="icon">
                            <img src="../assets/profile.png" />
                        </div>
                        <label class="name">Anish Gupta</label>
                    </div>
                    <div class="time">
                        Tue, 5May’20, 16:40 PM
                    </div>
                    <div class="state">
                        <div class="lbl">
                            Approved
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.card-temp{
    display: flex;
    flex-direction: column;
    width: 100%;
    .card{
        display: flex;
        flex-direction: row;
        width: inherit;
        justify-content: space-between;
        height: 70px;
        cursor: pointer;
        .left{
            display: flex;
            flex-direction: column;
            width: 35%;
            height: inherit;
            justify-content: space-between;
            // align-items: center;
            border-left: 2px solid;
            border-right: 2px solid;
            border-color: #e5e5e5;
            .pr-name{
                display: flex;
                flex-direction: row;
                width: 50%;
                margin-top: 7px;
                margin-left: 20px;
                justify-content: space-between;
                align-items: center;
                .icon{
                    height: 20px;
                    width: 20px;
                }
                .material-icons{
                    font-size: 18px;
                }
                .name{
                    font-size: 16px;
                    font-weight: 700;
                }
            }
            .pr-details{
                display: flex;
                flex-direction: row;
                width: 100%;
                align-items: flex-start;
                margin-bottom: 6px;
                .author{
                    width: 45%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    font-size: 16px;
                    justify-content: space-between;
                    margin-left: 20px;
                    .icon{
                        height: 20px;
                        width: 20px;
                    }
                    .name{
                        font-weight: 700;
                    }
                }
                .date{
                    width: 55%;
                    font-size: 16px;
                }
            }
        }
        .center{
            display: flex;
            flex-direction: column;
            width: 45%;
            height: inherit;
            justify-content: space-between;
            border-right: 2px solid;
            border-color: #e5e5e5;
            padding-left: 5px;
            padding-right: 5px;
            .pr{
                display: flex;
                flex-direction: row;
                width: 100%;
                margin-top: 7px;
                justify-content: space-between;
                .pr-number{
                    font-size: 20px;
                    font-weight: 700;
                }
                .pr-message{
                    font-weight: 400;
                    font-size: 16px;
                }
            }
            .details{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: 6px;
                .reviewers{
                    font-size: 16px;
                }
                .comments{
                    font-size: 16px;
                }
                .size{
                    font-size: 16px;
                    .plus{
                        color: red;
                    }
                    .minus{
                        color: green;
                    }
                }
            }
        }
        .right{
            display: flex;
            flex-direction: column;
            width: 20%;
            height: inherit;
            justify-content: space-between;
            .last-activity{
                margin-top: 7px;
                .title{
                    font-weight: 400;
                    font-size: 16px;
                }
                .days{
                    font-weight: 700;
                    font-size: 16px;
                }
            }
            .approved-by{
                font-weight: 400;
                font-size: 16px;
                margin-bottom: 6px;
            }
        }
    }
    .card-expansion{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border: 2px solid #e5e5e5;
        max-height: 0;
        overflow-y: hidden;
        .reviewers{
            height: 40px;
            width: 100%;
            background-color: #f1f1f1;
            display: flex;
            flex-direction: row;
            .r{
                margin-top: 10px;
                margin-right: 10px;
                font-weight: bold;
                font-size: 18px;
                margin-left: 70px;
            }
            .names{
                margin-top: 10px;
                font-family: 'Overpass';
                font-weight: normal;
                font-size: 18px;
            }
        }
        .bottom{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .message{
                background: none;
                font-weight: bold;
                font-size: 18px;
                margin-top: 10px;
                margin-left: 70px;
            }
            .description-block{
                width: 90%;
                height: 200px auto;
                border: 2px solid #e5e5e5;
                align-self: center;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding-left: 19px;
                padding-top: 10px;
                .h{
                    font-size: 20px;
                    font-weight: bold;
                }
                .description{

                }
            }
            .comments{
                font-weight: bold;
                font-size: 20px;
                margin-left: 70px;
                margin-top: 10px
            }
            .comment{
                width: 90%;
                height: 50px;
                border: 2px solid #e5e5e5;
                align-self: center;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                .commenter{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    width: 33%;
                    margin: auto;
                    .icon{
                        // margin: auto;
                        margin-right: 5px;
                    }
                    .name{
                        // margin: auto
                    }
                }
                .time{
                    width: 33%;
                    margin: auto;
                }
                .view-button{
                    width: 33%;
                    height: 25px;
                    .btn{
                        background: #AAD4C8;
                        border-radius: 10px;
                        width: 140px;
                        height: 25px;
                        margin: auto;
                        label{
                            // margin-bottom: 20px;
                            // line-height: 24.51px;
                        }
                    }
                }

            }
            .approvals{
                font-weight: bold;
                font-size: 20px;
                margin-left: 70px;
                margin-top: 10px
            }
            .approval{
                width: 90%;
                height: 50px;
                border: 2px solid #e5e5e5;
                align-self: center;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                .approver{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    width: 33%;
                    margin: auto;
                    .icon{
                        // margin: auto;
                        margin-right: 5px;
                    }
                    .name{
                        // margin: auto
                    }
                }
                .time{
                    width: 33%;
                    margin: auto;
                }
                .state{
                    width: 33%;
                    .lbl{
                        background: #28AD26;
                        border-radius: 10px;
                        height: 25px;
                        width: 140px;
                        margin: auto;
                        color: white;
                        
                    }
                }

            }
        }
    }
    .slideout, .slidein{
        // opacity: 0;
        // transform:translate(0, -1px);
        // display: none;
        // visibility: hidden;
        // overflow: hidden;
        max-height: 0;
        transition-property: max-height;
        transition-duration: .7s;
	    transition-timing-function: cubic-bezier(.42,0,.58,1);
    }
    .slidein{
        // transform:translate(0, 0px);
        max-height: 500px;
        // transition: max-height 0.7s ease-in;
        transition-property: max-height;
        transition-duration: .7s;
	    transition-timing-function: cubic-bezier(.42,0,.58,1);
        // opacity: 1;
        // display: flex;
        // visibility: visible;
    }
}

</style>
<script>
export default {
    name: 'prcard',
    props: {
        pr: {
            type: Object
        }
    },
    data(){
        let hidden = true;
        return {
            hidden
        }
    },
    mounted(){
        console.log(this.pr)
    },
    methods:{
        show(){
            this.hidden = !this.hidden;
        },
        openPRLink(){
            console.log('pr is opened bro')
        }
    }
}
</script>