<template>
    <div>
        <div ref="tinyGraph"></div>
    </div>
</template>
<style lang="scss" scoped>

</style>
<script>
import Plotly from 'plotly.js/dist/plotly';
export default {
    name: 'tinyGraph',
    props:{
        graphData:{
            required: true
        }
    },
    data(){
        let line1 = {
            y: [16, 25, 35, 24, 15, 31],
            x: ['Jan-02', 'Jan-03', 'Jan-04', 'Jan-05', 'Jan-06', 'Jan-07'],
            // type: 'Scatter',
            mode: 'lines',
            name: 'Dev 1'
        };
        let line2 = {
            y: [10, 12, 16, 14, 23, 43],
            x: ['Jan-02', 'Jan-03', 'Jan-04', 'Jan-05', 'Jan-06', 'Jan-07'],
            // type: 'Scatter',
            mode: 'lines',
            name: 'Dev 2'
        };
        let layout = {
            showlegend: false,
            margin: {
                l: 0,
                r: 0,
                b: 0,
                t: 0,
                pad: 0
            },
            height: 56,
            width:122,
            font: {family: 'Overpass'},
            xaxis:{
                visible: false
            },
            yaxis:{
                visible: false
            }
        };
        let data = [line1, line2];
        return{
            data,
            layout
        }
    },
    mounted(){
        // let lineGraph = this.$refs.tinyGraph;
        //     Plotly.newPlot(lineGraph, this.data, this.layout, {displayModeBar: false, responsive: true, staticPlot: true});
        this.prepareTinyGraph();
    },
    methods:{
        prepareTinyGraph(){
            // // console.log(this.graphData);
            // take the Array
            let months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
            // // console.log('called tiny graph');
            // // console.log(Object.keys(this.graphData));
            let currentObject = this.graphData[Object.keys(this.graphData)[0]]
            // // console.log(currentObject);
            let scatter_graph = currentObject.reduce((accumulator, currentValue) => {
                let temp = currentValue[Object.keys(currentValue)[0]]
                let obj_in = temp.reduce((acc, cVal) => {
                    let d =  new Date(cVal.time);
                    let month = months[d.getMonth()];
                    let date = d.getDate();
                    acc.x.push(`${month} - ${date}`);
                    acc.y.push(cVal.count)
                    return acc;
                },{x:[], y:[]});
                // // console.log(obj_in);
                obj_in.name = Object.keys(currentValue)[0];
                obj_in.mode = 'lines';
                accumulator.push(obj_in);
                return accumulator;
            }, []);
            // // console.log(scatter_graph);
            let lineGraph = this.$refs.tinyGraph;
            Plotly.newPlot(lineGraph, Object.values(scatter_graph), this.layout, {displayModeBar: false, responsive: true, staticPlot: true});
        }
    }
}
</script>