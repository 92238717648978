<template>
    <div class="modal">  
        <div class="outer-box">
            <template v-if="stepOne">
                <div class="header">
                    <label class="title">Select an organisation</label>
                    <div class="close" @click="closeModal()">
                        <label>
                            &times;
                        </label>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="select-card" v-for="(org, index) in orgList" :key="index" @click="selectOrg(org.account.id)">
                        <label for="">
                            {{org.account.login}}
                        </label>
                    </div>
                </div>
            </template>
            <template v-if="stepTwo">
                <div class="header">
                    <label v-if="!this.userSlackState && this.slackState" class="title">Authorize your slack account</label>
                    <label v-if="!this.slackState && !this.userSlackState" class="title">Install Our App to your Slack</label>
                    <label v-if="this.slackState && this.userSlackState" class="title">It's already done!</label>
                    <div class="close" @click="closeModal()">
                        <label>
                            &times;
                        </label>
                    </div>
                </div>
                <div class="modal-body">
                    <button class="btn" v-if="!this.userSlackState && this.slackState" @click="authSlack()">
                        <img src="../assets/slack-logo.svg" height="25px" width="25px" alt="">
                        <label for="">Authorize Your Slack Account</label> 
                    </button>
                    <button class="btn" v-if="!this.slackState && !this.userSlackState" @click="installSlack()">
                        <img src="../assets/slack-logo.svg" height="30px" width="30px" alt="">
                        <label>Add to Slack</label>
                    </button>
                    <label v-if="this.slackState && this.userSlackState" class="message">{{message}}</label>
                </div>
            </template>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    .modal{
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.4);
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
        position: fixed;
        z-index: 200;        
        .outer-box{
            display: flex;
            flex-direction: column;
            width: 25vw;
            height: 55vh;
            margin: auto;
            background: #fefefe;
            justify-content: flex-start;
            align-items: flex-start;
            box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.4);
            padding: 15px;
            border-radius: 5px;
            .header{
                display: flex;
                width: 23.5vw;
                flex-direction: row;
                justify-content: space-between;
                // align-items: center;
                .title{
                    align-self: center;
                    font-size: 3vmin;
                }
                .close{
                    font-size: 35px;
                    position: relative;
                    // width: 10px;
                    cursor: pointer;
                    label{
                        cursor: pointer;
                    }
                }
                .close:hover{
                    font-size: 45px;
                    cursor: pointer;
                }
            }
            .modal-body{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                height: auto;
                margin: auto;
                .select-card{
                    height: 10vh;
                    width: 20vw;
                    cursor: pointer;
                    transition: background-color 0.4s ease-in-out;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    label{
                        margin: auto;
                        font-size: 3vmin;
                    }
                }
                .select-card:hover{
                    background-color: #e5e5e5;
                    transition: background-color 0.4s ease-in-out;
                }
                .btn{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    width: 15vw;
                    height: 8vh;
                    label{
                        font-size: 12px;
                        font-weight: bold;
                    }
                }
            }
        }
    }
</style>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import axios from 'axios';
export default {
    name: 'SlackModal',
    props:{
        orgList:{
            required: true,
            type: Array
        }
    },
    data(){
        let url, state, stepOne = true, stepTwo = false, message;
        return{
            url, state, stepOne, stepTwo, message
        }
    },
    computed: {
        ...mapGetters([
            'getSlackState',
            'getUserSlackState',
        ]),
        ...mapState([
            'userSlackState',
            'slackState'
        ])
    },
    methods:{
        ...mapActions([
            'changeSlackState',
            'setOrganisation',
        ]),
        closeModal(){
            this.$emit('closeModal')
        },
        selectOrg(id){
            // console.log(id);
            let githubUserName;
            if(localStorage.getItem("accessToken") && localStorage.getItem("githubUser")){
            // access_token = localStorage.getItem("accessToken");
            githubUserName = JSON.parse(localStorage.getItem("githubUser")).login;
            }
            axios.get(`${process.env.VUE_APP_BASEURL}/get_organisation`, {
                params: {
                    org_id: id
                }
            }).then((response) => {
                // console.log(response);
                this.changeSlackState(response.data.response.has_slack);
                this.setOrganisation(response.data.response)
                // console.log(this.getUserSlackState)
                this.stepTwo = true;
                this.stepOne = false;
                console.log(this.userSlackState);
                console.log(this.slackState);
                if(!this.slackState){ // true if false
                    console.log('Install the app');
                    this.state = id + "&type=install" + `&username=${githubUserName}`;
                    this.url = `${process.env.VUE_APP_BASEURL}/auth/manual/slack?state=${this.state}`;
                    // console.log('slack not installed' + '  ' + this.url)

                }else if(!this.userSlackState){ // true if false
                    console.log('App is installed, auth your account');
                    this.state = id + "&type=identity" + `&username=${githubUserName}`;
                    this.url = `${process.env.VUE_APP_BASEURL}/auth/manual/slack?state=${this.state}`;
                }
                else{
                    console.log('installed and auth');
                    this.message = 'You have already authorized your Slack Account';
                }
            }).catch((error) => {
                // console.log(error)
            })
        },
        authSlack(){
            window.location = this.url;
        },
        installSlack(){
            window.location = this.url;
        }
    },
}
</script>