<template>
    <div v-if="gotInstallation">
        <div class="container">
            <div class="orglist" >
                <h3 class="minheader">CHOOSE ACCOUNT</h3>
                <br/>
                <OrganisationList v-for="item in orgData" :key="item.account.id" v-bind:item="item" v-bind:link="item.account.id"/>
            </div>
        </div>
    </div> 
</template>

<script>
import InstallApp from '@/components/InstallApp.vue';
import OrganisationList from '@/components/OrganisationList.vue';
import InstallsHeader from '@/components/InstallsHeader.vue';
import Header from '@/components/Header.vue';
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
    name: 'install-home',
    components: {
        InstallApp,
        OrganisationList,
        InstallsHeader,
        Header
    },
    computed: {
        ...mapState([
            'gotInstallation',
            'installations',
            'isAccountsPage'
        ]),
        ...mapGetters([
            'getSlackState'
        ])
    },
    methods: {
        ...mapActions([
            'getInstallations'
        ])
    },
    data(){
        let hasInstallations = false;
        let orgData = [];
        // let isAccountsPage;
        return {
            hasInstallations,
            orgData,
            // isAccountsPage
        }
    },
    created(){
        // console.log('install')
        const token = localStorage.getItem('accessToken');
        
        this.getInstallations(token).then(() => {
            if(this.gotInstallation){
                //display orgs
                this.hasInstallations = true;
                this.orgData = this.installations;
                // console.log(this.orgData);
                let githubUserName;
                if(localStorage.getItem("githubUser")) {
                    githubUserName = JSON.parse(localStorage.getItem("githubUser")).login
                }
                // this.slackIsInstalled = this.getSlackState;
                // if(this.slackIsInstalled == false){
                //     // redirect to slack installation page external
                //     let id = this.$route.params.id;
                //     let state = id + "&type=identity" + `&username=${githubUserName}`;
                //     let url = `${process.env.VUE_APP_BASEURL}/auth/manual/slack?state=${state}`;
                //     window.location = url
                // }
            }
            else{
                // console.log('pushing');
                // console.log(this.gotInstallation);
                this.$router.replace({ name: 'install-organisation' })
            }
        })
        
    },
};
</script>
<style lang="scss" scoped>
.container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .orglist{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        h3 {
            color: white;
        }
    }
}
</style>