<template>
    <div class="whole">
        <div class="page-header">
            <label>Worklog</label>
        </div>
        <hr />
        <div class="filters-row">
            <template>
                <fish-select search multiple v-model="selected_teams" style="width: 150px; height: auto;" hint="Teams">
                    <fish-option v-for="(team, index) in team_options" :key="index" :index="team.team_id" :content="team.team_name"></fish-option>
                </fish-select>
            </template>
            <template>
                <fish-select search multiple v-model="selected_repos" style="width: 150px; height: auto;" hint="Repos">
                    <fish-option v-for="(repo, index) in repo_options" :key="index" :index="repo.id" :content="repo.name"></fish-option>
                </fish-select>
            </template>
            <template>
                <fish-select v-model="time_period" style="width: 150px; height: auto;" hint="Time Period">
                    <fish-option index="7d" content="7 Days"></fish-option>
                    <fish-option index="14d" content="14 Days"></fish-option>
                    <fish-option index="30d" content="1 Month"></fish-option>
                </fish-select>
            </template>
            <!-- <template>
                <fish-select search multiple v-model="selected_repos" style="width: 150px; height: auto;" hint="Weekends">
                    <fish-option v-for="(repo, index) in repo_options" :key="index" :index="repo.name" :content="repo.name"></fish-option>
                </fish-select>
            </template> -->
            <template>
                <fish-button @click="applyFilters()">Apply</fish-button>
            </template>
        </div>
        <div class="secondary-header">
            <label>Daily Activity</label>
        </div>
        <div class="secondary-filters">
             <template>
                <fish-select search multiple v-model="selected_teams" style="width: 150px; height: auto;" hint="Teams">
                    <fish-option v-for="(team, index) in team_options" :key="index" :index="team.team_id" :content="team.team_name"></fish-option>
                </fish-select>
            </template>
            <template>
                <fish-select search multiple v-model="selected_repos" style="width: 150px; height: auto;" hint="Repos">
                    <fish-option v-for="(repo, index) in repo_options" :key="index" :index="repo.id" :content="repo.name"></fish-option>
                </fish-select>
            </template>
        </div>
        <div class="legends">
            <div class="legend">
                <div class="square"></div>
                <label for="">Commit</label>
            </div>
            <div class="legend">
                <div class="square red"></div>
                <label for="">Merge Commit</label>
            </div>
            <div class="legend">
                <div class="circle blue"></div>
                <label for="">PRs Opened</label>
            </div>
            <div class="legend">
                <div class="circle"></div>
                <label for="">PRs Approved</label>
            </div>
            <div class="legend">
                <img src="../assets/triangle.svg" alt="hoo">
                <label for="">PRs Comments</label>
            </div>
        </div>
        <div class="main-table">
             <div class="table-border">
                 <div class="table-rows" v-for="(row, index) in author_data" :key="index">
                    <div class="name">{{row.name}}</div>
                    <div class="table-columns" v-for="(col, inner_index) in row.data" :key="inner_index">
                        <div class="date">{{col.date | format}}</div>
                        <div class="elems">
                            <CanvasComponent :event_data="col.events" :id="`${row.name} + ${inner_index}`"/>
                        </div>
                    </div>
                 </div>
             </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.whole{
    margin-top: 40px;
    height: 100%;
    width: 98%;
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .page-header{
        display: flex;
        flex-direction: row;
        width: inherit;
        justify-content: space-between;
        align-items: center;
        label{
            font-size: 50px;
            font-weight: bold;
            height: auto;
            color: #190845;
        }
    }
    .filters-row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: inherit;
        align-items: baseline;
        margin-bottom: 20px;
    }
    .secondary-header{
        font-family: Overpass;
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 61px;

        color: #FF5B64;
    }
    .secondary-filters{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: inherit;
        align-items: baseline;
        margin-bottom: 20px;
    }
    .legends{
        display: flex;
        flex-direction: row;
        width: 50%;
        justify-content: space-between;
        align-items: center;
        margin-left: 5px;
        .legend{
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .square{
            border: 2px solid #FF5B64;
            box-sizing: border-box;
            transform: rotate(-45deg);
            width: 15px;
            height: 15px;
        }
        .red{
            background-color: #FF5B64;
        }
        .circle{
            border: 2px solid #8753DE;
            border-radius: 50%;
            box-sizing: border-box;
            width: 15px;
            height: 15px;
        }
        .blue{
            background-color: #190845 !important;
            border: 1px solid #190845 !important;
        }
        .triangle{
            background-color: #AAD4C8;
            border: 1px solid #AAD4C8;
            box-sizing: border-box;
        }
    }
    .main-table{
        width: 100%;
        height: 100%;
        // background-color: #8753DE;
        .table-border{
            .table-rows{
                display: flex;
                flex-direction: row;
                width: 100%;
                height: 100%;
                justify-content: flex-start;
                align-items: center;
                .name{
                    // width: 222px;
                    width: 20%;
                }
                .table-columns{
                    display: flex;
                    flex-direction: column;
                    // width: 119px;
                    // width: 80%;
                    justify-content: flex-start;
                    align-items: center;
                    border: 1px solid gray;
                    .elems{
                        // width: 13%;
                        height: 92px;
                    }
                }
                // .table-columns .date:nth-child(2){
                //     background: red;
                // }
            }
        }
    }
}
</style>
<script>
import axios from 'axios';
import PRService from '@/services/prService.js';
import CanvasComponent from '@/components/CanvasComponent';
export default {
    name: 'DailyActivity',
    components:{
        CanvasComponent
    },
    data(){
        let selected_repos = [], repo_options = [], selected_teams = [], team_options = [], author_data = [], time_period
        return{
            selected_repos, repo_options, selected_teams, team_options, author_data, time_period
        }
    },
    methods: {
        getTeams(){
            axios.get(`${process.env.VUE_APP_BASEURL}/get_teams`,{
                params:{
                    org_id: this.$route.params.id
                }
            }).then((response) => {
                this.team_options = response.data
            })
        },
        getRepos(){
            axios.get(`${process.env.VUE_APP_BASEURL}/get_org_repos`,{
                params:{
                    org_id: this.$route.params.id
                }
            }).then((response) => {
                //   // console.log(response.data)
                this.repo_options = response.data
            })
        },
        async getActivity(){
            return await PRService.getDummyDailyActivity();
        },
        getActivityData(){
            this.getActivity().then((response) => {
                console.log(response.data);
                this.author_data = response.data;
            })
        },
    },
    created(){
        this.getRepos();
        this.getTeams();
        this.getActivityData();
    },
    filters:{
        format: function(value){
            let months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
            let days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

            let d = new Date(value);
            let month = months[d.getMonth()];
            let day = days[d.getDay()];
            let date = d.getDate();
            return `${date} ${day} ${month}`
        }
    }
}
</script>