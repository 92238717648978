<template>
    <div class="whole">
        <div class="page-header">
          <label>Workload</label>
          <!-- <SearchBox /> -->
        </div>
        <hr />
        <!-- FILTERS -->
        <div class="filters-row">
            <template>
                <fish-select search multiple v-model="selected_teams" style="width: 150px; height: auto;" hint="Teams">
                    <fish-option v-for="(team, index) in team_options" :key="index" :index="team.team_id" :content="team.team_name"></fish-option>
                </fish-select>
            </template>
            <template>
                <fish-select search multiple v-model="selected_repos" style="width: 150px; height: auto;" hint="Repos">
                    <fish-option v-for="(repo, index) in repo_options" :key="index" :index="repo.id" :content="repo.name"></fish-option>
                </fish-select>
            </template>
            <template>
                <fish-select v-model="time_period" style="width: 150px; height: auto;" hint="Time Period">
                    <fish-option index="7d" content="7 Days"></fish-option>
                    <fish-option index="14d" content="14 Days"></fish-option>
                    <fish-option index="30d" content="1 Month"></fish-option>
                </fish-select>
            </template>
            <template>
                <fish-button @click="applyFilters()">Apply</fish-button>
            </template>
        </div>
        <div class="outer-box" v-if="noData">
            <div class="graph-column">
                <div class="view-selector">
                   <fish-select v-model="selectedMetric" @change="changeView()">
                        <fish-option index="commit-volume" content="Commit Volume"></fish-option>
                        <fish-option index="code-volume" content="Code Volume"></fish-option>
                    </fish-select> 
                </div>
                <div class="graph">
                    <CommitBarLine v-if="dataLoaded && selectedMetric == 'commit-volume'" :graphData="commitVolumeData"/>
                    <CodeBarLine v-if="selectedMetric == 'code-volume'" :graphData="codeVolumeData" :key="componentKey"/>
                </div>
            </div>
            <template v-if="commitVolumeMetrics">
                <div class="stats-column">
                    <div class="stat">
                        <div class="top-label">{{commitVolumeMetrics.total_commits}}</div>
                        <div class="bottom-label">Total PRs Opened</div>
                    </div>
                    <div class="stat white">
                        <div class="top-label">{{commitVolumeMetrics.avg_commits}}</div>
                        <div class="bottom-label">Avg. PRs Opened</div>
                    </div>
                    <div class="stat">
                        <div class="top-label">{{commitVolumeMetrics.trend.sign}}{{commitVolumeMetrics.trend.value}}%</div>
                        <div class="bottom-label">Trend</div>
                    </div>
                    <div class="stat white">
                        <div class="top-label">{{commitVolumeMetrics.minima}}</div>
                        <div class="bottom-label">Minima</div>
                    </div>
                    <div class="stat">
                        <div class="top-label">{{commitVolumeMetrics.maxima}}</div>
                        <div class="bottom-label">Maxima</div>
                    </div>
                </div>
            </template>
        </div>
        <div v-else class="message">
            We need atleast 3 days worth of data for the graphs to give something meaningful to you
        </div>
        <!-- <Modal v-if="showModal" :graphData="selectedUserGraph" :metricsData="selectedUserMetrics" @closeModal="showModal = false" /> -->
        <template v-if="selectedMetric == 'commit-volume'">
            <div class="contributors">
                <div class="contributors-header">
                    <div class="label">
                        <label>Contributors</label>    
                    </div>      
                </div>
                <template v-if="contributorCodeVolume.length != 0">
                    <div class="contributors-class" >
                        <div v-for="(graph, index) in contributorCommitVolume" :key="index" class="block" @click="showFullGraph(graph)">
                            <div class="up">
                                <!-- <div class="label-name">{{Object.keys(graph)[0]}}</div> TEMPORARY COMMENT -->
                                <!-- <div class="label-count">10</div> -->
                                <div class="label-name">
                                    {{Object.keys(graph)[0]}}
                                </div>
                            </div>
                            <div class="down">
                                <TinyCommitBarLine v-if="loaded" :graphData="graph"/>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="message small">
                        There are no new contributors.
                    </div>
                </template>
            </div>
        </template>
        <template v-else>
            <div class="contributors">
                <div class="contributors-header">
                    <div class="label">
                        <label>Teams</label>    
                    </div>      
                </div>
                <template v-if="contributorCodeVolume.length != 0">
                    <div class="contributors-class" >
                        <div v-for="(graph, index) in contributorCodeVolume" :key="index" class="block" @click="showFullGraph(graph)">
                            <div class="up">
                                <div class="label-name">
                                    {{Object.keys(graph)[0]}}
                                </div>
                                <!-- <div class="label-count">10</div> -->
                            </div>
                            <div class="down" >
                                <TinyCodeBarLine :graphData="graph"/>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="message small">
                        There are no new contributors.
                    </div>
                </template>
            </div>
        </template>       
    </div>
</template>
<style lang="scss" scoped>
.whole{
    // margin: 40px 30px 20px 30px;
    margin-top: 40px;
    height: 100%;
    width: 98%;
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .page-header{
        display: flex;
        flex-direction: row;
        width: inherit;
        justify-content: space-between;
        align-items: center;
        label{
            font-size: 50px;
            font-weight: bold;
            height: auto;
            color: #190845;
        }
    }
    .filters-row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: inherit;
        align-items: baseline;
        margin-bottom: 20px;
    }
    .outer-box{
        display: flex;
        flex-direction: row;
        border: 3px solid #e5e5e5;
        width: inherit;
        height: 55vh;
        z-index: 1;
        // position: absolute;
        .graph-column{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: inherit;
            height: 80%;
            .graph{
                margin: auto;
                height: 80%;
            }
            .selector{
                // height: 20%;
            }
            .view-selector{
                width: 200px;
                height: 20%;
            }
        }
        .stats-column{
            display: flex;
            flex-direction: column;
            height: inherit;
            width: 20%;
            .stat{
                display: flex;
                flex-direction: column;
                height: 20%;
                width: 100%;
                justify-content: flex-end;
                align-items: center;
                background: rgba(170, 212, 200, 0.5);
                color: #190845;
                .top-label{
                    font-size: 40px;
                    font-weight: bold;
                    line-height: 20px;
                    font-family: Overpass;
                }
                .bottom-label{
                    font-size: 18px;
                    font-weight: normal;
                    line-height: 28px;
                    font-family: Overpass;
                }
            }
            .white{
                background: white;;
            }
        }
    }
    .contributors{
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        width: inherit;
        align-items: flex-start;
        .contributors-header{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: inherit;
            .label{
                font-family: Overpass;
                font-style: normal;
                font-weight: bold;
                font-size: 40px;
                line-height: 61px;
                color: #190845;
            }
        }
        .contributors-class{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: inherit;
            justify-content: flex-start;
            align-items: flex-end;
            border: 2px solid #e5e5e5;
            .block{
                width: 163px;
                height: 130px;
                border-left: 2px solid #e5e5e5;
                border-bottom: 2px solid #e5e5e5;
                border-right: 2px solid #e5e5e5;
                margin-top: 1.5rem;
                cursor: pointer;
                .up{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: flex-start;
                    .label-name{
                        font-size: 20px;
                    }
                    .label-count{
                        background: #AAD4C8;
                        width: 60px;
                        // border: 1px solid red;
                        border-radius: 10px;
                    }
                }
                .down{
                    
                }
            }
            .block:last-child{
                margin-bottom: 1.5rem;
            }
        }
    }
    .message{
        font-size: 50px;
        height: 80px;
        line-height: 80px;
        background: none;
        margin-top: 10vh;
        margin-bottom: 10vh;
    }
    .small{
        font-size: 25px;
        margin-top: 0;
    }
    
}
</style>
<script>
import CommitBarLine from '@/components/CommitBarLine';
import CodeBarLine from '@/components/CodeBarLine';
import TinyCodeBarLine from '@/components/TinyCodeBarLine';
import TinyCommitBarLine from '@/components/TinyCommitBarLine';
import Modal from '@/components/Modal';
import Vue from 'vue';
import FishUI from 'fish-ui';
import PRService from '@/services/prService';
import axios from 'axios';
Vue.use(FishUI);
export default {
    name: 'workload',
    components:{
        CommitBarLine, Modal, CodeBarLine, TinyCodeBarLine, TinyCommitBarLine
    },
    data(){
        let team_options = [];
        let repo_options = [];
        let selected_repos = [];
        let selected_teams = [];
        let time_period;
        let dataLoaded = false;
        let loaded = false;
        let graphData = [];
        let graphType = 'throughput';
        let metricsData;
        let cycleMetricsData;
        let rttMetricsData;
        let arr = [1,2,3,4,5,6,7,8,9,10];
        let tinyGraphArray = [];
        let componentKey = 0;
        let showModal = false;
        let selectedUserGraph = [];
        let selectedUserMetrics;
        let noData = true;
        let selectedMetric =  'commit-volume';
        let rttData = [];
        let commitVolumeData = [], commitVolumeMetrics, codeVolumeData = [];
        let contributorCodeVolume = [], contributorCommitVolume = [];
        return{
            team_options, repo_options, selected_repos, selected_teams, time_period, graphType, graphData, dataLoaded,
            metricsData, rttMetricsData, cycleMetricsData, arr, tinyGraphArray, loaded, componentKey,
            showModal, selectedUserGraph, selectedUserMetrics, noData, selectedMetric, rttData, commitVolumeData, commitVolumeMetrics,
            codeVolumeData, contributorCodeVolume, contributorCommitVolume
        }
    },
    methods:{
        getTeams(){
            axios.get(`${process.env.VUE_APP_BASEURL}/get_teams`,{
                params:{
                    org_id: this.$route.params.id
                }
            }).then((response) => {
                this.team_options = response.data
            })
        },
        getRepos(){
            axios.get(`${process.env.VUE_APP_BASEURL}/get_org_repos`,{
                params:{
                    org_id: this.$route.params.id
                }
            }).then((response) => {
                //   // console.log(response.data)
                this.repo_options = response.data
            })
        },
        changeView(){
            console.log(this.selectedMetric);
            if(this.selectedMetric == 'commit-volume'){
                // this.setRTTGraph();
                this.getDummyCodeVolume();
                this.getDummyCommitVolumeContributor().then((response) => {
                    this.contributorCommitVolume = response.data;
                })
            } else if(this.selectedMetric == 'code-volume'){
                // this.getThroughput();
                this.getDummyCodeVolume();
                this.getDummyCodeVolumeContributor().then((response) => {
                    this.contributorCodeVolume = response.data;
                })
                this.componentKey = !this.componentKey
            }
        },
        async getCommitVolume(){
            return await PRService.getDummyCommitVolume();
        },
        async getCodeVolume(){
            return await PRService.getDummyCodeVolume();
        },
        async getDummyCommitVolumeContributor(){
            return await PRService.getDummyCommitVolumeContributor();
        },
        async getDummyCodeVolumeContributor(){
            return await PRService.getDummyCodeVolumeContributor();
        },
        getDummyCommitVolume(){
            this.getCommitVolume().then((response) => {
                console.log(response.data);
                this.commitVolumeData = response.data.graph;
                this.commitVolumeMetrics = response.data.metrics;
                this.dataLoaded = true;
            })
        },
        getDummyCodeVolume(){
            this.getCodeVolume().then((response) => {
                console.log(response.data);
                this.codeVolumeData = response.data;
            })
        }
    },
    created(){
        this.getDummyCommitVolume();
        this.getDummyCommitVolumeContributor().then((response) => {
            this.contributorCommitVolume = response.data;
        })
        // this.getDummyCodeVolume();
        this.getRepos();
        this.getTeams();
    },
    watch:{
        commitVolumeData: function(){
            if(this.commitVolumeData.length > 0){
                this.dataLoaded = true;
            }
        },
        contributorCommitVolume: function(){
            if(this.contributorCommitVolume.length > 0){
                this.loaded = true;
            }
        }
    }

}
</script>