<template>
    <div>
        <div ref="codeBarChart"></div>
    </div>
</template>
<style lang="scss" scoped>

</style>
<script>
import Plotly from 'plotly.js/dist/plotly';
export default {
    name: 'TinyCodeBarLine',
    props: {
        graphData: {
            required: true
        }
    },
    data(){
        var layout = {
            showlegend: false,
            margin: {
                l: 0,
                r: 0,
                b: 0,
                t: 0,
                pad: 0
            },
            height: 56,
            width:122,
            font: {family: 'Overpass'},
            barmode: 'stack', 
            legend: {orientation: "h", traceorder: "normal"},
            xaxis:{
                visible: false
            },
            yaxis:{
                visible: false
            }
        };

        return{
            layout
        }
    },
    mounted(){
        this.prepareGraph();
    },
    methods:{
        prepareGraph(){
            let finalData = this.sortData(Object.values(this.graphData)[0]);
            console.log(finalData)
            
            let lineGraph = this.$refs.codeBarChart;
            Plotly.newPlot(lineGraph, Object.values(finalData), this.layout, {displayModeBar: false, responsive: true, staticPlot: true});
        },
        sortData(data){
            console.log(data);
            let months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

            let barUp = {
                x: [],
                y: [], //date
                type: 'bar',
                name: 'New LOC',
                marker: {color: '#AAD4C8'},
                width: []
            }

            let barDown = {
                x: [],
                y: [],
                type: 'bar',
                name: 'Churn',
                marker: {color: '#FF5B64'},
                width: []
            };
            
            data.forEach((elem) => {
                console.log(elem);
                let d =  new Date(elem.date);
                let month = months[d.getMonth()];
                let date = d.getDate();
                barUp.x.push(`${date}-${month}`);
                barUp.y.push(elem.new_loc);
                barDown.x.push(`${date}-${month}`)
                barDown.y.push(elem.churn);
                barUp.width.push(0.3);
                barDown.width.push(0.3)
            })
             return [barUp, barDown]

        }
    }
}
</script>