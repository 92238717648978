<template>
<div class="sectionHome">
  <div class="container">
    <h2><span style="background-color: #202124">Getting started</span></h2>
    <div v-if="!this.getSlackState && !this.getUserSlackState">
        <a :href="url">Install Slack</a>
    </div>
     <div v-if="this.getSlackState && this.getUserSlackState" class="inner">
      <nav-list-item label="Team Reminders" linkTo= "notification-rules"/>
      <nav-list-item label="Personal Reminders" linkTo="personal-settings"/>
      <nav-list-item label="Add Users" linkTo="add-users"/>
    </div>
    <div v-if="!this.getUserSlackState && this.getSlackState">
        <a :href="url">Authorize your slack account</a>
    </div>
   
    
    
  </div>
</div>
</template>

<script>
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import store from '../store';
import NavListItem from '../components/NavListItem.vue';
export default {
    name: 'gettingstartedpage',
    components: {
        NavListItem
    },
    data() {
        let id;
        let state;
        let url;
        return {
            id,
            state,
            url
        }
    },
    methods: {
        ...mapActions([
            'changeSlackState',
            'setOrganisation',
        ]),
    },
    computed: {
        ...mapGetters([
            'getSlackState',
            'getUserSlackState',
        ])
    },
    created() {
        this.id = this.$route.params.id
        // let access_token;
        let githubUserName;
        if(localStorage.getItem("accessToken") && localStorage.getItem("githubUser")){
            // access_token = localStorage.getItem("accessToken");
            githubUserName = JSON.parse(localStorage.getItem("githubUser")).login;
        }
        axios.get(`${process.env.VUE_APP_BASEURL}/get_organisation`, {
            params: {
                org_id: this.id
            }
        }).then((response) => {
            // console.log(response);
            this.changeSlackState(response.data.response.has_slack);
            this.setOrganisation(response.data.response)
            // console.log(this.getUserSlackState)
            if(!this.getSlackState){ // true if false
                this.state = this.id + "&type=install" + `&username=${githubUserName}`;
                this.url = `${process.env.VUE_APP_BASEURL}/auth/manual/slack?state=${this.state}`;
                // console.log('slack not installed' + '  ' + this.url)
                // if(response.data.response.has_slack) {
                
                // } else {
                    
                // }
            }else if(!this.getUserSlackState){ // true if false
                this.state = this.id + "&type=identity" + `&username=${githubUserName}`;
                this.url = `${process.env.VUE_APP_BASEURL}/auth/manual/slack?state=${this.state}`;
            }
        }).catch((error) => {
            // console.log(error)
        })
    
    },
};
</script>

<style lang="scss" scoped>
$primary: #202124;
.sectionHome {
    max-width: 45em;
    margin: 0 auto;
    font-family: 'Raleway';
    padding-top: 30px;
}

.container {
    max-width: 58em;
    margin: 0 1em;
    position: relative;
}

.container {
    h2 {
        span{
            background-color: $primary;
            color: white;
        }
    }
}

.sectionHome h2 {
    font-weight: normal;
    position: relative;
    font-size: 1.25em;
    z-index: 0;
    margin: 1em 0;
    text-align: center;
}

h1, h2, h3, h4, h5 {
    letter-spacing: -0.005em;
}

h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.sectionHome h2:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    z-index: -1;
}

.sectionHome h2 span {
    display: inline-block;
    padding: 0 0.75em;
    background: #F4F7FA;
}

.sectionHome .inner {
    width: 400px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    height: 350px;
    justify-content: space-between;
}

.box {
    background: white;
    margin: 0 auto 1em auto;
    border-radius: 4px;
    box-shadow: 0 7px 14px 0 rgba(60,66,87,0.1), 0 3px 6px 0 rgba(0,0,0,0.07);
}

.box .itemList .listItem {
    font-size: 1.15em;
    padding: 1.1em 1.15rem;
    background-color: white;
    box-shadow: inset 0 -1px #eee;
    position: relative;
    text-decoration: none;
    color: #333;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.box .itemList .listItem strong {
    font-weight: 700;
}

b, strong {
    font-weight: bolder;
}

.box .itemList .listItem::after {
    content: "›";
    position: absolute;
    top: 50%;
    right: 1em;
    color: #aaa;
    font-size: 1.5em;
    font-weight: normal;
    line-height: 1rem;
    margin-top: -0.5em;
}
</style>
