import Api from '@/services/api';

export default {
    getReviewerList(params){
        return Api().get('/reviewerLeaderboard', {
            params: {
                org_id: params
            }
        })
    },
    getAuthorList(params){
        return Api().get('/authorLeaderboard', {
            params: {
                org_id: params
            }
        })
    },
    applyFilters(params){
        return Api().post('/filterLeaderboard',{
            teams: params.selected_teams,
            repos: params.selected_repos,
            org_id: params.org_id,
            leaderboard: params.leaderboard
        })
    },
    getLeaderboardGraph(org_id){
        return Api().post('/leaderboardGraph', {
            org_id: org_id
        })
    }
}