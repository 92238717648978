<template>
  <div class="hello">
    <button class="btn btn-green" @click="gotourl">Log In with Github</button>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String,
  },
  data(){
    // console.log(process.env.VUE_APP_BASEURL)
    let url = `${process.env.VUE_APP_BASEURL}/auth/github?redirect_uri=${window.location}`
    return {
      url
    }
  },
  methods: {
    gotourl: function(){
      window.location = this.url;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.btn.btn-green {
  color: #fff;
  background-color: #805baf;
  background-image: linear-gradient(-180deg, #805baf 0%, #805baf 90%);
  border: 1px solid rgba(27,31,35,0.2);
  width: 100%;
}
</style>
