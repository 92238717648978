<template>
    <div class="new-search-box">
        <input type="text" class="search-input" placeholder="Type Here" />
        <div class="search-btn">
            <i class="material-icons">search</i>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.new-search-box{
    display: flex;
    flex-direction: row;
    .search-input{
        border: 1px solid black;
        border-radius: 25px 0 0 25px;
        padding: 5px;
        width: 325px;
    }
    .search-btn{
        background: #190845;
        width: 75px;
        margin-left: -15px;
        border-radius: 25px;// 10px 10px 0;
        cursor: pointer;
        .material-icons{
            color: white;
            font-size: 16px;
            margin: 4px;
        }
    }

}


*{
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
}
body{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
}

#click {
    display: none;
}

.main{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.main label{
    position: absolute;
    background: rgb(30, 166, 245);
    height: 50px;
    width: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    transition: .6s;
}

span:nth-child(1){
    display: flex;
    position: absolute;
    top: 11px;
    left: 11px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 4px solid #fff;
    transition: .4s;
}

span:nth-child(2),
span:nth-child(3){
    display: flex;
    position: absolute;
    top: 31px;
    left: 26px;
    width: 12px;
    height: 4px;
    border-radius: 3px;
    background: #fff;
    transform: rotate(45deg);
    transition: .5s;
}
.main #search{
    position: absolute;
    height: 50px;
    width: 50px;
    font-size: 22px;
    padding: 0 20px;
    background: #ffffff;
    border-radius: 50px;
    color:rgb(22, 123, 182);
    border: 2px solid rgb(30, 166, 245);
    transition: .6s;
}

#click:checked ~ .main #search {
    width: 300px;
}
#click:checked ~ .main label{
    margin-left: 125px;
}
#click:checked ~ .main span:nth-child(1){
    opacity: 0;
}
#click:checked ~ .main span:nth-child(2),
#click:checked ~ .main span:nth-child(3){
    top: 22px;
    left: 15px;
    width: 20px;
    height: 5px;
}
#click:checked ~ .main #search{
    box-shadow: 0 0 40px rgb(90, 217, 255);   
}
#click:checked ~ .main span:nth-child(3){
    transform: rotate(-45deg);
}
</style>
<script>
export default {
    name: 'SearchBox'
}
</script>