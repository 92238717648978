<template>
    <div class="box">
        <h3>Connect To Github</h3>
        <p>Install our application to your github organisation</p>
        <a v-bind:href="url">Install App</a>
    </div>
</template>

<script>

export default {
    name: 'InstallApp',
    data(){
        let url = `https://github.com/apps/go-hedwig/installations/new`;
        return{
            url,
        }
    }
    
}
</script>
<style lang="scss" scoped>
div{
    background: none;
    border: none;
    color: white;
}
</style>