<template>
    <div>
         <div ref="sankeyChart"></div>
    </div>
</template>
<style lang="scss" scoped>

</style>
<script>
import Plotly from 'plotly.js/dist/plotly';
export default {
    name: 'sankeychart',
    data(){
        let trace = {
            type: "sankey",
            orientation: "h",
            node: {
                pad: 1,
                thickness: 30,
                line: {
                color: "black",
                width: 0.5
                },
                label: ["Pushed", "Open", "Reviewed", "Approved", "Merged", "Closed", "Abandoned"],
                color: ["blue", "red", "green", "purple", "cyan", "orange", "black"]
                },

            link: {
                source: [0, 0, 1, 1, 1, 2, 2, 3, 3],
                target: [1, 6, 2, 5, 6, 3, 6, 4, 6],
                value:  [8, 2, 4, 2, 2, 3, 1, 2, 1]
            }
        }
        

        let layout = {
            title: "Commit to Merge Flow",
            font: {
                size: 10
            },
            height: 340,
            width: 460,
        }
        let data = [trace]
        return {
            data, layout
        }
    },
    mounted(){
        let graph = this.$refs.sankeyChart;
        Plotly.newPlot(graph, this.data, this.layout, {displayModeBar: false, responsive: true, staticPlot: true});
    }
}
</script>
