<template>
    <section id="header">
        <div class="container">
            <div class="logo collapsible_content">
                <button class="expand_content load_accounts" href="/installs/54403222">
                    <img src="https://avatars1.githubusercontent.com/u/54403222?v=4">
                </button>
            </div>
            <div class="user">
                <div class="collapsible_content usr">
                    <button class="expand_content">
                        <div class="avatar">
                            <img src="https://avatars3.githubusercontent.com/u/28856319?v=4">
                        </div>
                    </button>
                    <button @click="logout" class="logout-btn">Log out</button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    name: 'installsHeader',
    methods: {
        ...mapActions([
            'logout'
        ])
    },
}
</script>

<style scoped>
#header {
    padding-top: 0.65em;
    margin-bottom: 1em;
}

#header .container {
    display: flex;
    align-items: center;
    max-width: 98%;
    justify-content: space-between;
}

#header .logo {
    flex: 0 0 4.5em;
}

.collapsible_content {
    position: relative;
}

.usr {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100px;
}

#header .logo .expand_content {
    display: flex;
    align-items: center;
}

.collapsible_content .expand_content {
    cursor: pointer;
    background: none;
    padding: 0;
    border: none;
    outline: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
    -webkit-appearance: button;
}

button, select {
    text-transform: none;
}

button, input {
    overflow: visible;
}

button, input, optgroup, select, textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}

#header .logo img {
    background: #fff;
    height: 1.75em;
    width: 1.75em;
    border-radius: 3px;
    margin-right: 0.45em;
    box-shadow: 0 2px 5px 0 rgba(60,66,87,0.1), 0 1px 1px 0 rgba(0,0,0,0.07);
}

#header .logo.expanded img, #header .logo.expanded .expand_content:after {
    opacity: 0.75;
}

#header .logo .expand_content:after {
    content: "";
    border: solid #666;
    border-width: 0 1.5px 1.5px 0;
    display: inline-block;
    transform: rotate(45deg);
    vertical-align: middle;
    margin-top: -0.125em;
    width: 0.25em;
    height: 0.25em;
}

img {
    border-style: none;
}

.collapsible_content .expanded_content.position-left {
    left: -6px;
}

.collapsible_content .expanded_content {
    position: absolute;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2), 0 5px 10px rgba(0,0,0,0.05);
    background: white;
    margin-top: 10px;
    top: 100%;
    z-index: 5;
    display: none;
}

#header .accountDropdown {
    min-height: 3em;
    padding: 1.5em;
    width: 15em;
    position: relative;
    text-align: left;
}

#header .accountDropdown h3 {
    position: relative;
    font-size: 1em;
    margin: 0;
    z-index: 0;
    margin-bottom: 1em;
}

#header .accountDropdown h3 span {
    display: inline-block;
    padding: 0 0.5em;
    padding-left: 0;
    background: #fff;
}

#header .accountDropdown ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}

#header .accountDropdown .btn {
    margin-top: 1.5em;
    width: 100%;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
}

.btn.btn-grey {
    color: #24292e;
    background-color: #eff3f6;
    background-image: linear-gradient(-180deg, #fafbfc 0%, #eff3f6 90%);
    border: 1px solid rgba(27,31,35,0.2);
}

.btn {
    border-radius: 0.25em;
    display: inline-block;
    font-size: 0.95em;
    font-weight: 700;
    padding: 0.6em 1em;
    text-decoration: none;
    cursor: pointer;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.18);
}

#header .nav {
    text-align: center;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
}


.collapsible_content {
    position: relative;
}


.collapsible_content .expand_content {
    cursor: pointer;
    background: none;
    padding: 0;
    border: none;
    outline: none;
}

.collapsible_content .expanded_content.position-center.width-280 {
    width: 280px;
    margin-left: -140px;
}

.collapsible_content .expanded_content.position-center {
    left: 50%;
}

.collapsible_content .expanded_content {
    position: absolute;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2), 0 5px 10px rgba(0,0,0,0.05);
    background: white;
    margin-top: 10px;
    top: 100%;
    z-index: 5;
    display: none;
}

#header .nav .subnav {
    text-align: left;
    padding: 1em 2em;
}

#header .nav .subnav .subnav-link {
    margin: 1em 0;
}

#header .nav .subnav a {
    text-decoration: none;
    color: #333;
}

a {
    color: #0366d6;
}

a {
    background-color: transparent;
}

#header .user {
    flex: 0 0 4.5em;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.logout-btn {
    background: none;
    border: 0px;
    color: #0366d6;
}
.collapsible_content .expanded_content.position-right {
    right: -6px;
}

.collapsible_content .expanded_content {
    position: absolute;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2), 0 5px 10px rgba(0,0,0,0.05);
    background: white;
    margin-top: 10px;
    top: 100%;
    z-index: 5;
    display: flex;
    flex-direction: row;
}

#header .accountDropdown.skinny {
    width: 8em;
}

#header .accountDropdown {
    min-height: 3em;
    padding: 1.5em;
    width: 15em;
    position: relative;
    text-align: left;
}

#header .accountDropdown ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}

#header .accountDropdown ul li {
    margin-bottom: 0.5em;
}

li {
    display: list-item;
    text-align: -webkit-match-parent;
}

#header .accountDropdown.skinny ul li a {
    color: #0366d6;
}

#header .accountDropdown ul li a {
    color: #666;
    text-decoration: none;
}

#header .accountDropdown.skinny ul li .externalLink {
    margin-left: 0.25em;
}

svg:not(:root) {
    overflow: hidden;
}

:not(svg) {
    transform-origin: 0px 0px;
}

#header .accountDropdown ul li:last-child {
    margin-bottom: 0;
}

#header .accountDropdown.skinny ul li a {
    color: #0366d6;
}

#header .avatar {
    border-radius: 1.75em;
    height: 1.75em;
    width: 1.75em;
    overflow: hidden;
    display: inline-block;
    box-shadow: 0 2px 5px 0 rgba(60,66,87,0.1), 0 1px 1px 0 rgba(0,0,0,0.07);
}

#header .avatar img {
    width: 100%;
    height: auto;
}

img {
    border-style: none;
}

button, select {
    text-transform: none;
}

button, input {
    overflow: visible;
}

button, input, optgroup, select, textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}

.collapsible_content .expanded_content.position-right {
    right: -6px;
}

#header .accountDropdown h3 {
    position: relative;
    font-size: 1em;
    margin: 0;
    z-index: 0;
    margin-bottom: 1em;
}

h1, h2, h3, h4, h5 {
    letter-spacing: -0.005em;
}

h3 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

#header .accountDropdown h3 span {
    display: inline-block;
    padding: 0 0.5em;
    padding-left: 0;
    background: #fff;
}

#header .accountDropdown ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}

#header .accountDropdown ul li:last-child {
    margin-bottom: 0;
}

#header .accountDropdown ul li {
    margin-bottom: 0.5em;
}

li {
    display: list-item;
    text-align: -webkit-match-parent;
}

#header .accountDropdown ul li a {
    color: #666;
    text-decoration: none;
}
</style>